import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { getDashboardApi } from '../../../api/adminApi';
import {
	DASHBOARD,
	FLOOR_PLAN,
	USER_LIST,
	VIDEO_UPLOAD,
	ADMIN_FAQ,
	ADMIN_EVENTS,
	ADMIN_LIBRARY,
	ADMIN_SALAQEEN,
	ADMIN_CONTACT,
	AUDIO_UPLOAD,
	ITIKAAF_APPROVED_LIST,
	ITIKAAF_CONFIRMED_LIST,
	ITIKAAF_ASSIGNED_LIST,
	ITIKAAF_ASSIGNED_SEATS_LIST,
	MASJID_LIST,
	CREATE_FLOOR_PLAN,
	ADMIN_USERS,
	ADMIN_USER_LOGS,
	ITIKAAF_REJECTED_LIST,
	ITIKAAF_CHECKIN_USER,
	ADMIN_ERROR_LOGS,
} from '../../../config/constants/routePathConstants';
import { getUserData, sessionDestroy } from '../../../helpers/projectHelper';
import { useWindowSize } from '../../../helpers/hooks';
import { useDispatch, useSelector } from 'react-redux';
import './dashboard-layout.css';

const DashboardLayout = (props) => {
	const dispatch = useDispatch();
	const [width] = useWindowSize();
	const { pathname } = useLocation();
	const navigate = useNavigate();
	const userData = getUserData();
	const isSuperAdmin = userData?.role === 'admin';

	const [status, setStatus] = useState('pending');
	const [listSize, setListsize] = useState(1);
	const [pageNumbers, setPageNumbers] = useState([]);
	const [limit, setLimit] = useState({ start: 1, end: 3 });
	const [page, setPage] = useState(1);
	const SIZE = 5;
	const [masjidValue, setMasjidValue] = useState(null);
	const [masjidData, setMasjidData] = useState([]);
	const [loading, setLoading] = useState(true);
	const masjidId = masjidValue
		? masjidValue
		: masjidData?.length > 0
		? masjidData[0]._id
		: null;
	const [dashboardList, setDashboardList] = useState([]);

	const { children } = props;
	const [navToggle, setNavToggle] = useState(true);
	useEffect(() => {
		width > 991 ? setNavToggle(true) : setNavToggle(false);
	}, [width]);
	const navHandleClick = () => {
		setNavToggle(!navToggle);
	};
	const [open, setOpen] = useState(false);

	const handleClickOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	const handleLogout = () => {
		sessionDestroy();
		// window.location.href = "/";
		// navigate("/");
	};

	const getDashboardList = () => {
		dispatch(
			getDashboardApi(
				status,
				SIZE,
				setListsize,
				page,
				setLoading,
				masjidId,
				setDashboardList
			)
		);
	};
	useEffect(() => {
		if (masjidId) getDashboardList();
	}, [page, masjidId]);

	return (
		<div className='app-container app-theme-white body-tabs-shadow fixed-sidebar fixed-header'>
			<div className='app-header header-shadow'>
				<div className='app-header__logo'>
					<div className='header__pane'>
						<div>
							<button
								type='button'
								className='hamburger close-sidebar-btn hamburger--elastic'
								data-class='closed-sidebar'
								onClick={navHandleClick}
							>
								<span className='hamburger-box'>
									<span className='hamburger-inner' />
								</span>
							</button>
						</div>
					</div>
				</div>
				<div className='logout-icon' title='logout' onClick={handleClickOpen}>
					Logout
					<i
						className='fa fa-sign-out'
						aria-hidden='true'
						style={{ paddingLeft: '10px' }}
					></i>
				</div>
				<div className='app-header__mobile-menu'>
					<div>
						<button
							type='button'
							className='hamburger hamburger--elastic mobile-toggle-nav'
							onClick={navHandleClick}
						>
							<span className='hamburger-box'>
								<span className='hamburger-inner' />
							</span>
						</button>
					</div>
				</div>
			</div>
			<div className='ui-theme-settings'>
				<div className='theme-settings__inner'>
					<div className='scrollbar-container'>
						<div className='theme-settings__options-wrapper'>
							<h3 className='themeoptions-heading'>Layout Options</h3>
							<div className='p-3'>
								<ul className='list-group'>
									<li className='list-group-item'>
										<div className='widget-content p-0'>
											<div className='widget-content-wrapper'>
												<div className='widget-content-left mr-3'>
													<div
														className='switch has-switch switch-container-class'
														data-class='fixed-header'
													>
														<div className='switch-animate switch-on'>
															<input
																type='checkbox'
																defaultChecked
																data-toggle='toggle'
																data-onstyle='success'
															/>
														</div>
													</div>
												</div>
												<div className='widget-content-left'>
													<div className='widget-heading'>Fixed Header</div>
													<div className='widget-subheading'>
														Makes the header top fixed, always visible!
													</div>
												</div>
											</div>
										</div>
									</li>
									<li className='list-group-item'>
										<div className='widget-content p-0'>
											<div className='widget-content-wrapper'>
												<div className='widget-content-left mr-3'>
													<div
														className='switch has-switch switch-container-class'
														data-class='fixed-sidebar'
													>
														<div className='switch-animate switch-on'>
															<input
																type='checkbox'
																defaultChecked
																data-toggle='toggle'
																data-onstyle='success'
															/>
														</div>
													</div>
												</div>
												<div className='widget-content-left'>
													<div className='widget-heading'>Fixed Sidebar</div>
													<div className='widget-subheading'>
														Makes the sidebar left fixed, always visible!
													</div>
												</div>
											</div>
										</div>
									</li>
									<li className='list-group-item'>
										<div className='widget-content p-0'>
											<div className='widget-content-wrapper'>
												<div className='widget-content-left mr-3'>
													<div
														className='switch has-switch switch-container-class'
														data-class='fixed-footer'
													>
														<div className='switch-animate switch-off'>
															<input
																type='checkbox'
																data-toggle='toggle'
																data-onstyle='success'
															/>
														</div>
													</div>
												</div>
												<div className='widget-content-left'>
													<div className='widget-heading'>Fixed Footer</div>
													<div className='widget-subheading'>
														Makes the app footer bottom fixed, always visible!
													</div>
												</div>
											</div>
										</div>
									</li>
								</ul>
							</div>
							<h3 className='themeoptions-heading'>
								<div>Header Options</div>
								<button
									type='button'
									className='btn-pill btn-shadow btn-wide ml-auto btn btn-focus btn-sm switch-header-cs-class'
									data-class
								>
									Restore Default
								</button>
							</h3>
							<div className='p-3'>
								<ul className='list-group'>
									<li className='list-group-item'>
										<h5 className='pb-2'>Choose Color Scheme</h5>
										<div className='theme-settings-swatches'>
											<div
												className='swatch-holder bg-primary switch-header-cs-class'
												data-class='bg-primary header-text-light'
											></div>
											<div
												className='swatch-holder bg-secondary switch-header-cs-class'
												data-class='bg-secondary header-text-light'
											></div>
											<div
												className='swatch-holder bg-success switch-header-cs-class'
												data-class='bg-success header-text-dark'
											></div>
											<div
												className='swatch-holder bg-info switch-header-cs-class'
												data-class='bg-info header-text-dark'
											></div>
											<div
												className='swatch-holder bg-warning switch-header-cs-class'
												data-class='bg-warning header-text-dark'
											></div>
											<div
												className='swatch-holder bg-danger switch-header-cs-class'
												data-class='bg-danger header-text-light'
											></div>
											<div
												className='swatch-holder bg-light switch-header-cs-class'
												data-class='bg-light header-text-dark'
											></div>
											<div
												className='swatch-holder bg-dark switch-header-cs-class'
												data-class='bg-dark header-text-light'
											></div>
											<div
												className='swatch-holder bg-focus switch-header-cs-class'
												data-class='bg-focus header-text-light'
											></div>
											<div
												className='swatch-holder bg-alternate switch-header-cs-class'
												data-class='bg-alternate header-text-light'
											></div>
											<div className='divider'></div>
											<div
												className='swatch-holder bg-vicious-stance switch-header-cs-class'
												data-class='bg-vicious-stance header-text-light'
											></div>
											<div
												className='swatch-holder bg-midnight-bloom switch-header-cs-class'
												data-class='bg-midnight-bloom header-text-light'
											></div>
											<div
												className='swatch-holder bg-night-sky switch-header-cs-class'
												data-class='bg-night-sky header-text-light'
											></div>
											<div
												className='swatch-holder bg-slick-carbon switch-header-cs-class'
												data-class='bg-slick-carbon header-text-light'
											></div>
											<div
												className='swatch-holder bg-asteroid switch-header-cs-class'
												data-class='bg-asteroid header-text-light'
											></div>
											<div
												className='swatch-holder bg-royal switch-header-cs-class'
												data-class='bg-royal header-text-light'
											></div>
											<div
												className='swatch-holder bg-warm-flame switch-header-cs-class'
												data-class='bg-warm-flame header-text-dark'
											></div>
											<div
												className='swatch-holder bg-night-fade switch-header-cs-class'
												data-class='bg-night-fade header-text-dark'
											></div>
											<div
												className='swatch-holder bg-sunny-morning switch-header-cs-class'
												data-class='bg-sunny-morning header-text-dark'
											></div>
											<div
												className='swatch-holder bg-tempting-azure switch-header-cs-class'
												data-class='bg-tempting-azure header-text-dark'
											></div>
											<div
												className='swatch-holder bg-amy-crisp switch-header-cs-class'
												data-class='bg-amy-crisp header-text-dark'
											></div>
											<div
												className='swatch-holder bg-heavy-rain switch-header-cs-class'
												data-class='bg-heavy-rain header-text-dark'
											></div>
											<div
												className='swatch-holder bg-mean-fruit switch-header-cs-class'
												data-class='bg-mean-fruit header-text-dark'
											></div>
											<div
												className='swatch-holder bg-malibu-beach switch-header-cs-class'
												data-class='bg-malibu-beach header-text-light'
											></div>
											<div
												className='swatch-holder bg-deep-blue switch-header-cs-class'
												data-class='bg-deep-blue header-text-dark'
											></div>
											<div
												className='swatch-holder bg-ripe-malin switch-header-cs-class'
												data-class='bg-ripe-malin header-text-light'
											></div>
											<div
												className='swatch-holder bg-arielle-smile switch-header-cs-class'
												data-class='bg-arielle-smile header-text-light'
											></div>
											<div
												className='swatch-holder bg-plum-plate switch-header-cs-class'
												data-class='bg-plum-plate header-text-light'
											></div>
											<div
												className='swatch-holder bg-happy-fisher switch-header-cs-class'
												data-class='bg-happy-fisher header-text-dark'
											></div>
											<div
												className='swatch-holder bg-happy-itmeo switch-header-cs-class'
												data-class='bg-happy-itmeo header-text-light'
											></div>
											<div
												className='swatch-holder bg-mixed-hopes switch-header-cs-class'
												data-class='bg-mixed-hopes header-text-light'
											></div>
											<div
												className='swatch-holder bg-strong-bliss switch-header-cs-class'
												data-class='bg-strong-bliss header-text-light'
											></div>
											<div
												className='swatch-holder bg-grow-early switch-header-cs-class'
												data-class='bg-grow-early header-text-light'
											></div>
											<div
												className='swatch-holder bg-love-kiss switch-header-cs-class'
												data-class='bg-love-kiss header-text-light'
											></div>
											<div
												className='swatch-holder bg-premium-dark switch-header-cs-class'
												data-class='bg-premium-dark header-text-light'
											></div>
											<div
												className='swatch-holder bg-happy-green switch-header-cs-class'
												data-class='bg-happy-green header-text-light'
											></div>
										</div>
									</li>
								</ul>
							</div>
							<h3 className='themeoptions-heading'>
								<div>Sidebar Options</div>
								<button
									type='button'
									className='btn-pill btn-shadow btn-wide ml-auto btn btn-focus btn-sm switch-sidebar-cs-class'
									data-class
								>
									Restore Default
								</button>
							</h3>
							<div className='p-3'>
								<ul className='list-group'>
									<li className='list-group-item'>
										<h5 className='pb-2'>Choose Color Scheme</h5>
										<div className='theme-settings-swatches'>
											<div
												className='swatch-holder bg-primary switch-sidebar-cs-class'
												data-class='bg-primary sidebar-text-light'
											></div>
											<div
												className='swatch-holder bg-secondary switch-sidebar-cs-class'
												data-class='bg-secondary sidebar-text-light'
											></div>
											<div
												className='swatch-holder bg-success switch-sidebar-cs-class'
												data-class='bg-success sidebar-text-dark'
											></div>
											<div
												className='swatch-holder bg-info switch-sidebar-cs-class'
												data-class='bg-info sidebar-text-dark'
											></div>
											<div
												className='swatch-holder bg-warning switch-sidebar-cs-class'
												data-class='bg-warning sidebar-text-dark'
											></div>
											<div
												className='swatch-holder bg-danger switch-sidebar-cs-class'
												data-class='bg-danger sidebar-text-light'
											></div>
											<div
												className='swatch-holder bg-light switch-sidebar-cs-class'
												data-class='bg-light sidebar-text-dark'
											></div>
											<div
												className='swatch-holder bg-dark switch-sidebar-cs-class'
												data-class='bg-dark sidebar-text-light'
											></div>
											<div
												className='swatch-holder bg-focus switch-sidebar-cs-class'
												data-class='bg-focus sidebar-text-light'
											></div>
											<div
												className='swatch-holder bg-alternate switch-sidebar-cs-class'
												data-class='bg-alternate sidebar-text-light'
											></div>
											<div className='divider'></div>
											<div
												className='swatch-holder bg-vicious-stance switch-sidebar-cs-class'
												data-class='bg-vicious-stance sidebar-text-light'
											></div>
											<div
												className='swatch-holder bg-midnight-bloom switch-sidebar-cs-class'
												data-class='bg-midnight-bloom sidebar-text-light'
											></div>
											<div
												className='swatch-holder bg-night-sky switch-sidebar-cs-class'
												data-class='bg-night-sky sidebar-text-light'
											></div>
											<div
												className='swatch-holder bg-slick-carbon switch-sidebar-cs-class'
												data-class='bg-slick-carbon sidebar-text-light'
											></div>
											<div
												className='swatch-holder bg-asteroid switch-sidebar-cs-class'
												data-class='bg-asteroid sidebar-text-light'
											></div>
											<div
												className='swatch-holder bg-royal switch-sidebar-cs-class'
												data-class='bg-royal sidebar-text-light'
											></div>
											<div
												className='swatch-holder bg-warm-flame switch-sidebar-cs-class'
												data-class='bg-warm-flame sidebar-text-dark'
											></div>
											<div
												className='swatch-holder bg-night-fade switch-sidebar-cs-class'
												data-class='bg-night-fade sidebar-text-dark'
											></div>
											<div
												className='swatch-holder bg-sunny-morning switch-sidebar-cs-class'
												data-class='bg-sunny-morning sidebar-text-dark'
											></div>
											<div
												className='swatch-holder bg-tempting-azure switch-sidebar-cs-class'
												data-class='bg-tempting-azure sidebar-text-dark'
											></div>
											<div
												className='swatch-holder bg-amy-crisp switch-sidebar-cs-class'
												data-class='bg-amy-crisp sidebar-text-dark'
											></div>
											<div
												className='swatch-holder bg-heavy-rain switch-sidebar-cs-class'
												data-class='bg-heavy-rain sidebar-text-dark'
											></div>
											<div
												className='swatch-holder bg-mean-fruit switch-sidebar-cs-class'
												data-class='bg-mean-fruit sidebar-text-dark'
											></div>
											<div
												className='swatch-holder bg-malibu-beach switch-sidebar-cs-class'
												data-class='bg-malibu-beach sidebar-text-light'
											></div>
											<div
												className='swatch-holder bg-deep-blue switch-sidebar-cs-class'
												data-class='bg-deep-blue sidebar-text-dark'
											></div>
											<div
												className='swatch-holder bg-ripe-malin switch-sidebar-cs-class'
												data-class='bg-ripe-malin sidebar-text-light'
											></div>
											<div
												className='swatch-holder bg-arielle-smile switch-sidebar-cs-class'
												data-class='bg-arielle-smile sidebar-text-light'
											></div>
											<div
												className='swatch-holder bg-plum-plate switch-sidebar-cs-class'
												data-class='bg-plum-plate sidebar-text-light'
											></div>
											<div
												className='swatch-holder bg-happy-fisher switch-sidebar-cs-class'
												data-class='bg-happy-fisher sidebar-text-dark'
											></div>
											<div
												className='swatch-holder bg-happy-itmeo switch-sidebar-cs-class'
												data-class='bg-happy-itmeo sidebar-text-light'
											></div>
											<div
												className='swatch-holder bg-mixed-hopes switch-sidebar-cs-class'
												data-class='bg-mixed-hopes sidebar-text-light'
											></div>
											<div
												className='swatch-holder bg-strong-bliss switch-sidebar-cs-class'
												data-class='bg-strong-bliss sidebar-text-light'
											></div>
											<div
												className='swatch-holder bg-grow-early switch-sidebar-cs-class'
												data-class='bg-grow-early sidebar-text-light'
											></div>
											<div
												className='swatch-holder bg-love-kiss switch-sidebar-cs-class'
												data-class='bg-love-kiss sidebar-text-light'
											></div>
											<div
												className='swatch-holder bg-premium-dark switch-sidebar-cs-class'
												data-class='bg-premium-dark sidebar-text-light'
											></div>
											<div
												className='swatch-holder bg-happy-green switch-sidebar-cs-class'
												data-class='bg-happy-green sidebar-text-light'
											></div>
										</div>
									</li>
								</ul>
							</div>
							<h3 className='themeoptions-heading'>
								<div>Main Content Options</div>
								<button
									type='button'
									className='btn-pill btn-shadow btn-wide ml-auto active btn btn-focus btn-sm'
								>
									Restore Default
								</button>
							</h3>
							<div className='p-3'>
								<ul className='list-group'>
									<li className='list-group-item'>
										<h5 className='pb-2'>Page Section Tabs</h5>
										<div className='theme-settings-swatches'>
											<div role='group' className='mt-2 btn-group'>
												<button
													type='button'
													className='btn-wide btn-shadow btn-primary btn btn-secondary switch-theme-class'
													data-class='body-tabs-line'
												>
													Line
												</button>
												<button
													type='button'
													className='btn-wide btn-shadow btn-primary active btn btn-secondary switch-theme-class'
													data-class='body-tabs-shadow'
												>
													Shadow
												</button>
											</div>
										</div>
									</li>
								</ul>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className='app-main'>
				<div
					className={`app-sidebar ${
						navToggle ? 'sidebar-mobile-open' : 'sidebar-mobile-close'
					}   sidebar-shadow`}
				>
					<div className='app-header__logo'>
						<div className='logo-src' />
						<div className='header__pane ml-auto'>
							<div>
								<button
									type='button'
									className='hamburger close-sidebar-btn hamburger--elastic'
									data-class='closed-sidebar'
									onClick={navHandleClick}
								>
									<span className='hamburger-box'>
										<span className='hamburger-inner' />
									</span>
								</button>
							</div>
						</div>
					</div>
					<div className='app-header__mobile-menu'>
						<div>
							<button
								type='button'
								className='hamburger hamburger--elastic mobile-toggle-nav'
							>
								<span className='hamburger-box'>
									<span className='hamburger-inner' />
								</span>
							</button>
						</div>
					</div>
					<div className='app-header__menu'>
						<span>
							<button
								type='button'
								className='btn-icon btn-icon-only btn btn-primary btn-sm mobile-toggle-header-nav'
							>
								<span className='btn-icon-wrapper'></span>
							</button>
						</span>
					</div>
					<div className='scrollbar-sidebar'>
						<div className='app-sidebar__inner'>
							<ul className='vertical-nav-menu'>
								<li className='app-sidebar__heading'>Dashboard</li>
								<li>
									<Link
										to={DASHBOARD}
										className={pathname === DASHBOARD ? 'mm-active' : ''}
									>
										<i className='fa-solid fa-table-columns'></i> Admin
										Dashboard
									</Link>
								</li>
								{isSuperAdmin && (
									<>
										<li className={'app-sidebar__heading'}>
											Youtube Management
										</li>
										<li>
											<Link
												to={VIDEO_UPLOAD}
												className={pathname === VIDEO_UPLOAD ? 'mm-active' : ''}
											>
												<i className='fa-solid fa-cloud-arrow-up'></i> Upload
												Video
											</Link>
											<Link
												to={AUDIO_UPLOAD}
												className={pathname === AUDIO_UPLOAD ? 'mm-active' : ''}
											>
												<i class='fa-solid fa-upload'></i> Upload Audio
											</Link>
										</li>
									</>
								)}
								<li className='app-sidebar__heading'>Itikaaf Management</li>
								<li>
									<Link
										to={USER_LIST}
										className={pathname === USER_LIST ? 'mm-active' : ''}
									>
										<i class='fa-solid fa-address-card'></i>Itikaaf Registered
										list
									</Link>
									<Link
										to={ITIKAAF_APPROVED_LIST}
										className={
											pathname === ITIKAAF_APPROVED_LIST ? 'mm-active' : ''
										}
									>
										<i class='fa-solid fa-person-circle-check'></i> Itikaaf
										Admin Approved List
									</Link>
									<Link
										to={ITIKAAF_REJECTED_LIST}
										className={
											pathname === ITIKAAF_REJECTED_LIST ? 'mm-active' : ''
										}
									>
										<i class='fa-solid fa-users'></i> Itikaaf
										Admin Mu'takif List
									</Link>
									<Link
										to={ITIKAAF_CONFIRMED_LIST}
										className={
											pathname === ITIKAAF_CONFIRMED_LIST ||
											pathname.includes(MASJID_LIST)
												? 'mm-active'
												: ''
										}
									>
										<i class='fa-solid fa-square-person-confined'></i> Itikaaf
										User Confirmed List
									</Link>
									<Link
										to={ITIKAAF_ASSIGNED_SEATS_LIST}
										className={
											pathname === ITIKAAF_ASSIGNED_SEATS_LIST ||
											pathname.includes(ITIKAAF_ASSIGNED_SEATS_LIST)
												? 'mm-active'
												: ''
										}
									>
										<i class='fa-solid fa-rotate'></i> Itikaaf Floors & Swapping
									</Link>
									<Link
										to={ITIKAAF_ASSIGNED_LIST}
										className={
											pathname === ITIKAAF_ASSIGNED_LIST ? 'mm-active' : ''
										}
									>
										<i className='fa-solid fa-mosque'></i> Itikaaf Assigned List
									</Link>
									{userData.role !== 'observer' && (
										<Link
											to={ITIKAAF_CHECKIN_USER}
											className={
												pathname === ITIKAAF_CHECKIN_USER ? 'mm-active' : ''
											}
										>
											<i class='fa-solid fa-user-check'></i>
											Check-in User
										</Link>
									)}
								</li>
								{isSuperAdmin && (
									<>
										<li className={'app-sidebar__heading'}>Floor Management</li>
										<li>
											<Link
												to={FLOOR_PLAN}
												className={
													pathname === FLOOR_PLAN ||
													pathname === CREATE_FLOOR_PLAN ||
													pathname.includes(FLOOR_PLAN)
														? 'mm-active'
														: ''
												}
											>
												<i className='fa-solid fa-object-ungroup'></i>
												Masjid Creation
											</Link>
										</li>
										<li className={'app-sidebar__heading'}>Websites Pages</li>
										<li>
											<Link
												to={ADMIN_SALAQEEN}
												className={
													pathname === ADMIN_SALAQEEN ? 'mm-active' : ''
												}
											>
												<i class='fa fa-users' aria-hidden='true'></i>
												Salikeen
											</Link>
										</li>
										<li>
											<Link
												to={ADMIN_FAQ}
												className={pathname === ADMIN_FAQ ? 'mm-active' : ''}
											>
												<i class='fa fa-comments' aria-hidden='true'></i>
												FAQ
											</Link>
										</li>
										<li>
											<Link
												to={ADMIN_EVENTS}
												className={pathname === ADMIN_EVENTS ? 'mm-active' : ''}
											>
												<i class='fa fa-calendar' aria-hidden='true'></i> Events
											</Link>
										</li>
										<li>
											<Link
												to={ADMIN_LIBRARY}
												className={
													pathname === ADMIN_LIBRARY ? 'mm-active' : ''
												}
											>
												<i class='fa fa-book' aria-hidden='true'></i> Library
											</Link>
										</li>
										<li>
											<Link
												to={ADMIN_CONTACT}
												className={
													pathname === ADMIN_CONTACT ? 'mm-active' : ''
												}
											>
												<i class='fa fa-book' aria-hidden='true'></i> Messages
											</Link>
										</li>
									</>
								)}
								{isSuperAdmin && (
									<>
										<li className={'app-sidebar__heading'}>User management</li>
										<li>
											<Link
												to={ADMIN_USERS}
												className={pathname === ADMIN_USERS ? 'mm-active' : ''}
											>
												<i class='fa fa-users' aria-hidden='true'></i>
												Users
											</Link>
										</li>
										<li>
											<Link
												to={ADMIN_USER_LOGS}
												className={
													pathname === ADMIN_USER_LOGS ? 'mm-active' : ''
												}
											>
												<i class='fa fa-users' aria-hidden='true'></i>
												User Logs
											</Link>
										</li>
									</>
								)}
								{isSuperAdmin && (
									<>
										<li className={'app-sidebar__heading'}>System</li>
										<li>
											<Link
												to={ADMIN_ERROR_LOGS}
												className={
													pathname === ADMIN_ERROR_LOGS ? 'mm-active' : ''
												}
											>
												<i class='fa fa-users' aria-hidden='true'></i>
												Error Logs
											</Link>
										</li>
									</>
								)}
							</ul>
						</div>
					</div>
				</div>
				<div className={`app-main__outer ${navToggle ? '' : 'ml-4'} `}>
					<div className='app-main__inner'>{children}</div>
				</div>
			</div>
			<Dialog
				className='video-upload-dialog'
				open={open}
				onClose={handleClose}
				aria-labelledby='alert-dialog-title'
				aria-describedby='alert-dialog-description'
			>
				<DialogTitle id='alert-dialog-title'>
					{'Are You Sure, you want to logout?'}
				</DialogTitle>
				<DialogContent></DialogContent>
				<DialogActions className='dialog-actions'>
					<Button variant='outlined' onClick={handleClose}>
						CANCEL
					</Button>
					<Button
						color='error'
						variant='contained'
						autoFocus
						onClick={handleLogout}
					>
						LOGOUT
					</Button>
				</DialogActions>
			</Dialog>
		</div>
	);
};

export default DashboardLayout;
