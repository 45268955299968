import api from '../helpers/apiHelper.js';
import {
	devConsoleLog,
	errorToast,
	// getUserData,
	// navigate,
	// redirectRoute,
	sessionDestroy,
	storeLocal,
	successToast,
} from '../helpers/projectHelper.js';

import {
	loginLoading,
	sessionSuccess,
	loginWebsite,
} from '../redux/slicers/sessionSlice.js';

import {
	LOGIN_API_ROOT,
	LOGIN_API,
	SIGNUP,
	ADMIN_LOGIN_API,
	LIVE_BROADCAST_API,
	ITIKAAF_REGISTRATION_API,
	SALAQEEN_API,
	FAQ_API,
	FAQ_CATEGORY_API,
	FAQ_CATEGORIES_API,
	EVENT_API,
	EVENTS_API,
	LIBRARY_API,
	LIVE_BROADCAST_CATEGORY_API,
	LIVE_BROADCAST_SUB_CATEGORY_API,
	CONTACT_US_API,
	REPLY_API,
	LIVE_AUDIO_API,
	FLOORPLANAPI,
	CREATEFLOORPLANAPI,
	CREATE_SUB_ADMIN,
	SUB_ADMIN_LIST,
	RESEND_SUB_ADMIN_INVITATION,
	SUB_ADMIN_SET_PASSWORD,
	USER_LOGS,
	ADMIN_API,
} from '../config/constants/apiConstant.js';

import {
	ADMIN_LOGIN,
	DASHBOARD,
	FLOOR_PLAN,
} from '../config/constants/routePathConstants.js';

import { ROLE_NAV, USER_DATA } from '../config/constants/projectConstant.js';
import { getSignUpPopUP } from '../redux/slicers/userSlice.js';
import {
	getFaqCategoryLists,
	getFaqLists,
	getLiveBroadCasts,
	getSalaqeenLists,
	getSingleLiveBroadCast,
	getUserLists,
	getDashboardLists,
	getEventLists,
	getBookLists,
	getLiveBroadCastCategories,
	getLiveBroadCastSubCategories,
	getMessageLists,
	getAudioLists,
	getFloorPlanLists,
	getFloorPlanListsById,
	getSubAdminsList,
	getLogsList,
	getServerErrorsList,
} from '../redux/slicers/adminSlice.js';
import { setAccess } from '../redux/slicers/activitySlice.js';
import Swal from 'sweetalert2';
import myAxiosInstance from '../helpers/axiosInstace.js';

export const adminLoginApi =
	(formData, navigate, setLoadingbtn) => (dispatch) => {
		api()
			.root(LOGIN_API_ROOT)
			.post(ADMIN_LOGIN_API)
			.data(formData)
			.success((a) => {
				const { message: msg = '' } = a;
				setLoadingbtn(false);
				if (a.statusCode === 200) {
					const { data = {} } = a;
					storeLocal(data, USER_DATA);
					storeLocal(data.token, 'token');
					navigate(DASHBOARD);
					dispatch(setAccess(data.token));
					successToast(msg);
				}
			})
			.error((e) => {
				const { message: msg = '' } = e;
				setLoadingbtn(false);
				errorToast(msg);
			})
			.send(() => {
				dispatch(loginLoading(false));
			});
	};

export const setSubAdminPasswordApi =
	(formData, navigate, setLoadingbtn) => (dispatch) => {
		api()
			.root(LOGIN_API_ROOT)
			.post(SUB_ADMIN_SET_PASSWORD)
			.data(formData)
			.success((a) => {
				const { message: msg = '' } = a;
				setLoadingbtn(false);
				if (a.statusCode === 200) {
					navigate(ADMIN_LOGIN);
					successToast(msg);
				}
			})
			.error((e) => {
				const { message: msg = '' } = e;
				setLoadingbtn(false);
				errorToast(msg);
			})
			.send(() => {
				dispatch(loginLoading(false));
			});
	};

export const createSubAdminApi =
	(formData, setLoading, handleClose, getUsersList) => (dispatch) => {
		api()
			.root(LOGIN_API_ROOT)
			.post(CREATE_SUB_ADMIN)
			.data(formData)
			.success((a) => {
				setLoading(false);
				if (a.statusCode === 201) {
					successToast(a.message);
					getUsersList();
					handleClose();
				}
				if (
					a.statusCode === 400 ||
					a.statusCode === 401 ||
					a.statusCode === 403 ||
					a.statusCode === 404
				) {
					errorToast(a.message);
				}
			})
			.error((e) => {
				const { message: msg = '' } = e;
				errorToast(msg);
				setLoading(false);
			})
			.send(() => {});
	};

export const resendSubAdminInvitationApi =
	(formData, setLoading) => (dispatch) => {
		api()
			.root(LOGIN_API_ROOT)
			.post(RESEND_SUB_ADMIN_INVITATION)
			.data(formData)
			.success((a) => {
				setLoading(false);
				if (a.statusCode === 200) {
					successToast(a.message);
				}
				if (
					a.statusCode === 400 ||
					a.statusCode === 401 ||
					a.statusCode === 403 ||
					a.statusCode === 404
				) {
					errorToast(a.message);
				}
			})
			.error((e) => {
				const { message: msg = '' } = e;
				errorToast(msg);
				setLoading(false);
			})
			.send(() => {});
	};

export const getSubAdminsListApi =
	(SIZE, setListsize, page, setLoading, search) => (dispatch) => {
		const isSearch =
			search?.length > 0
				? `search=${search}&page=${page}&limit=${SIZE}`
				: `page=${page}&limit=${SIZE}`;
		api()
			.root(LOGIN_API_ROOT)
			.get(`${SUB_ADMIN_LIST}?${isSearch}`)
			.success((a) => {
				setLoading && setLoading(false);
				if (a.statusCode === 200) {
					dispatch(getSubAdminsList(a?.data?.data || []));
					setListsize(a?.data?.totalCounts || 0);
				}
				if (
					a.statusCode === 400 ||
					a.statusCode === 401 ||
					a.statusCode === 403 ||
					a.statusCode === 404
				) {
					errorToast(a.message);
				}
			})
			.error((e) => {
				const { message: msg = '' } = e;
				errorToast(msg);
				dispatch(getSubAdminsList([]));
				setLoading && setLoading(false);
			})
			.send(() => {});
	};

export const getLogsListApi =
	(SIZE, setListsize, page, setLoading, search) => (dispatch) => {
		const isSearch =
			search?.length > 0
				? `search=${search}&page=${page}&limit=${SIZE}`
				: `page=${page}&limit=${SIZE}`;

		api()
			.root(LOGIN_API_ROOT)
			.get(`${USER_LOGS}?${isSearch}`)
			.success((res) => {
				setLoading && setLoading(false);

				if (res.statusCode === 200) {
					dispatch(getLogsList(res?.data?.data || []));
					setListsize(res?.data?.totalCounts || 0);
				}

				if (
					res.statusCode === 400 ||
					res.statusCode === 401 ||
					res.statusCode === 403 ||
					res.statusCode === 404
				) {
					errorToast(res.message);
				}
			})
			.error((err) => {
				const { message: msg = '' } = err;
				errorToast(msg);
				dispatch(getLogsList([]));
				setLoading && setLoading(false);
			})
			.send(() => {});
	};

export const getServerErrorsListApi =
	(SIZE, setListsize, page, setLoading, search) => (dispatch) => {
		const isSearch =
			search?.length > 0
				? `search=${search}&page=${page}&limit=${SIZE}&sortBy=-createdAt`
				: `page=${page}&limit=${SIZE}&sortBy=-createdAt`;

		api()
			.root(LOGIN_API_ROOT)
			.get(`${ADMIN_API}errors?${isSearch}`)
			.success((res) => {
				setLoading && setLoading(false);

				if (res.statusCode === 200) {
					dispatch(getServerErrorsList(res?.data?.data || []));
					setListsize(res?.data?.totalCounts || 0);
				}

				if (
					res.statusCode === 400 ||
					res.statusCode === 401 ||
					res.statusCode === 403 ||
					res.statusCode === 404
				) {
					errorToast(res.message);
				}
			})
			.error((err) => {
				const { message: msg = '' } = err;
				errorToast(msg);
				dispatch(getServerErrorsList([]));
				setLoading && setLoading(false);
			})
			.send(() => {});
	};

export const logoutApi = () => () => {
	sessionDestroy();
};

export const signUpApi =
	(formData, setPhone, setBtnDisabled, setReCapValue) => (dispatch) => {
		api()
			.root(LOGIN_API_ROOT)
			.post(SIGNUP)
			.data({
				phone_number: formData.phone,
			})
			.success((a) => {
				if (a.status === 200) {
					successToast(a.message);
					dispatch(getSignUpPopUP(false));
					setPhone();
					setBtnDisabled(false);
					setReCapValue('');
				}
				if (
					a.status === 400 ||
					a.status === 401 ||
					a.status === 403 ||
					a.status === 404
				) {
					errorToast(a.message);
				}
			})
			.error((e) => {
				const { message: msg = '' } = e;
				errorToast(msg);
			})
			.send(() => {});
	};

//start -----------live broadcast--------------------
export const addLiveBroadcastApi =
	(formData, setLoading, handleClose, playlist, SIZE, setListsize, page) =>
	(dispatch) => {
		api()
			.root(LOGIN_API_ROOT)
			.post(LIVE_BROADCAST_API)
			.data(formData)
			.success((a) => {
				handleClose();
				setLoading(false);
				if (a.statusCode === 200) {
					successToast(a?.message);
					dispatch(
						getLiveBroadcastApi(playlist, SIZE, setListsize, page, setLoading)
					);
				}
				if (
					a.statusCode === 400 ||
					a.statusCode === 401 ||
					a.statusCode === 403 ||
					a.statusCode === 404
				) {
					errorToast(a.message);
				}
			})
			.error((e) => {
				handleClose();
				setLoading(false);
				const { message: msg = '' } = e;
				errorToast(msg);
			})
			.send(() => {});
	};

export const updateLiveBroadcastApi =
	(formData, id, setLoading, handleClose, playlist, SIZE, setListsize, page) =>
	(dispatch) => {
		api()
			.root(LOGIN_API_ROOT)
			.put(`${LIVE_BROADCAST_API}${id}`)
			.data(formData)
			.success((a) => {
				handleClose();
				setLoading(false);
				if (a.statusCode === 200) {
					successToast(a?.message);
					dispatch(
						getLiveBroadcastApi(playlist, SIZE, setListsize, page, setLoading)
					);
				}
				if (
					a.statusCode === 400 ||
					a.statusCode === 401 ||
					a.statusCode === 403 ||
					a.statusCode === 404
				) {
					errorToast(a.message);
				}
			})
			.error((e) => {
				handleClose();
				setLoading(false);
				const { message: msg = '' } = e;
				errorToast(msg);
			})
			.send(() => {});
	};

export const getLiveBroadcastApi =
	(playlist, SIZE, setListsize, page, setLoading) => (dispatch) => {
		const videoUrl = playlist
			? `${LIVE_BROADCAST_API}${playlist}&page=${page}&limit=${SIZE}`
			: `${LIVE_BROADCAST_API}?page=${page}&limit=${SIZE}`;
		api()
			.root(LOGIN_API_ROOT)
			.get(videoUrl)
			.success((a) => {
				setLoading && setLoading(false);
				if (a.statusCode === 200) {
					setListsize(a?.data?.totalCounts);
					dispatch(getLiveBroadCasts(a?.data?.data));
				}
				if (
					a.statusCode === 400 ||
					a.statusCode === 401 ||
					a.statusCode === 403 ||
					a.statusCode === 404
				) {
					// errorToast(a.message);
					setLoading && setLoading(false);
				}
			})
			.error((e) => {
				const { message: msg = '' } = e;
				// errorToast(msg);
				dispatch(getLiveBroadCasts([]));
				setLoading && setLoading(false);
			})
			.send(() => {});
	};

export const getSingleLiveBroadcastApi = (setLoading) => (dispatch) => {
	api()
		.root(LOGIN_API_ROOT)
		.get(`${LIVE_BROADCAST_API}`)
		.success((a) => {
			setLoading && setLoading(false);
			if (a.statusCode === 200) {
				dispatch(getSingleLiveBroadCast(a?.data?.data?.[0]));
			}
			if (
				a.statusCode === 400 ||
				a.statusCode === 401 ||
				a.statusCode === 403 ||
				a.statusCode === 404
			) {
				// errorToast(a.message);
			}
		})
		.error((e) => {
			const { message: msg = '' } = e;
			// errorToast(msg);
			dispatch(getLiveBroadCasts([]));
			setLoading && setLoading(false);
		})
		.send(() => {});
};

export const deleteLiveBroadcastApi =
	(id, playlist, SIZE, setListsize, page, setLoading) => (dispatch) => {
		api()
			.root(LOGIN_API_ROOT)
			.delete(`${LIVE_BROADCAST_API}${id}`)
			.success((a) => {
				if (a.statusCode === 200) {
					successToast(a.message);
					dispatch(
						getLiveBroadcastApi(playlist, SIZE, setListsize, page, setLoading)
					);
				}
				if (
					a.statusCode === 400 ||
					a.statusCode === 401 ||
					a.statusCode === 403 ||
					a.statusCode === 404
				) {
					errorToast(a.message);
				}
			})
			.error((e) => {
				const { message: msg = '' } = e;
				errorToast(msg);
			})
			.send(() => {});
	};

// start -------------live broadcast  category----------

export const getLiveBroadCastCategoryApi = (setLoading) => (dispatch) => {
	api()
		.root(LOGIN_API_ROOT)
		.get(LIVE_BROADCAST_CATEGORY_API)
		.success((a) => {
			setLoading && setLoading(false);
			if (a.statusCode === 200) {
				dispatch(getLiveBroadCastCategories(a?.data));
			}
			if (
				a.statusCode === 400 ||
				a.statusCode === 401 ||
				a.statusCode === 403 ||
				a.statusCode === 404
			) {
				errorToast(a.message);
			}
		})
		.error((e) => {
			const { message: msg = '' } = e;
			errorToast(msg);
			dispatch(getLiveBroadCastCategories([]));
			setLoading && setLoading(false);
		})
		.send(() => {});
};

export const addLiveBroadCastCategoryApi =
	(formData, handleClose, setLoading) => (dispatch) => {
		api()
			.root(LOGIN_API_ROOT)
			.post(LIVE_BROADCAST_CATEGORY_API)
			.data(formData)
			.success((a) => {
				handleClose();
				setLoading && setLoading(false);
				if (a.statusCode === 201) {
					successToast(a.message);
					dispatch(getLiveBroadCastCategoryApi());
				}
				if (
					a.statusCode === 400 ||
					a.statusCode === 401 ||
					a.statusCode === 403 ||
					a.statusCode === 404
				) {
					errorToast(a.message);
				}
			})
			.error((e) => {
				const { message: msg = '' } = e;
				errorToast(msg);
				handleClose();
				setLoading && setLoading(false);
			})
			.send(() => {});
	};

export const deleteLiveBroadCastCategoryApi = (id) => (dispatch) => {
	api()
		.root(LOGIN_API_ROOT)
		.delete(`${LIVE_BROADCAST_CATEGORY_API}${id}`)
		.success((a) => {
			if (a.statusCode === 200) {
				successToast(a.message);
				dispatch(getLiveBroadCastCategoryApi());
			}
			if (
				a.statusCode === 400 ||
				a.statusCode === 401 ||
				a.statusCode === 403 ||
				a.statusCode === 404
			) {
				errorToast(a.message);
			}
		})
		.error((e) => {
			const { message: msg = '' } = e;
			errorToast(msg);
		})
		.send(() => {});
};

export const updateLiveBroadCastCategoryApi =
	(formData, id, setLoading, handleUpdClose) => (dispatch) => {
		api()
			.root(LOGIN_API_ROOT)
			.put(`${LIVE_BROADCAST_CATEGORY_API}${id}`)
			.data(formData)
			.success((a) => {
				handleUpdClose();
				setLoading(false);
				if (a.statusCode === 200) {
					successToast(a.message);
					dispatch(getLiveBroadCastCategoryApi());
				}
				if (
					a.statusCode === 400 ||
					a.statusCode === 401 ||
					a.statusCode === 403 ||
					a.statusCode === 404
				) {
					errorToast(a.message);
				}
			})
			.error((e) => {
				const { message: msg = '' } = e;
				handleUpdClose();
				setLoading(false);
				errorToast(msg);
			})
			.send(() => {});
	};

// start -------------live broadcast sub category----------

export const getLiveBroadCastSubCategoryApi = (setLoading) => (dispatch) => {
	api()
		.root(LOGIN_API_ROOT)
		.get(LIVE_BROADCAST_SUB_CATEGORY_API)
		.success((a) => {
			setLoading && setLoading(false);
			if (a.statusCode === 200) {
				dispatch(getLiveBroadCastSubCategories(a?.data));
			}
			if (
				a.statusCode === 400 ||
				a.statusCode === 401 ||
				a.statusCode === 403 ||
				a.statusCode === 404
			) {
				errorToast(a.message);
			}
		})
		.error((e) => {
			const { message: msg = '' } = e;
			errorToast(msg);
			dispatch(getLiveBroadCastSubCategories([]));
			setLoading && setLoading(false);
		})
		.send(() => {});
};

export const addLiveBroadCastSubCategoryApi =
	(formData, handleClose, setLoading) => (dispatch) => {
		api()
			.root(LOGIN_API_ROOT)
			.post(LIVE_BROADCAST_SUB_CATEGORY_API)
			.data(formData)
			.success((a) => {
				handleClose();
				setLoading && setLoading(false);
				if (a.statusCode === 201) {
					successToast(a.message);
					dispatch(getLiveBroadCastSubCategoryApi());
				}
				if (
					a.statusCode === 400 ||
					a.statusCode === 401 ||
					a.statusCode === 403 ||
					a.statusCode === 404
				) {
					errorToast(a.message);
				}
			})
			.error((e) => {
				const { message: msg = '' } = e;
				errorToast(msg);
				handleClose();
				setLoading && setLoading(false);
			})
			.send(() => {});
	};

export const deleteLiveBroadCastSubCategoryApi = (id) => (dispatch) => {
	api()
		.root(LOGIN_API_ROOT)
		.delete(`${LIVE_BROADCAST_SUB_CATEGORY_API}${id}`)
		.success((a) => {
			if (a.statusCode === 200) {
				successToast(a.message);
				dispatch(getLiveBroadCastSubCategoryApi());
			}
			if (
				a.statusCode === 400 ||
				a.statusCode === 401 ||
				a.statusCode === 403 ||
				a.statusCode === 404
			) {
				errorToast(a.message);
			}
		})
		.error((e) => {
			const { message: msg = '' } = e;
			errorToast(msg);
		})
		.send(() => {});
};

export const updateLiveBroadCastSubCategoryApi =
	(formData, id, setLoading, handleUpdClose) => (dispatch) => {
		api()
			.root(LOGIN_API_ROOT)
			.put(`${LIVE_BROADCAST_SUB_CATEGORY_API}${id}`)
			.data(formData)
			.success((a) => {
				handleUpdClose();
				setLoading(false);
				if (a.statusCode === 200) {
					successToast(a.message);
					dispatch(getLiveBroadCastSubCategoryApi());
				}
				if (
					a.statusCode === 400 ||
					a.statusCode === 401 ||
					a.statusCode === 403 ||
					a.statusCode === 404
				) {
					errorToast(a.message);
				}
			})
			.error((e) => {
				const { message: msg = '' } = e;
				handleUpdClose();
				setLoading(false);
				errorToast(msg);
			})
			.send(() => {});
	};

//end -----------live broadcast--------------------

export const sendParticipationNotificationApi =
	(id, updateState, updateLoading) => (dispatch) => {
		api()
			.root(LOGIN_API_ROOT)
			.post(`${ITIKAAF_REGISTRATION_API}participation-notify/${id}`)
			.success((a) => {
				if (a.statusCode === 200 || a.statusCode === 201) {
					updateState();
					updateLoading();
				}
				if (
					a.statusCode === 400 ||
					a.statusCode === 401 ||
					a.statusCode === 403 ||
					a.statusCode === 404
				) {
					updateLoading();
					errorToast(a.message);
				}
			})
			.error((e) => {
				const { message: msg = '' } = e;
				errorToast(msg);
			})
			.send(() => {});
	};

export const bulkSendParticipationNotificationApi =
	(ids, postStateUpdate, setLoadingBtn) => (dispatch) => {
		api()
			.root(LOGIN_API_ROOT)
			.post(`${ITIKAAF_REGISTRATION_API}bulk-participation-notify`)
			.data({ ids })
			.success((a) => {
				setLoadingBtn && setLoadingBtn(false);

				if (a.statusCode === 200 || a.statusCode === 201) {
					postStateUpdate();
				} else if ([400, 401, 403, 404].includes(a.statusCode)) {
					errorToast(a.message);
				}
			})
			.error((e) => {
				setLoadingBtn && setLoadingBtn(false);

				const { message: msg = '' } = e;
				errorToast(msg);
			})
			.send(() => {});
	};

export const acceptAndRegisterItakaafUserApi =
	(status, id, getUserList, setLoadingBtn) => (dispatch) => {
		api()
			.root(LOGIN_API_ROOT)
			.get(`${ITIKAAF_REGISTRATION_API}${status}/${id}`)
			.success((a) => {
				setLoadingBtn && setLoadingBtn(false);
				if (a.statusCode === 200 || a.statusCode === 201) {
					getUserList();
				}
				if (
					a.statusCode === 400 ||
					a.statusCode === 401 ||
					a.statusCode === 403 ||
					a.statusCode === 404
				) {
					errorToast(a.message);
				}
			})
			.error((e) => {
				const { message: msg = '' } = e;
				errorToast(msg);
				setLoadingBtn && setLoadingBtn(false);
			})
			.send(() => {});
	};

export const bulkAcceptItakaafUserApi =
	(ids, getUserList, setLoadingBtn) => (dispatch) => {
		api()
			.root(LOGIN_API_ROOT)
			.post(`${ITIKAAF_REGISTRATION_API}bulkaccept`)
			.data({ ids })
			.success((a) => {
				setLoadingBtn && setLoadingBtn(false);
				if (a.statusCode === 200 || a.statusCode === 201) {
					getUserList();
				} else if ([400, 401, 403, 404].includes(a.statusCode)) {
					errorToast(a.message);
				}
			})
			.error((e) => {
				const { message: msg = '' } = e;
				errorToast(msg);
				setLoadingBtn && setLoadingBtn(false);
			})
			.send(() => {});
	};

export const updateUserEtikaafTypeApi =
	(id, etikaafType, onSuccess, onFailure) => (dispatch) => {
		api()
			.root(LOGIN_API_ROOT)
			.put(`${ITIKAAF_REGISTRATION_API}updateEtikaafType/${id}`)
			.data({ etikaafType })
			.success((a) => {
				if (a.statusCode === 200 || a.statusCode === 201) {
					onSuccess && onSuccess();
				} else if ([400, 401, 403, 404].includes(a.statusCode)) {
					errorToast(a.message);
					onFailure && onFailure();
				}
			})
			.error((e) => {
				const { message: msg = '' } = e;
				errorToast(msg);
				onFailure && onFailure();
			})
			.send(() => {});
	};

export const checkInUserApi = (userId, setLoading) => (dispatch) => {
	setLoading && setLoading(true);
	api()
		.root(LOGIN_API_ROOT)
		.post(`/athikaaf/user/checkin`)
		.data({ userId })
		.success((a) => {
			setLoading && setLoading(false);
			if (a.statusCode === 200 || a.statusCode === 201) {
				successToast(a.message);
			} else if ([400, 401, 403, 404].includes(a.statusCode)) {
				errorToast(a.message);
			}
		})
		.error((e) => {
			setLoading && setLoading(false);
			const { message: msg = '' } = e;
			errorToast(msg);
		})
		.send(() => {});
};

export const markMetWithSheikhApi = (userId, setLoading) => (dispatch) => {
	setLoading && setLoading(true);
	api()
		.root(LOGIN_API_ROOT)
		.post(`/athikaaf/user/markmetwithsheikh`)
		.data({ userId })
		.success((a) => {
			setLoading && setLoading(false);
			if (a.statusCode === 200 || a.statusCode === 201) {
				successToast(a.message);
			} else if ([400, 401, 403, 404].includes(a.statusCode)) {
				errorToast(a.message);
			}
		})
		.error((e) => {
			setLoading && setLoading(false);
			const { message: msg = '' } = e;
			errorToast(msg);
		})
		.send(() => {});
};

export const inviteUserRegistrationApi =
	(userData, setLoading) => (dispatch) => {
		setLoading && setLoading(true);
		api()
			.root(LOGIN_API_ROOT)
			.post(`${ITIKAAF_REGISTRATION_API}registrationinvite`)
			.data(userData)
			.success((a) => {
				setLoading && setLoading(false);
				if (a.statusCode === 200 || a.statusCode === 201) {
					successToast(a.message);
				} else if ([400, 401, 403, 404].includes(a.statusCode)) {
					errorToast(a.message || 'Failed to send invitation.');
				}
			})
			.error((e) => {
				setLoading && setLoading(false);
				const { message: msg = '' } = e;
				errorToast(msg);
			})
			.send(() => {});
	};

export const getUserListApi =
	(status, SIZE, setListsize, page, setLoading, masjidValue, filters = {}) =>
	(dispatch) => {
		const params = new URLSearchParams();
		params.append('status', status);
		params.append('page', page);
		params.append('limit', SIZE);

		if (filters.state) {
			params.append('state', filters.state);
		}
		if (filters.city) {
			params.append('city', filters.city);
		}
		if (filters.etikafType) {
			params.append('typeOfIthikaaf', filters.etikafType);
		}
		if (filters.khidmat) {
			params.append('typeOfQidmat', filters.khidmat);
		}
		if (filters.search) {
			params.append('search', filters.search);
		}

		if (filters.isUserConfirmed) {
			params.append('isUserConfirmed', filters.isUserConfirmed);
		}

		const url = `${ITIKAAF_REGISTRATION_API}${masjidValue}?${params.toString()}`;

		api()
			.root(LOGIN_API_ROOT)
			.get(url)
			.success((a) => {
				if (a.statusCode === 200) {
					setListsize(a?.data?.totalCounts);
					dispatch(getUserLists(a?.data?.data));
				}
				if (
					a.statusCode === 400 ||
					a.statusCode === 401 ||
					a.statusCode === 403 ||
					a.statusCode === 404
				) {
					dispatch(getUserLists([]));
				}
			})
			.error((e) => {
				const { message: msg = '' } = e;
				dispatch(getUserLists([]));
				setLoading(false);
			})
			.send(() => {
				setLoading(false);
			});
	};

export const exportUserListApi =
	(status, masjidValue, filters = {}) =>
	(dispatch) => {
		const params = new URLSearchParams();
		params.append('status', status);
		if (filters.state) params.append('state', filters.state);
		if (filters.city) params.append('city', filters.city);
		if (filters.etikafType) params.append('typeOfIthikaaf', filters.etikafType);
		if (filters.khidmat) params.append('typeOfQidmat', filters.khidmat);
		if (filters.search) params.append('search', filters.search);

		const url = `${ITIKAAF_REGISTRATION_API}users/${masjidValue}/export?${params.toString()}`;

		api()
			.root(LOGIN_API_ROOT)
			.get(url)
			.success((response) => {
				const bufferArray = response.data.data;
				const fileBuffer = new Uint8Array(bufferArray);
				const blob = new Blob([fileBuffer], {
					type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
				});
				const downloadUrl = window.URL.createObjectURL(blob);
				const a = document.createElement('a');
				a.href = downloadUrl;
				a.download = `${status}-users.xlsx`;
				document.body.appendChild(a);
				a.click();
				a.remove();
				setTimeout(() => window.URL.revokeObjectURL(downloadUrl), 100);
			})
			.error((e) => {
				console.error('Export failed', e);
			})
			.send();
	};

// Start Admin Dashboard API

export const getDashboardApi =
	(setListsize, setLoading, setDashboardList) => (dispatch) => {
		api()
			.root(LOGIN_API_ROOT)
			.get(`${'/athikaaf/dashboard/'}`)
			.success((a) => {
				if (a.statusCode === 200) {
					setListsize(a?.data?.totalCounts);
					setDashboardList(a?.data);
				}
				if (
					a.statusCode === 400 ||
					a.statusCode === 401 ||
					a.statusCode === 403 ||
					a.statusCode === 404
				) {
					// errorToast(a.message);
					setDashboardList([]);
				}
			})
			.error((e) => {
				const { message: msg = '' } = e;
				// errorToast(msg);
				setDashboardList([]);
				setLoading(false);
			})
			.send(() => {
				setLoading(false);
			});
	};

// End Admin Dashboard API

export const getAssignedUserListApi =
	(
		status,
		SIZE,
		setListsize,
		page,
		setLoading,
		masjidId,
		setAssignedUserList
	) =>
	(dispatch) => {
		api()
			.root(LOGIN_API_ROOT)
			.get(
				`${'/athikaaf/get-status-by-seat/'}${masjidId}?status=assigned&page=${page}&limit=${SIZE}`
			)
			.success((a) => {
				if (a.statusCode === 200) {
					setListsize(a?.data?.totalCounts);
					setAssignedUserList(a?.data?.data);
				}
				if (
					a.statusCode === 400 ||
					a.statusCode === 401 ||
					a.statusCode === 403 ||
					a.statusCode === 404
				) {
					// errorToast(a.message);
					setAssignedUserList([]);
				}
			})
			.error((e) => {
				const { message: msg = '' } = e;
				// errorToast(msg);
				setAssignedUserList([]);
				setLoading(false);
			})
			.send(() => {
				setLoading(false);
			});
	};

export const getCityBasedList =
	(masjidId, SIZE, setListsize, page, setLoading) => (dispatch) => {
		api()
			.root(LOGIN_API_ROOT)
			.get(`/athikaaf/confirmed-user/${masjidId}?page=${page}&limit=${SIZE}`)
			.success((a) => {
				if (a.statusCode === 200) {
					setListsize(a?.data?.totalCounts);
					dispatch(getUserLists(a?.data?.data));
				}
				if (
					a.statusCode === 400 ||
					a.statusCode === 401 ||
					a.statusCode === 403 ||
					a.statusCode === 404
				) {
					errorToast(a.message);
				}
			})
			.error((e) => {
				const { message: msg = '' } = e;
				errorToast(msg);
			})
			.send(() => {
				setLoading(false);
			});
	};

export const getCityBasedUserList =
	(
		city,
		masjidId,
		SIZE,
		setListsize,
		page,
		setLoading,
		listStatus,
		filters = {}
	) =>
	(dispatch) => {
		const params = new URLSearchParams();
		params.append('status', listStatus);
		params.append('page', page);
		params.append('limit', SIZE);

		if (filters.itikafType) {
			params.append('typeOfIthikaaf', filters.itikafType);
		}

		if (filters.khidmat) {
			params.append('typeOfQidmat', filters.khidmat);
		}

		if (filters.search) {
			params.append('search', filters.search);
		}

		api()
			.root(LOGIN_API_ROOT)
			.get(`/athikaaf/confirmed-user/${city}/${masjidId}?${params.toString()}`)
			.success((a) => {
				if (a.statusCode === 200) {
					setListsize(a?.data?.totalCounts);
					dispatch(getUserLists(a?.data?.data));
				}
				if (
					a.statusCode === 400 ||
					a.statusCode === 401 ||
					a.statusCode === 403 ||
					a.statusCode === 404
				) {
					errorToast(a.message);
				}
			})
			.error((e) => {
				const { message: msg = '' } = e;
				errorToast(msg);
			})
			.send(() => {
				setLoading(false);
			});
	};

export const getCityBasedUserListWithoutPagination =
	(city, masjidId, setLoading, setUserList) => (dispatch) => {
		api()
			.root(LOGIN_API_ROOT)
			.get(`/athikaaf/confirmed-user/${city}/${masjidId}`)
			.success((a) => {
				if (a.statusCode === 200) {
					setUserList(a?.data?.data);
				}
				if (
					a.statusCode === 400 ||
					a.statusCode === 401 ||
					a.statusCode === 403 ||
					a.statusCode === 404
				) {
					errorToast(a.message);
				}
			})
			.error((e) => {
				const { message: msg = '' } = e;
				errorToast(msg);
			})
			.send(() => {
				setLoading(false);
			});
	};

//start -----------salaqeen--------------------

export const getSalaqeenListApi =
	(SIZE, setListsize, page, setLoading, search) => (dispatch) => {
		const isSearch =
			search?.length > 0
				? `search=${search}&page=${page}&limit=${SIZE}`
				: `page=${page}&limit=${SIZE}`;
		api()
			.root(LOGIN_API_ROOT)
			.get(`${SALAQEEN_API}?${isSearch}`)
			.success((a) => {
				setLoading && setLoading(false);
				if (a.statusCode === 200) {
					dispatch(getSalaqeenLists(a?.data?.data));
					setListsize(a?.data?.totalCounts);
				}
				if (
					a.statusCode === 400 ||
					a.statusCode === 401 ||
					a.statusCode === 403 ||
					a.statusCode === 404
				) {
					errorToast(a.message);
				}
			})
			.error((e) => {
				const { message: msg = '' } = e;
				errorToast(msg);
				dispatch(getSalaqeenLists([]));
				setLoading && setLoading(false);
			})
			.send(() => {});
	};

export const deleteSalaqeenApi =
	(id, getSalaqeenList, setLoadingBtn, setDelOpen) => (dispatch) => {
		api()
			.root(LOGIN_API_ROOT)
			.delete(`${SALAQEEN_API}${id}`)
			.success((a) => {
				setLoadingBtn(false);
				setDelOpen(false);
				if (a.statusCode === 200) {
					successToast(a.message);
					getSalaqeenList();
				}
				if (
					a.statusCode === 400 ||
					a.statusCode === 401 ||
					a.statusCode === 403 ||
					a.statusCode === 404
				) {
					errorToast(a.message);
				}
			})
			.error((e) => {
				const { message: msg = '' } = e;
				errorToast(msg);
				setLoadingBtn(false);
				setDelOpen(false);
			})
			.send(() => {});
	};

export const updateSalaqeenApi =
	(formData, id, handleClose, setLoading, getSalaqeenList) => (dispatch) => {
		api()
			.root(LOGIN_API_ROOT)
			.put(`${SALAQEEN_API}${id}`)
			.data(formData)
			.success((a) => {
				setLoading(false);
				if (a.statusCode === 200) {
					successToast(a.message);
					getSalaqeenList();
					handleClose();
				}
				if (
					a.statusCode === 400 ||
					a.statusCode === 401 ||
					a.statusCode === 403 ||
					a.statusCode === 404
				) {
					errorToast(a.message);
				}
			})
			.error((e) => {
				const { message: msg = '' } = e;
				errorToast(msg);
				setLoading(false);
				handleClose();
			})
			.send(() => {});
	};

//end -----------salaqeen--------------------

//start -----------FAQ--------------------

export const getFaqListApi = (setLoading) => (dispatch) => {
	api()
		.root(LOGIN_API_ROOT)
		.get(FAQ_API)
		.success((a) => {
			setLoading && setLoading(false);
			if (a.statusCode === 200) {
				dispatch(getFaqLists(a?.data));
			}
			if (
				a.statusCode === 400 ||
				a.statusCode === 401 ||
				a.statusCode === 403 ||
				a.statusCode === 404
			) {
				errorToast(a.message);
			}
		})
		.error((e) => {
			const { message: msg = '' } = e;
			errorToast(msg);
			dispatch(getFaqLists([]));
			setLoading && setLoading(false);
		})
		.send(() => {});
};

export const addFaqListApi =
	(formData, handleClose, setLoading) => (dispatch) => {
		api()
			.root(LOGIN_API_ROOT)
			.post(FAQ_API)
			.data(formData)
			.success((a) => {
				setLoading && setLoading(false);
				handleClose();
				if (a.statusCode === 201) {
					dispatch(getFaqListApi());
				}
				if (
					a.statusCode === 400 ||
					a.statusCode === 401 ||
					a.statusCode === 403 ||
					a.statusCode === 404
				) {
					errorToast(a.message);
				}
			})
			.error((e) => {
				const { message: msg = '' } = e;
				errorToast(msg);
				dispatch(getFaqLists([]));
				handleClose();
				setLoading && setLoading(false);
			})
			.send(() => {});
	};

export const addExistingFaqListApi =
	(formData, setLoading, handleAddFaqClose) => (dispatch) => {
		api()
			.root(LOGIN_API_ROOT)
			.put(FAQ_API)
			.data(formData)
			.success((a) => {
				setLoading && setLoading(false);
				handleAddFaqClose(false);
				if (a.statusCode === 200) {
					dispatch(getFaqListApi());
				}
				if (
					a.statusCode === 400 ||
					a.statusCode === 401 ||
					a.statusCode === 403 ||
					a.statusCode === 404
				) {
					errorToast(a.message);
				}
			})
			.error((e) => {
				const { message: msg = '' } = e;
				errorToast(msg);
				handleAddFaqClose(false);
				dispatch(getFaqLists([]));
				setLoading && setLoading(false);
			})
			.send(() => {});
	};

export const updateQnAnsApi =
	(formData, handleClose, setLoadingBtn) => (dispatch) => {
		api()
			.root(LOGIN_API_ROOT)
			.put(FAQ_API)
			.data(formData)
			.success((a) => {
				handleClose();
				setLoadingBtn(false);
				if (a.statusCode === 200) {
					successToast(a?.message);
					dispatch(getFaqListApi());
				}
				if (
					a.statusCode === 400 ||
					a.statusCode === 401 ||
					a.statusCode === 403 ||
					a.statusCode === 404
				) {
					errorToast(a.message);
				}
			})
			.error((e) => {
				handleClose();
				setLoadingBtn(false);
				const { message: msg = '' } = e;
				errorToast(msg);
			})
			.send(() => {});
	};

export const deleteFaqApi = (id) => (dispatch) => {
	api()
		.root(LOGIN_API_ROOT)
		.delete(`${FAQ_API}${id}`)
		.success((a) => {
			if (a.statusCode === 200) {
				successToast(a.message);
				dispatch(getFaqListApi());
			}
			if (
				a.statusCode === 400 ||
				a.statusCode === 401 ||
				a.statusCode === 403 ||
				a.statusCode === 404
			) {
				errorToast(a.message);
			}
		})
		.error((e) => {
			const { message: msg = '' } = e;
			errorToast(msg);
		})
		.send(() => {});
};

export const deleteFaqQnAnsApi =
	(categoryId, id, setLoadingBtn, setDelOpen) => (dispatch) => {
		const apiPath = (id = ''
			? `${FAQ_API}${categoryId}`
			: `${FAQ_API}${categoryId}/${id}`);
		api()
			.root(LOGIN_API_ROOT)
			.delete(apiPath)
			.success((a) => {
				setLoadingBtn(false);
				setDelOpen(false);
				if (a.statusCode === 200) {
					successToast(a.message);
					dispatch(getFaqListApi());
				}
				if (
					a.statusCode === 400 ||
					a.statusCode === 401 ||
					a.statusCode === 403 ||
					a.statusCode === 404
				) {
					errorToast(a.message);
				}
			})
			.error((e) => {
				const { message: msg = '' } = e;
				errorToast(msg);
				setLoadingBtn(false);
				setDelOpen(false);
			})
			.send(() => {});
	};

//start---------FAQ Category-----------

export const getFaqCategoryListApi = (setLoading) => (dispatch) => {
	api()
		.root(LOGIN_API_ROOT)
		.get(FAQ_CATEGORIES_API)
		.success((a) => {
			setLoading && setLoading(false);
			if (a.statusCode === 200) {
				dispatch(getFaqCategoryLists(a?.data));
			}
			if (
				a.statusCode === 400 ||
				a.statusCode === 401 ||
				a.statusCode === 403 ||
				a.statusCode === 404
			) {
				errorToast(a.message);
			}
		})
		.error((e) => {
			const { message: msg = '' } = e;
			errorToast(msg);
			dispatch(getFaqCategoryLists([]));
			setLoading && setLoading(false);
		})
		.send(() => {});
};

export const addFaqCategoryListApi =
	(formData, handleClose, setLoading) => (dispatch) => {
		api()
			.root(LOGIN_API_ROOT)
			.post(FAQ_CATEGORY_API)
			.data(formData)
			.success((a) => {
				handleClose();
				setLoading && setLoading(false);
				if (a.statusCode === 201) {
					dispatch(getFaqCategoryListApi());
				}
				if (
					a.statusCode === 400 ||
					a.statusCode === 401 ||
					a.statusCode === 403 ||
					a.statusCode === 404
				) {
					errorToast(a.message);
				}
			})
			.error((e) => {
				const { message: msg = '' } = e;
				errorToast(msg);
				handleClose();
				setLoading && setLoading(false);
			})
			.send(() => {});
	};

export const deleteFaqCategoryApi =
	(id, setLoadingBtn, setDelOpen) => (dispatch) => {
		api()
			.root(LOGIN_API_ROOT)
			.delete(`${FAQ_CATEGORY_API}${id}`)
			.success((a) => {
				setLoadingBtn(false);
				setDelOpen(false);
				if (a.statusCode === 200) {
					successToast(a.message);
					dispatch(getFaqCategoryListApi());
				}
				if (
					a.statusCode === 400 ||
					a.statusCode === 401 ||
					a.statusCode === 403 ||
					a.statusCode === 404
				) {
					errorToast(a.message);
				}
			})
			.error((e) => {
				const { message: msg = '' } = e;
				errorToast(msg);
				setLoadingBtn(false);
				setDelOpen(false);
			})
			.send(() => {});
	};

export const updateFaqCategoryApi =
	(formData, id, handleUpdClose, setLoadingBtn) => (dispatch) => {
		api()
			.root(LOGIN_API_ROOT)
			.put(`${FAQ_CATEGORY_API}${id}`)
			.data(formData)
			.success((a) => {
				setLoadingBtn(false);
				handleUpdClose();
				if (a.statusCode === 200) {
					successToast(a.message);
					dispatch(getFaqCategoryListApi());
				}
				if (
					a.statusCode === 400 ||
					a.statusCode === 401 ||
					a.statusCode === 403 ||
					a.statusCode === 404
				) {
					errorToast(a.message);
				}
			})
			.error((e) => {
				const { message: msg = '' } = e;
				handleUpdClose();
				setLoadingBtn(false);
				errorToast(msg);
			})
			.send(() => {});
	};

//start--------event--------------------

export const getEventListsApi =
	(SIZE, setListsize, page, setLoading) => (dispatch) => {
		api()
			.root(LOGIN_API_ROOT)
			.get(EVENTS_API)
			.get(`${EVENTS_API}?page=${page}&limit=${SIZE}`)
			.success((a) => {
				setLoading && setLoading(false);
				if (a.statusCode === 200) {
					setListsize(a?.data?.totalCounts);
					dispatch(getEventLists(a?.data?.data));
				}
				if (
					a.statusCode === 400 ||
					a.statusCode === 401 ||
					a.statusCode === 403 ||
					a.statusCode === 404
				) {
					// errorToast(a.message);
				}
			})
			.error((e) => {
				const { message: msg = '' } = e;
				// errorToast(msg);
				dispatch(getEventLists([]));
				setLoading && setLoading(false);
			})
			.send(() => {});
	};

export const addEventListsApi =
	(formData, handleClose, setLoading, getEventList) => (dispatch) => {
		api()
			.root(LOGIN_API_ROOT)
			.post(EVENT_API)
			.data(formData)
			.success((a) => {
				setLoading && setLoading(false);
				handleClose();
				if (a.statusCode === 201) {
					successToast(a?.message);
					getEventList();
				}
				if (
					a.statusCode === 400 ||
					a.statusCode === 401 ||
					a.statusCode === 403 ||
					a.statusCode === 404
				) {
					errorToast(a.message);
				}
			})
			.error((e) => {
				const { message: msg = '' } = e;
				errorToast(msg);
				handleClose();
				setLoading && setLoading(false);
			})
			.send(() => {});
	};

export const deleteEventListsApi =
	(id, setLoadingBtn, setDelOpen, getEventList) => (dispatch) => {
		api()
			.root(LOGIN_API_ROOT)
			.delete(`${EVENT_API}${id}`)
			.success((a) => {
				setLoadingBtn(false);
				setDelOpen(false);
				if (a.statusCode === 200) {
					successToast(a.message);
					getEventList();
				}
				if (
					a.statusCode === 400 ||
					a.statusCode === 401 ||
					a.statusCode === 403 ||
					a.statusCode === 404
				) {
					errorToast(a.message);
				}
			})
			.error((e) => {
				const { message: msg = '' } = e;
				setLoadingBtn(false);
				setDelOpen(false);
				errorToast(msg);
			})
			.send(() => {});
	};

export const updateEventListsApi =
	(formData, id, setLoading, handleUpdClose, getEventList) => (dispatch) => {
		api()
			.root(LOGIN_API_ROOT)
			.put(`${EVENT_API}${id}`)
			.data(formData)
			.success((a) => {
				handleUpdClose();
				setLoading && setLoading(false);
				if (a.statusCode === 200 || a.statusCode === 201) {
					successToast(a.message);
					getEventList();
				}
				if (
					a.statusCode === 400 ||
					a.statusCode === 401 ||
					a.statusCode === 403 ||
					a.statusCode === 404
				) {
					errorToast(a.message);
				}
			})
			.error((e) => {
				const { message: msg = '' } = e;
				handleUpdClose();
				setLoading && setLoading(false);
				errorToast(msg);
			})
			.send(() => {});
	};

//start--------library--------------------

export const getBookListsApi =
	(SIZE, setListsize, page, setLoading) => (dispatch) => {
		api()
			.root(LOGIN_API_ROOT)
			.get(`${LIBRARY_API}?page=${page}&limit=${SIZE}`)
			.success((a) => {
				setLoading && setLoading(false);
				if (a.statusCode === 200) {
					setListsize(a?.data?.totalCounts);
					dispatch(getBookLists(a?.data?.data));
				}
				if (
					a.statusCode === 400 ||
					a.statusCode === 401 ||
					a.statusCode === 403 ||
					a.statusCode === 404
				) {
					// errorToast(a.message);
				}
			})
			.error((e) => {
				const { message: msg = '' } = e;
				// errorToast(msg);
				dispatch(getBookLists([]));
				setLoading && setLoading(false);
			})
			.send(() => {});
	};

export const addBookListsApi =
	(formData, handleClose, setLoadingBtn, getBookList) => (dispatch) => {
		api()
			.root(LOGIN_API_ROOT)
			.post(LIBRARY_API)
			.data(formData)
			.success((a) => {
				handleClose();
				setLoadingBtn(false);
				if (a.statusCode === 200 || a.statusCode === 201) {
					successToast(a?.message);
					getBookList();
				}
				if (
					a.statusCode === 400 ||
					a.statusCode === 401 ||
					a.statusCode === 403 ||
					a.statusCode === 404
				) {
					errorToast(a.message);
				}
			})
			.error((e) => {
				handleClose();
				setLoadingBtn(false);
				const { message: msg = '' } = e;
				errorToast(msg);
			})
			.send(() => {});
	};

export const deleteBookListsApi =
	(id, setLoadingBtn, setDelOpen, getBookList) => (dispatch) => {
		api()
			.root(LOGIN_API_ROOT)
			.delete(`${LIBRARY_API}${id}`)
			.success((a) => {
				setLoadingBtn(false);
				setDelOpen(false);
				if (a.statusCode === 200) {
					successToast(a.message);
					getBookList();
				}
				if (
					a.statusCode === 400 ||
					a.statusCode === 401 ||
					a.statusCode === 403 ||
					a.statusCode === 404
				) {
					errorToast(a.message);
				}
			})
			.error((e) => {
				const { message: msg = '' } = e;
				setLoadingBtn(false);
				setDelOpen(false);
				errorToast(msg);
			})
			.send(() => {});
	};

export const updateBookListsApi =
	(formData, id, handleUpdClose, setLoadingBtn, getBookList) => (dispatch) => {
		api()
			.root(LOGIN_API_ROOT)
			.put(`${LIBRARY_API}${id}`)
			.data(formData)
			.success((a) => {
				handleUpdClose();
				setLoadingBtn(false);
				if (a.statusCode === 200) {
					successToast(a.message);
					getBookList();
				}
				if (
					a.statusCode === 400 ||
					a.statusCode === 401 ||
					a.statusCode === 403 ||
					a.statusCode === 404
				) {
					errorToast(a.message);
				}
			})
			.error((e) => {
				const { message: msg = '' } = e;
				handleUpdClose();
				setLoadingBtn(false);
				errorToast(msg);
			})
			.send(() => {});
	};

//list of contact messages

export const getMessagesListApi =
	(SIZE, setListsize, page, setLoading) => (dispatch) => {
		api()
			.root(LOGIN_API_ROOT)
			.get(`${CONTACT_US_API}?page=${page}&limit=${SIZE}`)
			.success((a) => {
				setLoading && setLoading(false);
				if (a.statusCode === 200 || a.statusCode === 201) {
					setListsize(a?.data?.totalCounts);
					dispatch(getMessageLists(a?.data?.data));
				}
				if (
					a.statusCode === 400 ||
					a.statusCode === 401 ||
					a.statusCode === 403 ||
					a.statusCode === 404
				) {
					errorToast(a.message);
				}
			})
			.error((e) => {
				const { message: msg = '' } = e;
				errorToast(msg);
				dispatch(getMessageLists([]));
				setLoading && setLoading(false);
			})
			.send(() => {});
	};

export const replyMessageApi =
	(formData, setLoading, handleClose, getMessageLists) => (dispatch) => {
		api()
			.root(LOGIN_API_ROOT)
			.post(REPLY_API)
			.data(formData)
			.success((a) => {
				setLoading && setLoading(false);
				handleClose();
				if (a.statusCode === 200) {
					getMessageLists();
					Swal.fire('Thank you!', a.message, 'success');
				}
				if (
					a.statusCode === 400 ||
					a.statusCode === 401 ||
					a.statusCode === 403 ||
					a.statusCode === 404
				) {
					errorToast(a.message);
				}
			})
			.error((e) => {
				const { message: msg = '' } = e;
				errorToast(msg);
				setLoading && setLoading(false);
				handleClose();
			})
			.send(() => {});
	};

//start audio-----------
export const addAudioApi =
	(formData, setLoading, handleClose, getAudioList) => (dispatch) => {
		api()
			.root(LOGIN_API_ROOT)
			.post(LIVE_AUDIO_API)
			.data(formData)
			.success((a) => {
				handleClose();
				setLoading(false);
				if (a.statusCode === 200) {
					successToast(a?.message);
					getAudioList();
				}
				if (
					a.statusCode === 400 ||
					a.statusCode === 401 ||
					a.statusCode === 403 ||
					a.statusCode === 404
				) {
					errorToast(a.message);
				}
			})
			.error((e) => {
				handleClose();
				setLoading(false);
				const { message: msg = '' } = e;
				errorToast(msg);
			})
			.send(() => {});
	};

export const deleteAudioApi =
	(id, getAudioList, setLoadingBtn, setDelOpen) => (dispatch) => {
		api()
			.root(LOGIN_API_ROOT)
			.delete(`${LIVE_AUDIO_API}${id}`)
			.success((a) => {
				setLoadingBtn(false);
				setDelOpen(false);
				if (a.statusCode === 200) {
					successToast(a.message);
					getAudioList();
				}
				if (
					a.statusCode === 400 ||
					a.statusCode === 401 ||
					a.statusCode === 403 ||
					a.statusCode === 404
				) {
					errorToast(a.message);
				}
			})
			.error((e) => {
				const { message: msg = '' } = e;
				errorToast(msg);
				setLoadingBtn(false);
				setDelOpen(false);
			})
			.send(() => {});
	};

export const uploadAudioApi =
	(formData, id, setLoading, handleClose, getAudioList) => (dispatch) => {
		api()
			.root(LOGIN_API_ROOT)
			.put(`${LIVE_AUDIO_API}${id}`)
			.data(formData)
			.success((a) => {
				handleClose();
				setLoading(false);
				if (a.statusCode === 200) {
					successToast(a?.message);
					getAudioList();
				}
				if (
					a.statusCode === 400 ||
					a.statusCode === 401 ||
					a.statusCode === 403 ||
					a.statusCode === 404
				) {
					errorToast(a.message);
				}
			})
			.error((e) => {
				handleClose();
				setLoading(false);
				const { message: msg = '' } = e;
				errorToast(msg);
			})
			.send(() => {});
	};
//end  audio--------------

export const addFloorPlanApi =
	(formData, setLoading, navigate) => (dispatch) => {
		api()
			.root(LOGIN_API_ROOT)
			.post(CREATEFLOORPLANAPI)
			.data(formData)
			.success((a) => {
				// handleClose();
				setLoading(false);
				if (a.statusCode === 201) {
					successToast(a?.message);
					navigate(FLOOR_PLAN);
				}
				if (
					a.statusCode === 400 ||
					a.statusCode === 401 ||
					a.statusCode === 403 ||
					a.statusCode === 404
				) {
					errorToast(a.message);
				}
			})
			.error((e) => {
				setLoading(false);
				const { message: msg = '' } = e;
				errorToast(msg);
			})
			.send(() => {});
	};

export const updateFloorPlanApi =
	(id, formData, handleUpdClose) => (dispatch) => {
		api()
			.root(LOGIN_API_ROOT)
			.put(`${CREATEFLOORPLANAPI}/${id}`)
			.data(formData)
			.success((a) => {
				handleUpdClose();
				if (a.statusCode === 200) {
					successToast(a?.message);
				}
				if (
					a.statusCode === 400 ||
					a.statusCode === 401 ||
					a.statusCode === 403 ||
					a.statusCode === 404
				) {
					errorToast(a.message);
				}
			})
			.error((e) => {
				handleUpdClose();
				const { message: msg = '' } = e;
				errorToast(msg);
			})
			.send(() => {});
	};
export const getFloorPlanListApi =
	(SIZE, setListsize, page, setLoading) => (dispatch) => {
		api()
			.root(LOGIN_API_ROOT)
			.get(`${FLOORPLANAPI}?status=true&year=2024&page=${page}&limit=${SIZE}`)
			.success((a) => {
				setLoading && setLoading(false);
				if (a.statusCode === 200 || a.statusCode === 201) {
					dispatch(getFloorPlanLists(a?.data));
					setListsize(a?.data?.totalCounts);
				}
				if (
					a.statusCode === 400 ||
					a.statusCode === 401 ||
					a.statusCode === 403 ||
					a.statusCode === 404
				) {
					errorToast(a.message);
				}
			})
			.error((e) => {
				const { message: msg = '' } = e;
				errorToast(msg);
				setLoading && setLoading(false);
			})
			.send(() => {});
	};

export const getMasjidListApi = (setLoading, setMasjidData) => (dispatch) => {
	api()
		.root(LOGIN_API_ROOT)
		.get(`${FLOORPLANAPI}?status=true&year=2024`)
		.success((a) => {
			setLoading && setLoading(false);
			if (a.statusCode === 200 || a.statusCode === 201) {
				setMasjidData(a?.data);
			}
			if (
				a.statusCode === 400 ||
				a.statusCode === 401 ||
				a.statusCode === 403 ||
				a.statusCode === 404
			) {
				errorToast(a.message);
				setMasjidData([]);
			}
		})
		.error((e) => {
			const { message: msg = '' } = e;
			errorToast(msg);
			setLoading && setLoading(false);
			setMasjidData([]);
		})
		.send(() => {});
};

export const deleteFloorPlanListApi =
	(id, getFloorPlanList, setLoadingBtn, setDelOpen) => (dispatch) => {
		api()
			.root(LOGIN_API_ROOT)
			.delete(`${FLOORPLANAPI}/${id}`)
			.success((a) => {
				setLoadingBtn(false);
				setDelOpen(false);
				if (a.statusCode === 200) {
					successToast(a.message);
					getFloorPlanList();
				}
				if (
					a.statusCode === 400 ||
					a.statusCode === 401 ||
					a.statusCode === 403 ||
					a.statusCode === 404
				) {
					errorToast(a.message);
				}
			})
			.error((e) => {
				const { message: msg = '' } = e;
				errorToast(msg);
				setLoadingBtn(false);
				setDelOpen(false);
			})
			.send(() => {});
	};

export const getFloorPlanListByIdApi = (id, setLoading) => (dispatch) => {
	api()
		.root(LOGIN_API_ROOT)
		.get(`/masjid/${id}?status=true&year=2024`)
		.success((a) => {
			setLoading && setLoading(false);
			if (a.statusCode === 200 || a.statusCode === 201) {
				dispatch(getFloorPlanListsById(a?.data));
			}
			if (
				a.statusCode === 400 ||
				a.statusCode === 401 ||
				a.statusCode === 403 ||
				a.statusCode === 404
			) {
				errorToast(a.message);
			}
		})
		.error((e) => {
			const { message: msg = '' } = e;
			errorToast(msg);
			setLoading && setLoading(false);
		})
		.send(() => {});
};

export const customApiRequest = async (method, url, data = null) => {
	try {
		let response;

		switch (method.toLowerCase()) {
			case 'get':
				response = await myAxiosInstance.get(url);
				break;
			case 'post':
				response = await myAxiosInstance.post(url, data);
				break;
			case 'put':
				response = await myAxiosInstance.put(url, data);
				break;
			case 'patch':
				response = await myAxiosInstance.patch(url, data);
				break;
			case 'delete':
				response = await myAxiosInstance.delete(url);
				break;
			// Add other cases for different HTTP methods as needed

			default:
				throw new Error(`Unsupported HTTP method: ${method}`);
		}

		return response.data;
	} catch (error) {
		throw error; // Rethrow the error for the caller to handle
	}
};

export const bookSelectedSeat =
	(
		formData,
		setLoading,
		getFloorPlanListById,
		setSelectedSeat,
		handleClose,
		getFloorPlanList
	) =>
	(dispatch) => {
		setLoading && setLoading(true);
		api()
			.root(LOGIN_API_ROOT)
			.put('/athikaaf/assign-seat')
			.data(formData)
			.success((a) => {
				setLoading && setLoading(false);
				// handleClose();
				if (a.statusCode === 200) {
					getFloorPlanListById();
					getFloorPlanList();
					handleClose();
					setSelectedSeat([]);
					successToast(a.message);
					// dispatch(getFaqListApi());
				} else if (
					a.statusCode === 400 ||
					a.statusCode === 401 ||
					a.statusCode === 403 ||
					a.statusCode === 404
				) {
					errorToast(a.message);
				} else {
					errorToast(a.message);
				}
			})
			.error((e) => {
				const { message: msg = '' } = e;
				errorToast(msg);
				// dispatch(getFaqLists([]));
				// handleClose();
				setLoading && setLoading(false);
			})
			.send(() => {});
	};

export const swapSelectedSeat =
	(formData, setLoading, setOpen, getFloorPlanListById, setSelectedSeat) =>
	(dispatch) => {
		setLoading && setLoading(true);
		api()
			.root(LOGIN_API_ROOT)
			.put('/athikaaf/swap-seat')
			.data(formData)
			.success((a) => {
				setLoading && setLoading(false);
				if (a.statusCode === 200) {
					successToast(a.message);
					setOpen(false);
					setSelectedSeat([]);
					getFloorPlanListById();
				} else if (a.statusCode === 203) {
					successToast(a.message);
					setOpen(true);
				} else {
					errorToast(a.message);
				}
			})
			.error((e) => {
				const { message: msg = '' } = e;
				errorToast(msg);
				setLoading && setLoading(false);
			})
			.send(() => {});
	};

export const unassignSeat =
	(formData, setLoading, getFloorPlanListById) => (dispatch) => {
		setLoading && setLoading(true);
		api()
			.root(LOGIN_API_ROOT)
			.put('/athikaaf/unassign-seat')
			.data(formData)
			.success((a) => {
				setLoading && setLoading(false);
				if (a.statusCode === 200) {
					successToast(a.message);
					getFloorPlanListById();
				} else {
					errorToast(a.message);
				}
			})
			.error((e) => {
				const { message: msg = '' } = e;
				errorToast(msg);
				setLoading && setLoading(false);
			})
			.send(() => {});
	};
