import React, { useEffect, useState } from 'react';
import {
	Box,
	Paper,
	Typography,
	IconButton,
	Button,
	CircularProgress,
	Chip,
	Tooltip,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Slide,
	TextField,
	Menu,
	MenuItem,
} from '@mui/material';
import { ReactComponent as SeatIcon } from '../../../assets/images/floor/seat.svg';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import {
	getFloorPlanListByIdApi,
	swapSelectedSeat,
	unassignSeat,
} from '../../../api/adminApi';
import DashboardLayout from '../dashboardLayout';
import { adminSelector } from '../../../redux/slicers/adminSlice';
import { errorToast } from '../../../helpers/projectHelper';

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction='up' ref={ref} {...props} />;
});

const ItikaafAssignedFloor = () => {
	const { id } = useParams();
	const dispatch = useDispatch();
	const { floorPlanListsById } = useSelector(adminSelector);
	const [loading, setLoading] = useState(true);
	const [selectedSeat, setSelectedSeat] = useState([]);
	const [selectedFloorIndex, setSelectedFloorIndex] = useState(0);
	const [loadingBtn, setLoadingBtn] = useState(false);
	const [openOtpModal, setOpenOtpModal] = useState(false);
	const [otp, setOtp] = useState('');
	// const [otp1, setOtp1] = useState('');
	// const [otp2, setOtp2] = useState('');
	const navigate = useNavigate();
	const { pathname } = useLocation();
	const masjidId = pathname.split('/')[3];

	const [filter, setFilter] = useState('all');
	const [anchorEl, setAnchorEl] = useState(null);
	const handleOpenFilterMenu = (event) => setAnchorEl(event.currentTarget);
	const handleCloseFilterMenu = () => setAnchorEl(null);

	useEffect(() => {
		dispatch(getFloorPlanListByIdApi(masjidId, setLoading));
	}, [dispatch, masjidId]);

	const handleSeatClick = (seat, rowId, floorId) => {
		const isSelected = selectedSeat.some((s) => s.seatId === seat._id);

		if (isSelected) {
			setSelectedSeat(selectedSeat.filter((s) => s.seatId !== seat._id));
		} else {
			if (selectedSeat.length < 2) {
				setSelectedSeat([
					...selectedSeat,
					{
						...seat,
						seatId: seat._id,
						masjidId,
						floorId,
						rowId,
						userName: seat?.user?.name,
						userId: seat?.user?._id || null,
					},
				]);
			} else {
				errorToast('You can only select 2 seats for swap');
			}
		}
	};

	const handleSwapClick = () => {
		if (selectedSeat.length === 1 && selectedSeat[0]?.userId) {
			const unassignData = {
				seatId: selectedSeat[0]?.seatId,
				userId: selectedSeat[0]?.userId,
			};

			dispatch(
				unassignSeat(unassignData, setLoadingBtn, () =>
					dispatch(getFloorPlanListByIdApi(masjidId, setLoading))
				)
			);
		} else if (selectedSeat.length === 2) {
			if (selectedSeat[0]?.userName || selectedSeat[1]?.userName) {
				const formattedResponse = {
					seatId1: selectedSeat[0]?.seatId,
					seatId2: selectedSeat[1]?.seatId,
					isAdmin: false,
				};
				dispatch(
					swapSelectedSeat(
						formattedResponse,
						setLoadingBtn,
						setOpenOtpModal,
						() => dispatch(getFloorPlanListByIdApi(masjidId, setLoading)),
						setSelectedSeat
					)
				);
			} else {
				errorToast('Please select at least one assigned seat');
			}
		} else {
			errorToast('Please select a seat');
		}
	};

	const handleOtpSubmit = () => {
		if (!otp) {
			errorToast('Please enter OTP');
			return;
		}
		const formattedResponse = {
			seatId1: selectedSeat[0].seatId,
			seatId2: selectedSeat[1].seatId,
			otp: Number(otp),
		};
		dispatch(
			swapSelectedSeat(
				formattedResponse,
				setLoadingBtn,
				setOpenOtpModal,
				() => {
					dispatch(getFloorPlanListByIdApi(masjidId, setLoading));
				},
				setSelectedSeat
			)
		);
	};

	const handleCloseOtpModal = () => {
		setOpenOtpModal(false);
	};

	if (loading) {
		return (
			<Box display='flex' justifyContent='center' py={4}>
				<CircularProgress />
			</Box>
		);
	}

	const floors = floorPlanListsById?.floors;
	if (!floors || floors.length === 0) {
		return <Typography>No Floor Data Found</Typography>;
	}
	const selectedFloor = floors[selectedFloorIndex];

	return (
		<DashboardLayout>
			<Box mt={3}>
				<Paper
					sx={{
						p: 2,
						mb: 3,
						borderRadius: 2,
						boxShadow: 1,
					}}
				>
					<Box
						display='flex'
						flexDirection={{ xs: 'column', md: 'row' }}
						alignItems='center'
						justifyContent='space-between'
						gap={2}
					>
						<Box textAlign={{ xs: 'center', md: 'left' }}>
							<Typography variant='h5' fontWeight='bold'>
								{floorPlanListsById?.name}
							</Typography>
							{floorPlanListsById?.city && (
								<Typography variant='subtitle1' color='text.secondary'>
									({floorPlanListsById.city})
								</Typography>
							)}
						</Box>
						<Box textAlign={{ xs: 'center', md: 'right' }}>
							<Button
								variant='contained'
								color='primary'
								onClick={handleSwapClick}
								disabled={loadingBtn}
								sx={{
									fontSize: { xs: '0.75rem', md: 'inherit' },
									whiteSpace: 'nowrap',
								}}
							>
								{selectedSeat.length === 1 && selectedSeat[0]?.userId
									? 'Unassign Seat'
									: `Swap Seat 
								${
									selectedSeat.length > 1
										? ` (${selectedFloor.floorName
												?.charAt(0)
												.toUpperCase()}${selectedSeat[0].number
												.toString()
												.padStart(3, '0')} -> ${selectedFloor.floorName
												?.charAt(0)
												.toUpperCase()}${selectedSeat[1].number
												.toString()
												.padStart(3, '0')})`
										: ''
								}`}
							</Button>
						</Box>
					</Box>

					<Box
						mt={2}
						display='flex'
						justifyContent='center'
						alignItems='center'
						gap={4}
					>
						<Box display='flex' alignItems='center' gap={1}>
							<Box sx={{ width: 20, height: 4, backgroundColor: '#ff9800' }} />
							<Typography variant='caption'>Selected</Typography>
						</Box>
						<Box display='flex' alignItems='center' gap={1}>
							<Box sx={{ width: 20, height: 4, backgroundColor: '#1976d2' }} />
							<Typography variant='caption'>Checked In</Typography>
						</Box>
						<Box display='flex' alignItems='center' gap={1}>
							<Box sx={{ width: 20, height: 4, backgroundColor: '#d32f2f' }} />
							<Typography variant='caption'>Booked</Typography>
						</Box>
						<Box display='flex' alignItems='center' gap={1}>
							<Box
								sx={{
									width: 20,
									height: 4,
									backgroundColor: '#fff',
									border: '1px solid #ccc',
								}}
							/>
							<Typography variant='caption'>Free</Typography>
						</Box>
					</Box>
				</Paper>

				<Box
					display='flex'
					alignItems='center'
					justifyContent='center'
					gap={1}
					mb={3}
					flexWrap='wrap'
				>
					<Box
						sx={{
							overflowX: 'auto',
							whiteSpace: 'nowrap',
							display: 'flex',
							gap: 1,
						}}
					>
						{floors.map((floor, index) => (
							<Chip
								key={floor._id}
								label={floor.floorName}
								clickable
								onClick={() => setSelectedFloorIndex(index)}
								sx={{ flexShrink: 0, cursor: 'pointer' }}
								color={selectedFloorIndex === index ? 'primary' : 'default'}
							/>
						))}
					</Box>
					<Box
						sx={{
							width: '1px',
							height: '24px',
							backgroundColor: '#ccc',
							mx: 1,
						}}
					/>
					<Box
						onClick={handleOpenFilterMenu}
						sx={{
							cursor: 'pointer',
							display: 'flex',
							alignItems: 'center',
							gap: 0.5,
						}}
					>
						<Typography variant='body2'>
							{filter.charAt(0).toUpperCase() + filter.slice(1)}
						</Typography>
						<i className='fa-solid fa-chevron-down'></i>
					</Box>
					<Menu
						anchorEl={anchorEl}
						open={Boolean(anchorEl)}
						onClose={handleCloseFilterMenu}
						anchorOrigin={{
							vertical: 'bottom',
							horizontal: 'right',
						}}
						transformOrigin={{
							vertical: 'top',
							horizontal: 'right',
						}}
					>
						<MenuItem
							onClick={() => {
								setFilter('all');
								handleCloseFilterMenu();
							}}
						>
							All
						</MenuItem>
						<MenuItem
							onClick={() => {
								setFilter('booked');
								handleCloseFilterMenu();
							}}
						>
							Booked
						</MenuItem>
						<MenuItem
							onClick={() => {
								setFilter('free');
								handleCloseFilterMenu();
							}}
						>
							Free
						</MenuItem>
						<MenuItem
							onClick={() => {
								setFilter('checkedin');
								handleCloseFilterMenu();
							}}
						>
							Checked In
						</MenuItem>
					</Menu>
				</Box>

				<Box sx={{ overflowX: 'auto', maxWidth: '1010px' }} mx='auto'>
					{selectedFloor.rows.map((row) => {
						const filteredSeats = row.seats.filter((seat) => {
							if (filter === 'all') return true;
							if (filter === 'booked')
								return !!seat.user && !seat.user?.isCheckedIn;
							if (filter === 'free') return !seat.user;
							if (filter === 'checkedin') return seat.user?.isCheckedIn;
							return true;
						});
						if (filteredSeats.length === 0) return null;
						return (
							<Box key={row._id} mb={3}>
								<Box sx={{ mb: 2 }}>
									<Box
										sx={{
											display: 'flex',
											justifyContent: 'center',
											gap: 1,
											minWidth: 'max-content',
											margin: '0 auto',
										}}
									>
										{filteredSeats.map((seat) => {
											const isBooked = !!seat.user;
											const isSelected = selectedSeat.some(
												(s) => s.seatId === seat._id
											);
											const isCheckedIn = seat.user?.isCheckedIn;

											let borderStyle = '',
												backgroundColor = '',
												iconFill = '',
												tooltipTitle = '';

											if (isBooked) {
												if (isCheckedIn) {
													borderStyle = '2px solid #1976d2';
													backgroundColor = '#e3f2fd';
													iconFill = '#1976d2';
													tooltipTitle = (
														<p
															style={{
																fontSize: '12px',
																textAlign: 'center',
																marginBottom: 0,
															}}
														>
															Checked in <br /> {seat.user?.name ?? ''} <br />
															{seat.user?.contactNumber ?? ''}
														</p>
													);
												} else {
													borderStyle = '2px solid #d32f2f';
													backgroundColor = '#f5f5f5';
													iconFill = '#d32f2f';
													tooltipTitle = (
														<p
															style={{
																fontSize: '12px',
																textAlign: 'center',
																marginBottom: 0,
															}}
														>
															Assigned to <br /> {seat.user?.name ?? ''} <br />
															{seat.user?.contactNumber ?? ''}
														</p>
													);
												}
											} else {
												if (isSelected) {
													borderStyle = '2px solid #ff9800';
													backgroundColor = '#fff3e0';
													iconFill = '#ff9800';
													tooltipTitle = 'Selected';
												} else {
													borderStyle = '1px solid #ccc';
													backgroundColor = '#fff';
													iconFill = '#4caf50';
													tooltipTitle = 'Free';
												}
											}

											if (isSelected && (isBooked || isCheckedIn)) {
												backgroundColor = '#fff3e0';
												iconFill = '#ff9800';
											}

											return (
												<Tooltip key={seat._id} title={tooltipTitle} arrow>
													<Box flex='0 0 auto'>
														<Paper
															elevation={isSelected ? 6 : 2}
															sx={{
																p: 1,
																textAlign: 'center',
																border: borderStyle,
																cursor: 'pointer',
																backgroundColor: backgroundColor,
																width: 70,
																height: 90,
																display: 'flex',
																flexDirection: 'column',
																alignItems: 'center',
																justifyContent: 'center',
															}}
															onClick={() =>
																handleSeatClick(
																	seat,
																	row._id,
																	selectedFloor._id
																)
															}
														>
															<IconButton disableRipple>
																<SeatIcon
																	fill={iconFill}
																	width={40}
																	height={40}
																/>
															</IconButton>
															<Typography variant='caption'>
																{selectedFloor.floorName
																	?.charAt(0)
																	.toUpperCase()}
																{seat?.number.toString().padStart(3, '0')}
															</Typography>
														</Paper>
													</Box>
												</Tooltip>
											);
										})}
									</Box>
								</Box>
							</Box>
						);
					})}
				</Box>
			</Box>

			<Dialog
				open={openOtpModal}
				TransitionComponent={Transition}
				keepMounted
				onClose={handleCloseOtpModal}
				aria-describedby='otp-dialog-description'
			>
				<DialogTitle sx={{ fontWeight: '600', paddingBottom: '0' }}>
					Submit OTP to Swap Seats
				</DialogTitle>
				<DialogContent sx={{ paddingTop: '20px' }}>
					<Box sx={{ display: 'flex', gap: 2 }}>
						<TextField
							label={`Enter OTP from admin`}
							name='otp'
							value={otp}
							onChange={(e) => setOtp(e.target.value)}
						/>
						{/* <TextField
							label={`Enter OTP for ${selectedSeat[0]?.user?.name || 'Seat 1'}`}
							name='otp1'
							value={otp1}
							onChange={(e) => setOtp1(e.target.value)}
						/>
						<TextField
							label={`Enter OTP for ${selectedSeat[1]?.user?.name || 'Seat 2'}`}
							name='otp2'
							value={otp2}
							onChange={(e) => setOtp2(e.target.value)}
						/> */}
					</Box>
				</DialogContent>
				<DialogActions sx={{ gap: '20px', padding: '16px' }}>
					<Button onClick={handleCloseOtpModal}>Cancel</Button>
					<Button
						variant='contained'
						onClick={handleOtpSubmit}
						disabled={loadingBtn}
					>
						{loadingBtn ? <CircularProgress size={24} /> : 'Submit OTP'}
					</Button>
				</DialogActions>
			</Dialog>
		</DashboardLayout>
	);
};

export default ItikaafAssignedFloor;
