import React, { useEffect, useState, useRef } from 'react';
import './style.css';
import '../user-list/user-list.css';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import {
	getCityBasedUserList,
	getFloorPlanListApi,
} from '../../../api/adminApi';
import { adminSelector } from '../../../redux/slicers/adminSlice';
import DashboardLayout from '../dashboardLayout';
import { PulseLoader } from 'react-spinners';
import Pagination from '../../../components/common/Pagination';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Slide from '@mui/material/Slide';
import ViewFloorPlan from './ViewUserFloorPlan';
import { errorToast, getLocal } from '../../../helpers/projectHelper';
import { USER_DATA } from '../../../config/constants/projectConstant';
import { useDebouncedEffect } from '../../../helpers/hooks';
import { DEBOUNCE_DELAY } from '../../../config/constants/projectConstant';
import { fileDownloadApi } from '../../../api/Sessions'; // Import fileDownloadApi for image URLs

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction='up' ref={ref} {...props} />;
});

const MasjidList = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { pathname } = useLocation();
	const { userLists = [] } = useSelector(adminSelector);

	const cityName = pathname.split('/')[3];
	const masjidId = pathname.split('/')[4];

	const [loading, setLoading] = useState(true);
	const [userRole, setUserRole] = useState('observer');
	const [listSize, setListsize] = useState(1);
	const [pageNumbers, setPageNumbers] = useState([]);
	const [limit, setLimit] = useState({ start: 1, end: 3 });
	const [page, setPage] = useState(1);
	const SIZE = 10;
	const [checkedItems, setCheckedItems] = useState([]);
	const [selectAll, setSelectAll] = useState(false);
	const [open, setOpen] = useState(false);
	const [listStatus, setListStatus] = useState('unassigned');

	const [searchTerm, setSearchTerm] = useState('');
	const [showFilter, setShowFilter] = useState(false);
	const [filterItikafType, setFilterItikafType] = useState('');
	const [filterKhidmat, setFilterKhidmat] = useState('');
	const filterRef = useRef(null);
	const isInitialMount = useRef(true);

	const [editUser, setEditUser] = useState(null);

	const handleSelectAllChange = (event) => {
		setSelectAll(event.target.checked);
		if (event.target.checked) {
			const allItemIds = userLists.map((item) => item?._id);
			setCheckedItems(allItemIds);
		} else {
			setCheckedItems([]);
		}
	};

	const handleCheckboxChange = (event, itemId) => {
		if (event.target.checked) {
			setCheckedItems([...checkedItems, itemId]);
		} else {
			setCheckedItems(checkedItems.filter((id) => id !== itemId));
		}
	};

	const handleClickOpen = () => {
		if (checkedItems.length === 0) {
			errorToast('Please check at least one user');
		} else {
			setOpen(true);
		}
	};

	const handleListStatus = (status) => {
		setSearchTerm('');
		setShowFilter(false);
		setFilterItikafType('');
		setFilterKhidmat('');
		setListStatus(status);
	};

	const handleClose = () => {
		setOpen(false);
		setCheckedItems([]);
	};

	useEffect(() => {
		const userData = getLocal(USER_DATA);
		setUserRole(userData?.role || 'observer');
	}, []);

	useEffect(() => {
		const totalPages = Math.ceil(listSize / SIZE);
		const pages = [];
		for (let i = 1; i <= totalPages; i++) {
			pages.push(i);
		}
		setPageNumbers(pages);
	}, [listSize]);

	useEffect(() => {
		const handleClickOutside = (event) => {
			if (filterRef.current && !filterRef.current.contains(event.target)) {
				setShowFilter(false);
			}
		};
		document.addEventListener('mousedown', handleClickOutside);
		return () => document.removeEventListener('mousedown', handleClickOutside);
	}, []);

	const getFloorPlanList = () => {
		dispatch(
			getCityBasedUserList(
				cityName,
				masjidId,
				SIZE,
				setListsize,
				page,
				setLoading,
				listStatus,
				{
					search: searchTerm,
					itikafType: filterItikafType,
					khidmat: filterKhidmat,
				}
			)
		);
	};

	useEffect(() => {
		getFloorPlanList();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [page, listStatus]);

	useDebouncedEffect(
		() => {
			if (isInitialMount.current) {
				isInitialMount.current = false;
				return;
			}
			setPage(1);
			getFloorPlanList();
		},
		DEBOUNCE_DELAY,
		[searchTerm]
	);

	return (
		<>
			<DashboardLayout>
				<div className='mt-2'>
					<div className='d-flex flex-column flex-md-row justify-content-between align-items-md-center mb-3 bg-white p-3 rounded-2'>
						<h1 className='responsive-heading'>
							Confirmed City Based User List
						</h1>
						<div className='d-flex justify-content-between align-items-center gap-3'>
							<Button
								variant='contained'
								className='back-btn'
								onClick={() => navigate(-1)}
							>
								<i className='fa-solid fa-arrow-left'></i> Back
							</Button>
							{userRole !== 'observer' && (
								<Button variant='outlined' onClick={handleClickOpen}>
									Book
								</Button>
							)}
						</div>
					</div>

					<div className='d-flex justify-content-between justify-content-md-center mb-3 gap-3'>
						<button
							className={`btn btn-lg ${
								listStatus === 'unassigned'
									? 'btn-primary'
									: 'btn-outline-primary'
							}`}
							onClick={() => handleListStatus('unassigned')}
						>
							Yet to Assign Seat
						</button>
						<button
							className={`btn btn-lg ${
								listStatus === 'assigned'
									? 'btn-primary'
									: 'btn-outline-primary'
							}`}
							onClick={() => handleListStatus('assigned')}
						>
							Already Seat Assigned
						</button>
					</div>

					<div className='flex table-lists'>
						<div className='d-flex justify-content-between justify-content-sm-end align-items-center my-3'>
							<div style={{ maxWidth: '300px' }} className='mr-3'>
								<input
									type='text'
									placeholder='Search...'
									className='form-control'
									value={searchTerm}
									onChange={(e) => setSearchTerm(e.target.value)}
								/>
							</div>
							<div className='dropdown'>
								<button
									className='btn btn-lg btn-primary'
									onClick={() => setShowFilter(!showFilter)}
								>
									Filter By
								</button>
								<div
									ref={filterRef}
									className={`dropdown-menu dropdown-menu-right p-3 ${
										showFilter ? 'show' : ''
									}`}
									style={{ minWidth: '300px' }}
								>
									<div className='form-group'>
										<label>I'tikaf Type</label>
										<select
											className='form-control'
											value={filterItikafType}
											onChange={(e) => setFilterItikafType(e.target.value)}
										>
											<option value=''>Select I'tikaf Type</option>
											<option value='sunnat'>Sunnah</option>
											<option value='nafil'>Nafil</option>
										</select>
									</div>
									<div className='form-group'>
										<label>Khidmat</label>
										<select
											className='form-control'
											value={filterKhidmat}
											onChange={(e) => setFilterKhidmat(e.target.value)}
										>
											<option value=''>Select Khidmat</option>
											<option value='Khane Ki Khidmat'>Khane Ki Khidmat</option>
											<option value='Safai Ki Khidmat'>Safai Ki Khidmat</option>
											<option value='Nigrani Ki Khidmat'>
												Nigrani Ki Khidmat
											</option>
										</select>
									</div>
									<Button
										variant='contained'
										className='mt-2'
										onClick={() => {
											setPage(1);
											getFloorPlanList();
											setShowFilter(false);
										}}
									>
										Apply
									</Button>
								</div>
							</div>
						</div>
						<div className='flex-large'>
							<table className='table' style={{ border: '1px solid #e9ecef' }}>
								<thead>
									<tr>
										{listStatus !== 'assigned' && userRole !== 'observer' && (
											<th style={{ width: '10%' }}>
												<input
													type='checkbox'
													checked={selectAll}
													onChange={handleSelectAllChange}
													disabled={listStatus === 'assigned'}
												/>
											</th>
										)}
										<th style={{ width: '20%' }}>Name</th>
										<th style={{ width: '15%' }}>Age</th>
										<th style={{ width: '30%' }}>Email</th>
										<th style={{ width: '25%' }}>Actions</th>
									</tr>
								</thead>
								<tbody>
									{loading ? (
										<tr className='loading-tr'>
											<td colSpan={5}>
												<div className='loading-table-wrapper'>
													<PulseLoader
														color='#0e5472'
														style={{
															display: 'flex',
															justifyContent: 'center',
														}}
													/>
												</div>
											</td>
										</tr>
									) : userLists?.length > 0 ? (
										userLists.map((item) => (
											<tr key={item?._id}>
												{listStatus !== 'assigned' &&
													userRole !== 'observer' && (
														<td>
															<input
																type='checkbox'
																checked={checkedItems.includes(item?._id)}
																onChange={(event) =>
																	handleCheckboxChange(event, item?._id)
																}
																disabled={listStatus === 'assigned'}
															/>
														</td>
													)}
												<td>{item?.name}</td>
												<td>{item?.age}</td>
												<td>{item?.email}</td>
												<td>
													<button
														className='btn btn-primary'
														data-toggle='modal'
														data-target='#userDetailModal'
														onClick={() => setEditUser(item)}
														style={{ minWidth: '30px', height: '35px' }}
													>
														<i className='fa-regular fa-eye'></i>
													</button>
												</td>
											</tr>
										))
									) : (
										<tr>
											<td colSpan={5}>
												<h1 style={{ textAlign: 'center' }}>No data found</h1>
											</td>
										</tr>
									)}
								</tbody>
							</table>
							<div className='customer_page'>
								<div className='pagination'>
									<Pagination
										setPage={setPage}
										limit={limit}
										pageNumbers={pageNumbers}
										setLimit={setLimit}
										page={page}
									/>
								</div>
							</div>
						</div>
					</div>
				</div>
			</DashboardLayout>

			<Dialog
				fullScreen
				open={open}
				onClose={handleClose}
				TransitionComponent={Transition}
			>
				<AppBar sx={{ position: 'relative' }}>
					<Toolbar sx={{ justifyContent: 'flex-end' }}>
						<IconButton
							edge='start'
							color='inherit'
							onClick={handleClose}
							aria-label='close'
						>
							<i className='fa-solid fa-xmark'></i>
							<Typography sx={{ ml: 2, flex: 1 }} variant='h6' component='div'>
								Close
							</Typography>
						</IconButton>
					</Toolbar>
				</AppBar>
				<ViewFloorPlan
					checkedItems={checkedItems}
					isPopup={true}
					handleClose={handleClose}
					getFloorPlanList={getFloorPlanList}
				/>
			</Dialog>

			<div
				className='modal fade'
				id='userDetailModal'
				tabIndex='-1'
				aria-labelledby='userDetailsModalLabel'
				aria-hidden='true'
			>
				<div className='modal-dialog shadow-none modal-dialog-centered'>
					<div className='modal-content'>
						<div className='modal-header'>
							<h5 className='modal-title' id='userDetailsModalLabel'>
								User details
							</h5>
							<button type='button' className='close' data-dismiss='modal'>
								&times;
							</button>
						</div>
						<div className='modal-body'>
							<div className='user-det'>
								<img
									src={editUser ? fileDownloadApi(editUser.photo) : ''}
									alt='user'
								/>
							</div>
							<table className='table'>
								<tbody>
									<tr>
										<td style={{ width: '50%' }}>
											Status <span style={{ float: 'right' }}>:</span>
										</td>
										<td>{editUser?.status}</td>
									</tr>
									<tr>
										<td style={{ width: '50%' }}>
											Name <span style={{ float: 'right' }}>:</span>
										</td>
										<td>{editUser?.name}</td>
									</tr>
									<tr>
										<td style={{ width: '50%' }}>
											Age <span style={{ float: 'right' }}>:</span>
										</td>
										<td>{editUser?.age}</td>
									</tr>
									<tr>
										<td style={{ width: '50%' }}>
											Has Met Sheikh <span style={{ float: 'right' }}>:</span>
										</td>
										<td>{editUser?.hasMetSheikh ? 'Yes' : 'No'}</td>
									</tr>
									<tr>
										<td style={{ width: '50%' }}>
											Contact Number <span style={{ float: 'right' }}>:</span>
										</td>
										<td>{editUser?.contactNumber}</td>
									</tr>
									<tr>
										<td style={{ width: '50%' }}>
											Whatsapp Number <span style={{ float: 'right' }}>:</span>
										</td>
										<td>{editUser?.whatsappNumber}</td>
									</tr>
									<tr>
										<td style={{ width: '50%' }}>
											City <span style={{ float: 'right' }}>:</span>
										</td>
										<td>{editUser?.city}</td>
									</tr>
									<tr>
										<td style={{ width: '50%' }}>
											State <span style={{ float: 'right' }}>:</span>
										</td>
										<td>{editUser?.state}</td>
									</tr>
									<tr>
										<td style={{ width: '50%' }}>
											Etekaaf Maqsad <span style={{ float: 'right' }}>:</span>
										</td>
										<td>{editUser?.etekaafMaqsad}</td>
									</tr>
									<tr>
										<td style={{ width: '50%' }}>
											Itikaf Type <span style={{ float: 'right' }}>:</span>
										</td>
										<td>{editUser?.typeOfIthikaaf}</td>
									</tr>
									<tr>
										<td style={{ width: '50%' }}>
											Nafil Days <span style={{ float: 'right' }}>:</span>
										</td>
										<td>{editUser?.nafilDays}</td>
									</tr>
									<tr>
										<td style={{ width: '50%' }}>
											Is Baiyath <span style={{ float: 'right' }}>:</span>
										</td>
										<td>{editUser?.baiyath?.isbaiyath ? 'Yes' : 'No'}</td>
									</tr>
									<tr>
										<td style={{ width: '50%' }}>
											Baiyath With <span style={{ float: 'right' }}>:</span>
										</td>
										<td>{editUser?.baiyath?.baiyathWith || 'N/A'}</td>
									</tr>
									<tr>
										<td style={{ width: '50%' }}>
											Baiyath Duration <span style={{ float: 'right' }}>:</span>
										</td>
										<td>{editUser?.baiyath?.howLongBaiyath || 'N/A'}</td>
									</tr>
									<tr>
										<td style={{ width: '50%' }}>
											Muraqaba <span style={{ float: 'right' }}>:</span>
										</td>
										<td>{editUser?.baiyath?.muqaraba || 'N/A'}</td>
									</tr>
									<tr>
										<td style={{ width: '50%' }}>
											Sabaq <span style={{ float: 'right' }}>:</span>
										</td>
										<td>{editUser?.baiyath?.sabaq || 'N/A'}</td>
									</tr>
									<tr>
										<td style={{ width: '50%' }}>
											Notes <span style={{ float: 'right' }}>:</span>
										</td>
										<td>{editUser?.notes}</td>
									</tr>
									{editUser?.officialIdProof && (
										<tr>
											<td style={{ width: '50%' }}>
												Id Proof <span style={{ float: 'right' }}>:</span>
											</td>
											<td>
												<div className='user-detail'>
													<img
														src={fileDownloadApi(editUser?.officialIdProof)}
														alt='id-proof'
													/>
												</div>
											</td>
										</tr>
									)}
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default MasjidList;
