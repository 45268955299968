import React, { useEffect, useState } from 'react';
import DashboardLayout from '../dashboardLayout';
import './floor-plan.css';
import { addFloorPlanApi, getFloorPlanListApi } from '../../../api/adminApi';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Button, TextField, Select } from '@mui/material';
import { ReactComponent as SeatIcon } from '../../../assets/images/floor/mosqueIcon.svg';
import SelectInput from '../../../components/common/SelectInput';
import moment from 'moment';

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import { createFloorValidateField } from '../../../helpers/validateField';

function Seat(props) {
	const availability = props.available ? 'available' : 'booked';
	return (
		<div className='seat-icon create' onClick={props.onClick}>
			<SeatIcon />
			<p className='seat-icon-info'>{props?.name}</p>
		</div>
	);
}

function recalcSeatNumbers(rows) {
	let counter = 1;
	return rows.map((row) => {
		const newSeats = row.seats.map((seat) => ({ ...seat, number: counter++ }));
		return { ...row, seats: newSeats };
	});
}

function Floor(props) {
	const { floorName, rows, onModifyRow } = props;

	function addRow() {
		const currentSeatCount = rows.reduce(
			(total, row) => total + row.seats.length,
			0
		);
		const numColumns = rows.length > 0 ? rows[0].seats.length : 0;
		const newRow = {
			rowName: String.fromCharCode(65 + rows.length),
			seats: Array.from({ length: numColumns }, (_, index) => ({
				number: currentSeatCount + index + 1,
				registeredUser: null,
			})),
		};
		const updatedRows = recalcSeatNumbers([...rows, newRow]);
		onModifyRow(updatedRows);
	}

	function handleDeleteColumn(rowIndex, columnIndex) {
		const updatedRows = rows.map((row, i) => {
			if (i === rowIndex) {
				const newSeats = row.seats.filter((seat, j) => j !== columnIndex);
				return { ...row, seats: newSeats };
			}
			return row;
		});
		onModifyRow(recalcSeatNumbers(updatedRows));
	}

	function handleModifyRow(rowIndex, modifiedRow) {
		const updatedRows = rows.map((row, i) =>
			i === rowIndex ? modifiedRow : row
		);
		onModifyRow(recalcSeatNumbers(updatedRows));
	}

	return (
		<div className='floor'>
			<h2>{floorName}</h2>
			<div
				className='floor-wrap'
				style={{
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'center',
				}}
			>
				{rows.map((row, rowIndex) => (
					<Row
						key={row.rowName}
						row={row}
						rowIndex={rowIndex}
						onDeleteColumn={(columnIndex) =>
							handleDeleteColumn(rowIndex, columnIndex)
						}
						onModifyRow={(modifiedRow) =>
							handleModifyRow(rowIndex, modifiedRow)
						}
					/>
				))}
			</div>
			<Button variant='outlined' onClick={addRow}>
				Add Row
			</Button>
		</div>
	);
}

function Row(props) {
	const { row, rowIndex, onDeleteColumn, onModifyRow } = props;

	function toggleSeatAvailability(index) {
		const modifiedRow = { ...row };
		modifiedRow.seats = modifiedRow.seats.map((seat, j) =>
			j === index ? { ...seat, registeredUser: !seat.registeredUser } : seat
		);
		onModifyRow(modifiedRow);
	}

	function handleDeleteColumn() {
		if (row.seats.length === 0) return;
		onDeleteColumn(row.seats.length - 1);
	}

	function handleAddSeat() {
		const modifiedRow = {
			...row,
			seats: [...row.seats, { number: 0, registeredUser: null }],
		};
		onModifyRow(modifiedRow);
	}

	return (
		<div
			className='seat-view-wrapper'
			style={{
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'center',
				margin: '0 auto',
			}}
		>
			<div className='wrap' style={{ display: 'flex', alignItems: 'center' }}>
				{row.seats.map((seat, index) => (
					<Seat
						key={seat.number}
						name={seat.number}
						available={!seat.registeredUser}
						onClick={() => toggleSeatAvailability(index)}
					/>
				))}
			</div>
			<div
				className='seat-buttons'
				style={{
					display: 'flex',
					flexDirection: 'column',
					justifyContent: 'center',
					marginLeft: '1rem',
					gap: '20px',
				}}
			>
				<Button
					variant='contained'
					color='error'
					className='remove-seat'
					onClick={handleDeleteColumn}
				>
					<i className='fa-solid fa-minus'></i>
				</Button>
				<Button
					variant='contained'
					color='primary'
					className='add-seat'
					onClick={handleAddSeat}
				>
					<i className='fa-solid fa-plus'></i>
				</Button>
			</div>
		</div>
	);
}

function CreateFloorPlan() {
	const InitialState = {
		masjidName: '',
		cityName: '',
		floorName: '',
		rowCount: 3,
		columnCount: 3,
		regOpen: false,
		regStartDateTime: '',
		regEndDateTime: '',
		confirmationDateTime: '',
		ithkaafDateTime: '',
	};

	const [formData, setFormData] = useState(InitialState);
	const [errors, setErrors] = useState({});
	const [floorErr, setFloorErr] = useState({});
	const [floorData, setFloorData] = useState({ floors: [] });
	const [loading, setLoading] = useState(false);
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const {
		masjidName,
		cityName,
		floorName,
		rowCount,
		columnCount,
		regOpen,
		regStartDateTime,
		regEndDateTime,
		confirmationDateTime,
		ithkaafDateTime,
	} = formData;

	const ErrorMessage = ({ name }) => {
		return errors[name] && <p className='error-field'>{errors[name]}</p>;
	};

	const handleChange = (event) => {
		const { name, value } = event.target;
		const fieldError = createFloorValidateField(name, value);

		setFormData((prevData) => ({
			...prevData,
			[name]:
				name === 'rowCount' || name === 'columnCount'
					? parseInt(value, 10)
					: value,
		}));

		if (fieldError) {
			setErrors({ [name]: fieldError });
		} else {
			setErrors({});
		}
	};

	function generateFloor() {
		if (formData?.floorName) {
			setFloorErr((prevData) => ({
				...prevData,
				['floorName']: '',
			}));
			const newFloor = {
				floorName,
				rows: Array.from({ length: rowCount }, (_, i) => ({
					rowName: formData?.floorName?.charAt(0) + getRowName(i),
					seats: Array.from({ length: columnCount }, (_, j) => ({
						number: i * columnCount + j + 1,
						registeredUser: null,
					})),
				})),
				capacity: rowCount * columnCount,
			};

			function getRowName(index) {
				const baseChar = 65; // ASCII code for 'A'
				let rowName = '';

				while (index >= 0) {
					const charCode = baseChar + (index % 26);
					rowName = String.fromCharCode(charCode) + rowName;
					index = Math.floor(index / 26) - 1;
				}
				return rowName;
			}

			setFloorData((prevFloorData) => ({
				name: masjidName,
				city: cityName,
				floors: [...(prevFloorData?.floors || []), newFloor],
				registrationOpen: regOpen,
				registrationStart: moment(regStartDateTime).format(
					'YYYY-MM-DDTHH:mm:ssZ'
				),
				registrationEnd: moment(regEndDateTime).format('YYYY-MM-DDTHH:mm:ssZ'),
				confirmationDate: moment(confirmationDateTime).format(
					'YYYY-MM-DDTHH:mm:ssZ'
				),
				ithikaafDate: moment(ithkaafDateTime).format('YYYY-MM-DDTHH:mm:ssZ'),
				year: `${moment().year()}`,
			}));

			setFormData((prevData) => ({
				...prevData,
				floorName: '',
				rowCount: 3,
				columnCount: 3,
			}));
		} else {
			setFloorErr((prevData) => ({
				...prevData,
				['floorName']: 'Floor Name is required',
			}));
		}
	}

	function handleModifyRow(floorIndex, updatedRows) {
		setFloorData((prevFloorData) => {
			const updatedFloors = [...prevFloorData.floors];
			updatedFloors[floorIndex].rows = updatedRows;
			updatedFloors[floorIndex].capacity = updatedRows.reduce(
				(totalSeats, row) => totalSeats + row.seats.length,
				0
			);
			return {
				...prevFloorData,
				floors: updatedFloors,
			};
		});
	}

	function handleSubmit() {
		const formErrors = {};
		Object.keys(formData).forEach((name) => {
			const value = formData[name];
			const fieldError = createFloorValidateField(name, value);
			if (fieldError) {
				formErrors[name] = fieldError;
			}
		});

		setErrors(formErrors);

		if (floorData?.floors?.length > 0) {
			setFloorErr({});
		} else {
			setFloorErr((prevData) => ({
				...prevData,
				['floorData']: 'Floor is required',
			}));
		}
		if (
			Object.keys(formErrors).length === 0 &&
			Object.keys(floorErr).length === 0
		) {
			dispatch(addFloorPlanApi(floorData, setLoading, navigate));
		}
	}

	return (
		<DashboardLayout>
			<div className='floor-map' style={{ maxWidth: '1080px' }}>
				<div className='controls'>
					<div className='back-btn-wrap'>
						<Button
							variant='contained'
							className='back-btn'
							onClick={() => navigate(-1)}
						>
							<i className='fa-solid fa-arrow-left'></i> Back
						</Button>
					</div>
					<h1>Masjid Details</h1>
					<br />
					<div className='form-group-control'>
						<div className='form-group w50'>
							<TextField
								type='text'
								variant='outlined'
								label='Masjid Name:'
								name='masjidName'
								value={formData?.masjidName}
								onChange={handleChange}
							/>
							{errors?.masjidName && (
								<p className='error-msg'>
									<ErrorMessage name='masjidName' />
								</p>
							)}
						</div>
						<div className='form-group w50'>
							<TextField
								type='text'
								variant='outlined'
								label='City Name:'
								name='cityName'
								value={formData?.cityName}
								onChange={handleChange}
							/>
							{errors?.cityName && (
								<p className='error-msg'>
									<ErrorMessage name='cityName' />
								</p>
							)}
						</div>
					</div>
					<hr
						style={{
							width: '100%',
							backgroundColor: 'black',
							fontWeight: 'bold',
						}}
					/>
					<h1>Masjid Etikaaf Information Details</h1>
					<br />
					<div className='form-group-control fields'>
						<div className='form-group w50 select'>
							<FormControl fullWidth>
								<InputLabel id='demo-simple-select-label'>
									Regestation Open
								</InputLabel>
								<Select
									labelId='demo-simple-select-label'
									id='demo-simple-select'
									value={formData?.regOpen}
									name='regOpen'
									label='Regestration Open'
									onChange={handleChange}
								>
									<MenuItem value={true}>Yes</MenuItem>
									<MenuItem value={false}>No</MenuItem>
								</Select>
							</FormControl>
						</div>
						<div className='form-group w50'>
							<TextField
								InputLabelProps={{ shrink: true }}
								type='datetime-local'
								variant='outlined'
								label='Regestration Start Date and Time:'
								name='regStartDateTime'
								value={formData?.regStartDateTime}
								onChange={handleChange}
							/>
							{errors?.regStartDateTime && (
								<p className='error-msg'>
									<ErrorMessage name='regStartDateTime' />
								</p>
							)}
						</div>
						<div className='form-group w50'>
							<TextField
								InputLabelProps={{ shrink: true }}
								type='datetime-local'
								variant='outlined'
								label='Regestration End Date and Time:'
								name='regEndDateTime'
								value={formData?.regEndDateTime}
								onChange={handleChange}
							/>
							{errors?.regEndDateTime && (
								<p className='error-msg'>
									<ErrorMessage name='regEndDateTime' />
								</p>
							)}
						</div>
						<div className='form-group w50'>
							<TextField
								InputLabelProps={{ shrink: true }}
								type='datetime-local'
								variant='outlined'
								label='Confirmation Date and Time:'
								name='confirmationDateTime'
								value={formData?.confirmationDateTime}
								onChange={handleChange}
							/>
							{errors?.confirmationDateTime && (
								<p className='error-msg'>
									<ErrorMessage name='confirmationDateTime' />
								</p>
							)}
						</div>
						<div className='form-group w50'>
							<TextField
								InputLabelProps={{ shrink: true }}
								type='datetime-local'
								variant='outlined'
								label='Ithikaaf Date and Time:'
								name='ithkaafDateTime'
								value={formData?.ithkaafDateTime}
								onChange={handleChange}
							/>
							{errors?.ithkaafDateTime && (
								<p className='error-msg'>
									<ErrorMessage name='ithkaafDateTime' />
								</p>
							)}
						</div>
						<hr
							style={{
								width: '100%',
								backgroundColor: 'black',
								fontWeight: 'bold',
							}}
						/>
						<h1>Masjid Floor Info</h1>
						<br />
						<div className='form-group w100'>
							<TextField
								variant='outlined'
								type='text'
								label='Floor Name:'
								name='floorName'
								value={formData?.floorName}
								onChange={handleChange}
							/>
							{floorErr?.floorName && (
								<p className='error-msg'>{floorErr?.floorName}</p>
							)}
						</div>
						<div className='form-group w50'>
							<TextField
								type='number'
								variant='outlined'
								label='Number of Rows:'
								name='rowCount'
								value={formData?.rowCount}
								onChange={handleChange}
							/>
							{errors?.rowCount && (
								<p className='error-msg'>
									<ErrorMessage name='rowCount' />
								</p>
							)}
						</div>
						<div className='form-group w50'>
							<TextField
								type='number'
								variant='outlined'
								label='Number of Columns:'
								name='columnCount'
								value={formData?.columnCount}
								onChange={handleChange}
							/>
							{errors?.columnCount && (
								<p className='error-msg'>
									<ErrorMessage name='columnCount' />
								</p>
							)}
						</div>
					</div>
					<Button
						variant='outlined'
						className='generate-floor'
						onClick={generateFloor}
					>
						Add Floor
					</Button>
					{floorErr?.floorData && (
						<p className='error-msg floor-msg'>{floorErr?.floorData}</p>
					)}
				</div>
				{floorData?.floors?.map((floor, index) => (
					<Floor
						key={index}
						floorName={floor.floorName}
						rows={floor.rows}
						onModifyRow={(updatedRows) => handleModifyRow(index, updatedRows)}
					/>
				))}
				<div className='submit-btn-wrapper'>
					<Button variant='contained' onClick={handleSubmit}>
						Submit Changes
					</Button>
				</div>
			</div>
		</DashboardLayout>
	);
}

export default CreateFloorPlan;
