import React, { useEffect, useState } from 'react';
import DashboardLayout from '../dashboardLayout';
import './floor-plan.css';
import {
	updateFloorPlanApi,
	getFloorPlanListByIdApi,
} from '../../../api/adminApi';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, TextField, Select } from '@mui/material';
import { ReactComponent as SeatIcon } from '../../../assets/images/floor/mosqueIcon.svg';
import moment from 'moment';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import { createFloorValidateField } from '../../../helpers/validateField';
import { adminSelector } from '../../../redux/slicers/adminSlice';
import { errorToast } from '../../../helpers/projectHelper';

function Seat(props) {
	return (
		<div className='seat-icon create' onClick={props.onClick}>
			<SeatIcon />
			<p className='seat-icon-info'>{props?.name}</p>
		</div>
	);
}

function recalcSeatNumbers(rows) {
	let counter = 1;
	return rows.map((row) => {
		const newSeats = row.seats.map((seat) => ({ ...seat, number: counter++ }));
		return { ...row, seats: newSeats };
	});
}

function Floor(props) {
	const { floorName, rows, onModifyRow } = props;

	function addRow() {
		const currentSeatCount = rows.reduce(
			(total, row) => total + row.seats.length,
			0
		);
		const numColumns = rows.length > 0 ? rows[0].seats.length : 0;
		const newRow = {
			rowName: String.fromCharCode(65 + rows.length),
			seats: Array.from({ length: numColumns }, (_, index) => ({
				number: currentSeatCount + index + 1,
				registeredUser: null,
			})),
		};
		const updatedRows = recalcSeatNumbers([...rows, newRow]);
		onModifyRow(updatedRows);
	}

	function handleDeleteColumn(rowIndex, columnIndex) {
		const updatedRows = rows.map((row, i) => {
			if (i === rowIndex) {
				const newSeats = row.seats.filter((seat, j) => j !== columnIndex);
				return { ...row, seats: newSeats };
			}
			return row;
		});
		onModifyRow(recalcSeatNumbers(updatedRows));
	}

	function handleModifyRow(rowIndex, modifiedRow) {
		const updatedRows = rows.map((row, i) =>
			i === rowIndex ? modifiedRow : row
		);
		onModifyRow(recalcSeatNumbers(updatedRows));
	}

	return (
		<div className='floor'>
			<h2>{floorName}</h2>
			<div
				className='floor-wrap'
				style={{
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'center',
				}}
			>
				{rows.map((row, rowIndex) => (
					<Row
						key={row.rowName}
						row={row}
						rowIndex={rowIndex}
						onDeleteColumn={(columnIndex) =>
							handleDeleteColumn(rowIndex, columnIndex)
						}
						onModifyRow={(modifiedRow) =>
							handleModifyRow(rowIndex, modifiedRow)
						}
					/>
				))}
			</div>
			<Button variant='outlined' onClick={addRow}>
				Add Row
			</Button>
		</div>
	);
}

function Row(props) {
	const { row, onDeleteColumn, onModifyRow } = props;

	function toggleSeatAvailability(index) {
		const modifiedRow = { ...row };
		modifiedRow.seats = modifiedRow.seats.map((seat, j) =>
			j === index ? { ...seat, registeredUser: !seat.registeredUser } : seat
		);
		onModifyRow(modifiedRow);
	}

	function handleDeleteColumn() {
		if (row.seats.length === 0) return;
		onDeleteColumn(row.seats.length - 1);
	}

	function handleAddSeat() {
		const modifiedRow = {
			...row,
			seats: [...row.seats, { number: 0, registeredUser: null }],
		};
		onModifyRow(modifiedRow);
	}

	return (
		<div
			className='seat-view-wrapper'
			style={{
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'center',
				margin: '0 auto',
			}}
		>
			<div className='wrap' style={{ display: 'flex', alignItems: 'center' }}>
				{row.seats.map((seat, index) => (
					<Seat
						key={seat.number}
						name={seat.number}
						available={!seat.registeredUser}
						onClick={() => toggleSeatAvailability(index)}
					/>
				))}
			</div>
			<div
				className='seat-buttons'
				style={{
					display: 'flex',
					flexDirection: 'column',
					justifyContent: 'center',
					marginLeft: '1rem',
					gap: '20px',
				}}
			>
				<Button variant='contained' color='error' onClick={handleDeleteColumn}>
					<i className='fa-solid fa-minus'></i>
				</Button>
				<Button variant='contained' color='primary' onClick={handleAddSeat}>
					<i className='fa-solid fa-plus'></i>
				</Button>
			</div>
		</div>
	);
}

const EditFloorPlan = () => {
	const { id } = useParams();
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { floorPlanListsById } = useSelector(adminSelector);
	const [loading, setLoading] = useState(true);
	const [loadingBtn, setLoadingBtn] = useState(false);
	const [isSeatLayoutChanged, setIsSeatLayoutChanged] = useState(false);
	const [canAssignSeat, setCanAssignSeat] = useState(false);

	const [formData, setFormData] = useState({
		masjidName: '',
		cityName: '',
		floorName: '',
		rowCount: 3,
		columnCount: 3,
		regOpen: false,
		canAssignSeat: false,
		regStartDateTime: '',
		regEndDateTime: '',
		confirmationDateTime: '',
		ithkaafDateTime: '',
	});

	const [errors, setErrors] = useState({});
	const [floorErr, setFloorErr] = useState({});
	const [floorData, setFloorData] = useState({ floors: [] });

	useEffect(() => {
		dispatch(getFloorPlanListByIdApi(id, setLoading));
	}, [dispatch, id]);

	useEffect(() => {
		if (floorPlanListsById && Object.keys(floorPlanListsById).length) {
			setFormData({
				masjidName: floorPlanListsById.name || '',
				cityName: floorPlanListsById.city || '',
				floorName: '',
				rowCount: 3,
				columnCount: 3,
				regOpen: floorPlanListsById.registrationOpen || false,
				canAssignSeat: floorPlanListsById.canAssignSeat || false,
				regStartDateTime: moment(floorPlanListsById.registrationStart).format(
					'YYYY-MM-DDTHH:mm'
				),
				regEndDateTime: moment(floorPlanListsById.registrationEnd).format(
					'YYYY-MM-DDTHH:mm'
				),
				confirmationDateTime: moment(
					floorPlanListsById.confirmationDate
				).format('YYYY-MM-DDTHH:mm'),
				ithkaafDateTime: moment(floorPlanListsById.ithikaafDate).format(
					'YYYY-MM-DDTHH:mm'
				),
			});
			setFloorData({ floors: floorPlanListsById.floors || [] });
			setCanAssignSeat(floorPlanListsById.canAssignSeat);
		}
	}, [floorPlanListsById]);

	const handleChange = (event) => {
		const { name, value, type, checked } = event.target;
		const fieldValue = type === 'checkbox' ? checked : value;
		const fieldError = createFloorValidateField(name, fieldValue);

		setFormData((prev) => ({ ...prev, [name]: fieldValue }));
		if (fieldError) {
			setErrors({ [name]: fieldError });
		} else {
			setErrors({});
		}
	};

	function generateFloor() {
		if (canAssignSeat) {
			errorToast('Cannot update the seats');
			return;
		}
		setIsSeatLayoutChanged(true);
		if (formData.floorName) {
			setFloorErr((prev) => ({ ...prev, floorName: '' }));
			const newFloor = {
				floorName: formData.floorName,
				rows: Array.from({ length: formData.rowCount }, (_, i) => ({
					rowName: formData.floorName.charAt(0) + getRowName(i),
					seats: Array.from({ length: formData.columnCount }, (_, j) => ({
						number: i * formData.columnCount + j + 1,
						registeredUser: null,
					})),
				})),
				capacity: formData.rowCount * formData.columnCount,
			};

			function getRowName(index) {
				const baseChar = 65;
				let rowName = '';
				while (index >= 0) {
					const charCode = baseChar + (index % 26);
					rowName = String.fromCharCode(charCode) + rowName;
					index = Math.floor(index / 26) - 1;
				}
				return rowName;
			}

			setFloorData((prev) => ({
				...prev,
				floors: [...(prev.floors || []), newFloor],
			}));
			setFormData((prev) => ({
				...prev,
				floorName: '',
				rowCount: 3,
				columnCount: 3,
			}));
		} else {
			setFloorErr((prev) => ({ ...prev, floorName: 'Floor Name is required' }));
		}
	}

	function handleModifyRow(floorIndex, updatedRows) {
		if (canAssignSeat) {
			errorToast('Cannot update the seats');
			return;
		}
		setIsSeatLayoutChanged(true);
		setFloorData((prev) => {
			const updatedFloors = prev.floors.map((floor, i) => {
				if (i === floorIndex) {
					return {
						...floor,
						rows: updatedRows,
						capacity: updatedRows.reduce(
							(total, row) => total + row.seats.length,
							0
						),
					};
				}
				return floor;
			});
			return { ...prev, floors: updatedFloors };
		});
	}

	function handleSubmit() {
		const formErrors = {};
		Object.keys(formData).forEach((name) => {
			const value = formData[name];
			const fieldError = createFloorValidateField(name, value);
			if (fieldError) {
				formErrors[name] = fieldError;
			}
		});
		setErrors(formErrors);
		if (Object.keys(formErrors).length === 0) {
			setLoadingBtn(true);
			const reqBody = {
				name: formData.masjidName,
				city: formData.cityName,
				registrationOpen: formData.regOpen,
				canAssignSeat: formData.canAssignSeat,
				registrationStart: moment(formData.regStartDateTime).format(
					'YYYY-MM-DDTHH:mm:ssZ'
				),
				registrationEnd: moment(formData.regEndDateTime).format(
					'YYYY-MM-DDTHH:mm:ssZ'
				),
				confirmationDate: moment(formData.confirmationDateTime).format(
					'YYYY-MM-DDTHH:mm:ssZ'
				),
				ithikaafDate: moment(formData.ithkaafDateTime).format(
					'YYYY-MM-DDTHH:mm:ssZ'
				),
				year: `${moment().year()}`,
				...(isSeatLayoutChanged && { floors: floorData.floors }),
			};
			dispatch(
				updateFloorPlanApi(id, reqBody, () => {
					setLoadingBtn(false);
					navigate(-1);
				})
			);
		}
	}

	return (
		<DashboardLayout>
			<div className='floor-map' style={{ maxWidth: '1080px' }}>
				<div className='controls'>
					<div className='back-btn-wrap'>
						<Button
							variant='contained'
							className='back-btn'
							onClick={() => navigate(-1)}
						>
							<i className='fa-solid fa-arrow-left'></i> Back
						</Button>
					</div>
					<h1>Edit Masjid Details</h1>
					<br />
					<div className='form-group-control'>
						<div className='form-group w50'>
							<TextField
								type='text'
								variant='outlined'
								label='Masjid Name:'
								name='masjidName'
								value={formData.masjidName}
								onChange={handleChange}
							/>
							{errors.masjidName && (
								<p className='error-msg'>{errors.masjidName}</p>
							)}
						</div>
						<div className='form-group w50'>
							<TextField
								type='text'
								variant='outlined'
								label='City Name:'
								name='cityName'
								value={formData.cityName}
								onChange={handleChange}
							/>
							{errors.cityName && (
								<p className='error-msg'>{errors.cityName}</p>
							)}
						</div>
					</div>
					<hr
						style={{
							width: '100%',
							backgroundColor: 'black',
							fontWeight: 'bold',
						}}
					/>
					<h1>Masjid Etikaaf Information Details</h1>
					<br />
					<div className='form-group-control fields'>
						<div className='form-group w50 select'>
							<FormControl fullWidth>
								<InputLabel id='demo-simple-select-label'>
									Regestation Open
								</InputLabel>
								<Select
									labelId='demo-simple-select-label'
									id='demo-simple-select'
									value={formData.regOpen}
									name='regOpen'
									label='Regestration Open'
									onChange={handleChange}
								>
									<MenuItem value={true}>Yes</MenuItem>
									<MenuItem value={false}>No</MenuItem>
								</Select>
							</FormControl>
						</div>
						<div className='form-group w50 select'>
							<FormControl fullWidth>
								<InputLabel id='allow-assign-seat-select-label'>
									Allow Seat Assignment
								</InputLabel>
								<Select
									labelId='allow-assign-seat-select-label'
									id='allow-assign-seat-select'
									value={formData.canAssignSeat}
									name='canAssignSeat'
									label='Allow Seat Assignment'
									onChange={handleChange}
									disabled={canAssignSeat}
								>
									<MenuItem value={true}>Yes</MenuItem>
									<MenuItem value={false}>No</MenuItem>
								</Select>
							</FormControl>
						</div>
						<div className='form-group w50'>
							<TextField
								InputLabelProps={{ shrink: true }}
								type='datetime-local'
								variant='outlined'
								label='Regestration Start Date and Time:'
								name='regStartDateTime'
								value={formData.regStartDateTime}
								onChange={handleChange}
							/>
							{errors.regStartDateTime && (
								<p className='error-msg'>{errors.regStartDateTime}</p>
							)}
						</div>
						<div className='form-group w50'>
							<TextField
								InputLabelProps={{ shrink: true }}
								type='datetime-local'
								variant='outlined'
								label='Regestration End Date and Time:'
								name='regEndDateTime'
								value={formData.regEndDateTime}
								onChange={handleChange}
							/>
							{errors.regEndDateTime && (
								<p className='error-msg'>{errors.regEndDateTime}</p>
							)}
						</div>
						<div className='form-group w50'>
							<TextField
								InputLabelProps={{ shrink: true }}
								type='datetime-local'
								variant='outlined'
								label='Confirmation Date and Time:'
								name='confirmationDateTime'
								value={formData.confirmationDateTime}
								onChange={handleChange}
							/>
							{errors.confirmationDateTime && (
								<p className='error-msg'>{errors.confirmationDateTime}</p>
							)}
						</div>
						<div className='form-group w50'>
							<TextField
								InputLabelProps={{ shrink: true }}
								type='datetime-local'
								variant='outlined'
								label='Ithikaaf Date and Time:'
								name='ithkaafDateTime'
								value={formData.ithkaafDateTime}
								onChange={handleChange}
							/>
							{errors.ithkaafDateTime && (
								<p className='error-msg'>{errors.ithkaafDateTime}</p>
							)}
						</div>
					</div>
					<hr
						style={{
							width: '100%',
							backgroundColor: 'black',
							fontWeight: 'bold',
						}}
					/>
					<h1>Masjid Floor Info</h1>
					<br />
					<div className='form-group-control'>
						<div className='form-group w100'>
							<TextField
								variant='outlined'
								type='text'
								label='Floor Name:'
								name='floorName'
								value={formData.floorName}
								onChange={handleChange}
							/>
							{floorErr.floorName && (
								<p className='error-msg'>{floorErr.floorName}</p>
							)}
						</div>
						<div className='form-group w50'>
							<TextField
								type='number'
								variant='outlined'
								label='Number of Rows:'
								name='rowCount'
								value={formData.rowCount}
								onChange={handleChange}
							/>
							{errors.rowCount && (
								<p className='error-msg'>{errors.rowCount}</p>
							)}
						</div>
						<div className='form-group w50'>
							<TextField
								type='number'
								variant='outlined'
								label='Number of Columns:'
								name='columnCount'
								value={formData.columnCount}
								onChange={handleChange}
							/>
							{errors.columnCount && (
								<p className='error-msg'>{errors.columnCount}</p>
							)}
						</div>
					</div>
					<Button
						variant='outlined'
						className='generate-floor'
						onClick={generateFloor}
					>
						Add Floor
					</Button>
				</div>
				{floorData.floors &&
					floorData.floors.map((floor, index) => (
						<Floor
							key={index}
							floorName={floor.floorName}
							rows={floor.rows}
							onModifyRow={(updatedRows) => handleModifyRow(index, updatedRows)}
						/>
					))}
				<div className='submit-btn-wrapper'>
					<Button
						variant='contained'
						onClick={handleSubmit}
						disabled={loadingBtn}
					>
						{loadingBtn ? 'Updating...' : 'Update'}
					</Button>
				</div>
			</div>
		</DashboardLayout>
	);
};

export default EditFloorPlan;
