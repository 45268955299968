import React, { useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import './style.css';
import { setPasswordSchema } from '../../../helpers/schemas/validation';
import { logoutApi, setSubAdminPasswordApi } from '../../../api/adminApi';
import { useDispatch } from 'react-redux';
import QLogo from '../../../assets/images/Quran.jpg';
import { PulseLoader } from 'react-spinners';
const initialValues = { password: '', confirmPassword: '' };

const SetSubAdminPassword = () => {
	const [searchParams] = useSearchParams();
	const token = searchParams.get('token');
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const [loadingbtn, setLoadingbtn] = useState(false);
	const onSubmit = (values) => {
		setLoadingbtn(true);
		values.token = token;
		dispatch(setSubAdminPasswordApi(values, navigate, setLoadingbtn));
		dispatch(logoutApi());
	};

	return (
		<div className='login-page d-flex'>
			<div className='form d-flex'>
				<div className='img-div'>
					<img alt='book' src={QLogo} />
				</div>
				<Formik
					initialValues={initialValues}
					onSubmit={onSubmit}
					validationSchema={setPasswordSchema}
				>
					{({ errors, touched }) => (
						<Form className='login-form'>
							<div>
								<label htmlFor='password'>Password</label>
								<Field
									type='password'
									id='password'
									name='password'
									className='form-control col-12'
									placeholder='password'
								/>
								<p className='error-msg'>
									<ErrorMessage name='password' />
								</p>
							</div>

							<div>
								<label htmlFor='confirmPassword'>Confirm Password</label>
								<Field
									type='password'
									id='confirmPassword'
									name='confirmPassword'
									className='form-control col-12'
									placeholder='Confirm Password'
								/>
								<p>
									<p className='error-msg'>
										<ErrorMessage name='confirmPassword' />
									</p>
								</p>
							</div>

							<button
								className='btn login-submit'
								type='submit'
								disabled={loadingbtn}
							>
								{loadingbtn ? <PulseLoader color='#0e5472' /> : 'Set Password'}
							</button>
						</Form>
					)}
				</Formik>
			</div>
		</div>
	);
};

export default SetSubAdminPassword;
