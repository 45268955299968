import React, { useEffect, useState } from 'react';
import {
	Box,
	Paper,
	Typography,
	CircularProgress,
	Chip,
	Tooltip,
	Menu,
	MenuItem,
} from '@mui/material';
import { ReactComponent as SeatIcon } from '../../../assets/images/floor/seat.svg';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getFloorPlanListByIdApi } from '../../../api/adminApi';
import DashboardLayout from '../dashboardLayout';
import { adminSelector } from '../../../redux/slicers/adminSlice';

const ViewFloorPlan = () => {
	const { id } = useParams();
	const dispatch = useDispatch();
	const { floorPlanListsById } = useSelector(adminSelector);
	const [loading, setLoading] = useState(true);
	const [selectedFloorIndex, setSelectedFloorIndex] = useState(0);
	const [filter, setFilter] = useState('all');
	const [anchorEl, setAnchorEl] = useState(null);
	const handleOpenFilterMenu = (event) => setAnchorEl(event.currentTarget);
	const handleCloseFilterMenu = () => setAnchorEl(null);

	useEffect(() => {
		dispatch(getFloorPlanListByIdApi(id, setLoading));
	}, [dispatch, id]);

	if (loading) {
		return (
			<Box display='flex' justifyContent='center' py={4}>
				<CircularProgress />
			</Box>
		);
	}

	const floors = floorPlanListsById?.floors;
	if (!floors || floors.length === 0) {
		return <Typography>No Floor Data Found</Typography>;
	}
	const selectedFloor = floors[selectedFloorIndex];

	return (
		<DashboardLayout>
			<Box mt={4}>
				<Paper
					sx={{
						p: 2,
						mb: 3,
						borderRadius: 2,
						boxShadow: 1,
					}}
				>
					<Box textAlign='center'>
						<Typography variant='h5' fontWeight='bold'>
							{floorPlanListsById?.name}
						</Typography>
						{floorPlanListsById?.city && (
							<Typography variant='subtitle1' color='text.secondary'>
								({floorPlanListsById.city})
							</Typography>
						)}
					</Box>
					<Box
						mt={2}
						display='flex'
						justifyContent='center'
						alignItems='center'
						gap={2}
					>
						<Box display='flex' alignItems='center' gap={0.5}>
							<Box sx={{ width: 20, height: 4, backgroundColor: '#d32f2f' }} />
							<Typography variant='caption'>Booked</Typography>
						</Box>
						<Box display='flex' alignItems='center' gap={0.5}>
							<Box
								sx={{
									width: 20,
									height: 4,
									backgroundColor: '#fff',
									border: '1px solid #ccc',
								}}
							/>
							<Typography variant='caption'>Free</Typography>
						</Box>
						<Box display='flex' alignItems='center' gap={0.5}>
							<Box sx={{ width: 20, height: 4, backgroundColor: '#1976d2' }} />
							<Typography variant='caption'>Checked In</Typography>
						</Box>
					</Box>
				</Paper>

				<Box
					display='flex'
					alignItems='center'
					justifyContent='center'
					gap={1}
					mb={3}
					flexWrap='wrap'
				>
					<Box
						sx={{
							overflowX: 'auto',
							whiteSpace: 'nowrap',
							display: 'flex',
							gap: 1,
						}}
					>
						{floors.map((floor, index) => (
							<Chip
								key={floor._id}
								label={floor.floorName}
								onClick={() => setSelectedFloorIndex(index)}
								sx={{ flexShrink: 0, cursor: 'pointer' }}
								color={selectedFloorIndex === index ? 'primary' : 'default'}
							/>
						))}
					</Box>
					<Box
						sx={{
							width: '1px',
							height: '24px',
							backgroundColor: '#ccc',
							mx: 1,
						}}
					/>
					<Box
						onClick={handleOpenFilterMenu}
						sx={{
							cursor: 'pointer',
							display: 'flex',
							alignItems: 'center',
							gap: 0.5,
						}}
					>
						<Typography variant='body2'>
							{filter.charAt(0).toUpperCase() + filter.slice(1)}
						</Typography>
						<i className='fa-solid fa-chevron-down'></i>
					</Box>
					<Menu
						anchorEl={anchorEl}
						open={Boolean(anchorEl)}
						onClose={handleCloseFilterMenu}
					>
						<MenuItem
							onClick={() => {
								setFilter('all');
								handleCloseFilterMenu();
							}}
						>
							All
						</MenuItem>
						<MenuItem
							onClick={() => {
								setFilter('booked');
								handleCloseFilterMenu();
							}}
						>
							Booked
						</MenuItem>
						<MenuItem
							onClick={() => {
								setFilter('free');
								handleCloseFilterMenu();
							}}
						>
							Free
						</MenuItem>
						<MenuItem
							onClick={() => {
								setFilter('checkedin');
								handleCloseFilterMenu();
							}}
						>
							Checked In
						</MenuItem>
					</Menu>
				</Box>

				<Box style={{ maxWidth: 1010, overflowX: 'auto' }} mx='auto'>
					{selectedFloor.rows.map((row) => {
						const filteredSeats = row.seats.filter((seat) => {
							if (filter === 'all') return true;
							if (filter === 'booked')
								return !!seat.user && !seat.user?.isCheckedIn;
							if (filter === 'free') return !seat.user;
							if (filter === 'checkedin') return seat.user?.isCheckedIn;
							return true;
						});
						if (filteredSeats.length === 0) return null;
						return (
							<Box key={row._id} mb={3}>
								<Box sx={{ mb: 2 }}>
									<Box
										sx={{
											display: 'flex',
											justifyContent: 'center',
											gap: 1,
											minWidth: 'max-content',
											margin: '0 auto',
										}}
									>
										{filteredSeats.map((seat) => {
											const isBooked = !!seat.user;
											const isCheckedIn = seat.user?.isCheckedIn;
											let borderColor, backgroundColor, iconFill, tooltipTitle;

											if (isBooked) {
												if (isCheckedIn) {
													borderColor = '#1976d2';
													backgroundColor = '#e3f2fd';
													iconFill = '#1976d2';
													tooltipTitle = (
														<p
															style={{
																fontSize: '12px',
																textAlign: 'center',
																marginBottom: 0,
															}}
														>
															Checked in <br /> {seat.user?.name ?? ''} <br />
															{seat.user?.contactNumber ?? ''}
														</p>
													);
												} else {
													borderColor = '#d32f2f';
													backgroundColor = '#f5f5f5';
													iconFill = '#d32f2f';
													tooltipTitle = (
														<p
															style={{
																fontSize: '12px',
																textAlign: 'center',
																marginBottom: 0,
															}}
														>
															Assigned to <br /> {seat.user?.name ?? ''} <br />
															{seat.user?.contactNumber ?? ''}
														</p>
													);
												}
											} else {
												borderColor = '#ccc';
												backgroundColor = '#fff';
												iconFill = '#4caf50';
												tooltipTitle = 'Free';
											}
											return (
												<Tooltip key={seat._id} title={tooltipTitle} arrow>
													<Box flex='0 0 auto'>
														<Paper
															elevation={2}
															sx={{
																padding: 1,
																textAlign: 'center',
																border: `1px solid ${borderColor}`,
																backgroundColor: backgroundColor,
																width: 70,
																height: 90,
																display: 'flex',
																flexDirection: 'column',
																alignItems: 'center',
																justifyContent: 'center',
																cursor: 'default',
															}}
														>
															<SeatIcon
																fill={iconFill}
																width={40}
																height={40}
															/>
															<Typography variant='caption'>
																{selectedFloor.floorName
																	?.charAt(0)
																	.toUpperCase()}
																{seat?.number.toString().padStart(3, '0')}
															</Typography>
														</Paper>
													</Box>
												</Tooltip>
											);
										})}
									</Box>
								</Box>
							</Box>
						);
					})}
				</Box>
			</Box>
		</DashboardLayout>
	);
};

export default ViewFloorPlan;
