import * as Yup from "yup";

export const AdminLoginSchema = Yup.object().shape({
  email: Yup.string()
    .email("Invalid email address")
    .required("Email is required"),
  password: Yup.string()
  .required("Password is required")
  .min(8, "Password must be at least 8 characters")
  .max(20, "Password must be at most 20 characters")
  .matches(
    /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]+$/,
    "Password must contain at least one letter, one number and one special character"
  ),
});

export const AdminSignupSchema = Yup.object().shape({
  firstname: Yup.string().required("FirstName is required"),
  lastname: Yup.string().required("LastName is required"),
  email: Yup.string()
    .email("Invalid email address")
    .required("Email is required"),
  password: Yup.string()
    .required("Password is required")
    .min(8, "Password must be at least 8 characters")
    .max(20, "Password must be at most 20 characters")
    .matches(
      /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]+$/,
      "Password must contain at least one letter, one number and one special character"
    ),
});

export const resetPasswordSchema = Yup.object().shape({
  currentPassword: Yup.string().required("Current Password is required"),
  newPassword: Yup.string()
    .required("Password is required")
    .min(8, "Password must be at least 8 characters")
    .max(20, "Password must be at most 20 characters")
    .matches(
      /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]+$/,
      "Password must contain at least one letter, one number and one special character"
    ),
  confirmNewPassword: Yup.string()
    .oneOf([Yup.ref("newPassword"), null], "Passwords must match")
    .required("Confirm New Password is required"),
});

export const setPasswordSchema = Yup.object().shape({
  password: Yup.string()
    .required("Password is required")
    .min(8, "Password must be at least 8 characters")
    .max(20, "Password must be at most 20 characters")
    .matches(
      /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]+$/,
      "Password must contain at least one letter, one number and one special character"
    ),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref("password"), null], "Passwords must match")
    .required("Confirm Password is required"),
});

export const OTPSchema = Yup.object().shape({
  otp: Yup.string()
    .required("OTP is required")
    .length(6, "OTP must be exactly 6 digits"),
});

export const userSigninSchema = Yup.object().shape({
  name: Yup.string().required("Name is required"),
  age: Yup.number().required("Age is required").positive().integer(),
  city: Yup.string().required("City is required"),
  state: Yup.string().required("State is required"),
  country: Yup.string().required("Country is required"),
  whatsappNumber: Yup.string().required("Whatsapp Number is required"),
  contactNumber: Yup.string().required("Contact Number is required"),
  email: Yup.string()
    .email("Invalid email address")
    .required("Email is required"),
  // officialIdProof: Yup.string().required("Proof is required"),
  // photo: Yup.string().required("Photo is required"),
  nafilDays: Yup.number().required("Nafil Days is required").positive().integer(),
  education: Yup.string().required("Education is required"),
  occupation: Yup.string().required("Occupation is required"),
  kisseBaitHain: Yup.string().required("This Field is required"),
  howLongBaiyath: Yup.string().required("This Field is required"),
  etekaafMaqsad: Yup.string().required("This Field is required"),
  notes: Yup.string().required("Notes is required"),
});

export const addLiveBroadCast = Yup.object().shape({
  title: Yup.string().required("Title is required"),
  discription: Yup.string().required("Discription is required"),
  url: Yup.string().required("Youtube Link is required"),
  playlist: Yup.string().required("PlayList is required"),
  isActive: Yup.bool().oneOf([true], 'Field must be checked'),
});