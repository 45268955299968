import React, { useEffect, useState } from 'react';
import moment from 'moment';
import './style.css';
import '../user-list/user-list.css';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { getServerErrorsListApi } from '../../../api/adminApi';
import { adminSelector } from '../../../redux/slicers/adminSlice';

import DashboardLayout from '../dashboardLayout';
import { PulseLoader } from 'react-spinners';
import Pagination from '../../../components/common/Pagination';
import { useDebouncedEffect } from '../../../helpers/hooks';
import { DEBOUNCE_DELAY } from '../../../config/constants/projectConstant';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

const ErrorLogsList = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const { serverErrorsList = [] } = useSelector(adminSelector);

	const [searchQuery, setSearchQuery] = useState('');
	const [loading, setLoading] = useState(false);
	const [listSize, setListSize] = useState(0);
	const [pageNumbers, setPageNumbers] = useState([]);
	const [limit, setLimit] = useState({ start: 1, end: 3 });
	const [paginationSize, setPaginationSize] = useState(25);
	const [page, setPage] = useState(1);

	const [viewOpen, setViewOpen] = useState(false);
	const [selectedError, setSelectedError] = useState(null);

	const getErrorLogsList = (search) => {
		dispatch(
			getServerErrorsListApi(
				paginationSize,
				setListSize,
				page,
				setLoading,
				search
			)
		);
	};

	useDebouncedEffect(
		() => {
			setPage(1);
			getErrorLogsList(searchQuery);
		},
		DEBOUNCE_DELAY,
		[searchQuery]
	);

	useEffect(() => {
		getErrorLogsList(searchQuery);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [page, paginationSize]);

	useEffect(() => {
		const pageCount =
			parseInt(listSize / paginationSize) +
			(listSize % paginationSize > 0 ? 1 : 0);
		let pages = [];
		for (let i = 1; i <= pageCount; i++) {
			pages.push(i);
		}
		setPageNumbers(pages);
	}, [listSize, paginationSize]);

	const handlePaginationChange = (event) => {
		setPaginationSize(parseInt(event.target.value));
		setPage(1);
	};

	const handleSearchChange = (e) => {
		setSearchQuery(e.target.value);
	};

	const handleViewOpen = (errorLog) => {
		setSelectedError(errorLog);
		setViewOpen(true);
	};

	const handleViewClose = () => {
		setViewOpen(false);
		setSelectedError(null);
	};

	return (
		<DashboardLayout>
			<div className=''>
				<div className='title'>
					<h1>Error Logs</h1>
					<div>
						<input
							className='search'
							type='text'
							value={searchQuery}
							onChange={handleSearchChange}
							placeholder='Search error logs...'
						/>
					</div>
				</div>

				<div className='flex table-lists'>
					<div className='flex-large'>
						<table className='table' style={{ border: '1px solid #e9ecef' }}>
							<thead>
								<tr>
									<th style={{ width: '15%' }}>Date</th>
									<th style={{ width: '25%' }}>Message</th>
									<th style={{ width: '30%' }}>Stack</th>
									<th style={{ width: '5%' }}></th>
								</tr>
							</thead>
							<tbody>
								{loading ? (
									<tr className='loading-tr'>
										<td colSpan={5}>
											<div className='loading-table-wrapper'>
												<PulseLoader
													color='#0e5472'
													style={{ display: 'flex', justifyContent: 'center' }}
												/>
											</div>
										</td>
									</tr>
								) : serverErrorsList.length > 0 ? (
									serverErrorsList.map((errorLog) => (
										<tr key={errorLog._id}>
											<td>
												{moment(errorLog.createdAt).format('DD MMM YYYY')}{' '}
												<br />
												{moment(errorLog.createdAt).format('hh:mm A')}
											</td>
											<td className='truncated-message'>{errorLog.message}</td>
											<td className='truncated-stack'>{errorLog.stack}</td>
											<td>
												<div className='button-div'>
													<button
														className='btn btn-primary'
														onClick={() => handleViewOpen(errorLog)}
													>
														View
													</button>
												</div>
											</td>
										</tr>
									))
								) : (
									<tr>
										<td colSpan={5}>
											<h1 style={{ textAlign: 'center' }}>
												No error logs found
											</h1>
										</td>
									</tr>
								)}
							</tbody>
						</table>

						<div className='pagination'>
							<div>
								<h1 className='paginationSize'>Total Errors: {listSize}</h1>
							</div>
							<div className='paginationSize'>
								<label htmlFor='pagination-size'>Select Page Size:&nbsp;</label>
								<select
									id='pagination-size'
									value={paginationSize}
									onChange={handlePaginationChange}
								>
									<option value='15'>15</option>
									<option value='25'>25</option>
									<option value='50'>50</option>
									<option value='75'>75</option>
									<option value='100'>100</option>
								</select>
							</div>
							<div className='pagination'>
								<Pagination
									setPage={setPage}
									limit={limit}
									pageNumbers={pageNumbers}
									setLimit={setLimit}
									page={page}
								/>
							</div>
						</div>
					</div>
				</div>
			</div>

			<Dialog
				className='dialog'
				fullWidth={true}
				maxWidth={'md'}
				open={viewOpen}
				onClose={handleViewClose}
				aria-labelledby='view-error-dialog-title'
			>
				<DialogTitle id='view-error-dialog-title'>Error Details</DialogTitle>
				<DialogContent>
					<DialogContentText>
						{selectedError ? (
							<table className='table'>
								<tbody>
									<tr>
										<td style={{ width: '30%' }}>Date:</td>
										<td>
											{moment(selectedError.createdAt).format(
												'DD MMM YYYY hh:mm A'
											)}
										</td>
									</tr>
									<tr>
										<td>Message:</td>
										<td>{selectedError.message}</td>
									</tr>
									<tr>
										<td>Origin:</td>
										<td>{selectedError.origin}</td>
									</tr>
									<tr>
										<td>Stack Trace:</td>
										<td className='stack-trace'>
											<pre>{selectedError.stack}</pre>
										</td>
									</tr>
									{selectedError.meta && (
										<tr>
											<td>Metadata:</td>
											<td>
												<table className='table table-bordered'>
													<tbody>
														{Object.entries(selectedError.meta).map(
															([key, value]) => (
																<tr key={key}>
																	<td style={{ fontWeight: 'bold' }}>{key}</td>
																	<td>{JSON.stringify(value, null, 2)}</td>
																</tr>
															)
														)}
													</tbody>
												</table>
											</td>
										</tr>
									)}
								</tbody>
							</table>
						) : (
							<p>No error data</p>
						)}
					</DialogContentText>
				</DialogContent>
				<DialogActions className='dialog-actions'>
					<Button variant='contained' onClick={handleViewClose}>
						CLOSE
					</Button>
				</DialogActions>
			</Dialog>
		</DashboardLayout>
	);
};

export default ErrorLogsList;
