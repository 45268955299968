import { createSlice } from '@reduxjs/toolkit';

const adminSlice = createSlice({
	name: 'admin',
	initialState: {
		liveBroadCasts: [],
		liveBroadCastCategories: [],
		liveBroadCastSubCategories: [],
		messageLists: [],
		salaqeenLists: [],
		faqLists: [],
		faqCategoryLists: [],
		singleLiveBroadCast: {},
		userLists: [],
		subAdminsList: [],
		dashbooardList: [],
		eventLists: [],
		bookLists: [],
		floorPlanLists: [],
		floorPlanListsById: [],
		logsList: [],
	},
	reducers: {
		getLiveBroadCasts: (state, { payload }) => {
			state.liveBroadCasts = payload;
		},
		getLiveBroadCastCategories: (state, { payload }) => {
			state.liveBroadCastCategories = payload;
		},
		getLiveBroadCastSubCategories: (state, { payload }) => {
			state.liveBroadCastSubCategories = payload;
		},
		getSingleLiveBroadCast: (state, { payload }) => {
			state.singleLiveBroadCast = payload;
		},
		getSubAdminsList: (state, { payload }) => {
			state.subAdminsList = payload;
		},
		getUserLists: (state, { payload }) => {
			state.userLists = payload;
		},
		getDashboardLists: (state, { payload }) => {
			state.dashbooardList = payload;
		},
		getSalaqeenLists: (state, { payload }) => {
			state.salaqeenLists = payload;
		},
		getFaqLists: (state, { payload }) => {
			state.faqLists = payload;
		},
		getFaqCategoryLists: (state, { payload }) => {
			state.faqCategoryLists = payload;
		},
		getEventLists: (state, { payload }) => {
			state.eventLists = payload;
		},
		getBookLists: (state, { payload }) => {
			state.bookLists = payload;
		},
		getMessageLists: (state, { payload }) => {
			state.messageLists = payload;
		},
		getFloorPlanLists: (state, { payload }) => {
			state.floorPlanLists = payload;
		},
		getFloorPlanListsById: (state, { payload }) => {
			state.floorPlanListsById = payload;
		},
		getLogsList: (state, { payload }) => {
			state.logsList = payload;
		},
		getServerErrorsList: (state, { payload }) => {
			state.serverErrorsList = payload;
		},
		updateUserItikafType: (state, action) => {
			const { userId, newType } = action.payload;
			state.userLists = state.userLists.map((user) =>
				user._id === userId ? { ...user, typeOfIthikaaf: newType } : user
			);
		},
	},
});

export const {
	getLiveBroadCasts,
	getLiveBroadCastCategories,
	getLiveBroadCastSubCategories,
	getSingleLiveBroadCast,
	getUserLists,
	getSubAdminsList,
	getDashboardLists,
	getSalaqeenLists,
	getFaqLists,
	getFaqCategoryLists,
	getEventLists,
	getBookLists,
	getMessageLists,
	getFloorPlanLists,
	getFloorPlanListsById,
	getLogsList,
	getServerErrorsList,
	updateUserItikafType,
} = adminSlice.actions;

export const adminSelector = (state) => state.admin;
const adminReducer = adminSlice.reducer;
export default adminReducer;
