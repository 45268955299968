import api from '../helpers/apiHelper.js';
import {
	devConsoleLog,
	errorToast,
	// getUserData,
	// navigate,
	// redirectRoute,
	sessionDestroy,
	storeLocal,
	successToast,
} from '../helpers/projectHelper.js';

import {
	loginLoading,
	sessionSuccess,
	loginWebsite,
} from '../redux/slicers/sessionSlice.js';

import {
	LOGIN_API_ROOT,
	LOGIN_API,
	SIGNUP,
	ITIKAAF_REGISTRATION_API,
	FILE_UPLOAD_API,
	SALAQEEN_API,
	FILE_DOWNLOAD_API,
	EVENTS_API,
	RECENT_EVENTS_API,
	UPCOMING_EVENTS_API,
	CONTACT_US_API,
	LIBRARY_API,
	LIVE_AUDIO_API,
	PROGRAM_REGISTRATION_FORM_API,
} from '../config/constants/apiConstant.js';

import { DASHBOARD } from '../config/constants/routePathConstants.js';

import { ROLE_NAV, USER_DATA } from '../config/constants/projectConstant.js';
import {
	getAllEventList,
	getListOfBooks,
	getLiveBroadcastPlaylist,
	getLiveBroadcastSubPlaylist,
	getRecentEventList,
	getSignUpPopUP,
	getUpComingEventList,
	getAudioLists,
	getSingleAudio,
	getAllAudioLists,
	getSubPlaylistAudio,
} from '../redux/slicers/userSlice.js';
import Swal from 'sweetalert2';
import { getLocal } from './../helpers/projectHelper';
import { LIVE_BROADCAST_API } from './../config/constants/apiConstant';

export const loginApi = (formData, navigate, setLoadingbtn) => (dispatch) => {
	api()
		.root(LOGIN_API_ROOT)
		.post(LOGIN_API)
		.data(formData)
		.success((a) => {
			// devConsoleLog("loginApi", a);
			const { message: msg = '' } = a;
			if (a.status === 200) {
				const { result = {} } = a;
				const { access = null } = result;
				setLoadingbtn(false);
				storeLocal(access);
				storeLocal(result, USER_DATA);
				dispatch(sessionSuccess(result));
				dispatch(loginWebsite(true));
				successToast(msg);
				navigate(DASHBOARD);
			} else {
				errorToast(msg);
				setLoadingbtn(false);
			}
		})
		.error((e) => {
			const { message: msg = '' } = e;
			setLoadingbtn(false);
			errorToast(msg);
		})
		.send(() => {
			dispatch(loginLoading(false));
		});
};

export const logoutApi = () => () => {
	sessionDestroy();
};

export const signUpApi =
	(formData, setPhone, setBtnDisabled, setReCapValue, setLoadingbtn) =>
	(dispatch) => {
		api()
			.root(LOGIN_API_ROOT)
			.post(SIGNUP)
			.data({
				phone_number: formData.phone,
			})
			.success((a) => {
				if (a.status === 200) {
					setLoadingbtn(false);
					successToast(a.message);
					dispatch(getSignUpPopUP(false));
					setPhone();
					setBtnDisabled(false);
					setReCapValue('');
				}
				if (
					a.status === 400 ||
					a.status === 401 ||
					a.status === 403 ||
					a.status === 404
				) {
					errorToast(a.message);
					setLoadingbtn(false);
				}
			})
			.error((e) => {
				const { message: msg = '' } = e;
				errorToast(msg);
				setLoadingbtn(false);
			})
			.send(() => {});
	};

export const itikaafRegistrationApi =
	(formData, resetForm, navigate, setLoadingbtn) => (dispatch) => {
		api()
			.root(LOGIN_API_ROOT)
			.post(ITIKAAF_REGISTRATION_API)
			.data(formData)
			.success((a) => {
				const { message: msg = '' } = a;
				setLoadingbtn(false);
				if (a.statusCode === 201) {
					resetForm();
					Swal.fire({
						title: 'Thank you!',
						text: msg,
						icon: 'success',
						allowOutsideClick: false,
						preConfirm: () => {
							window.location.reload();
						},
					});
				} else {
					Swal.fire({
						title: 'Validation Missing!',
						text: msg,
						icon: 'warning',
						allowOutsideClick: false,
					});
				}
			})
			.error((e) => {
				const { message: msg = '' } = e;
				setLoadingbtn(false);
				Swal.fire('Error!', msg, 'error');
			})
			.send(() => {
				dispatch(setLoadingbtn(false));
			});
	};

export const fileUploadApi =
	(formData, setImageKey, setLoading, setIsUploading) => (dispatch) => {
		api()
			.root(LOGIN_API_ROOT)
			.put(FILE_UPLOAD_API)
			.data(formData)
			.success((a) => {
				setLoading && setLoading(false);
				setIsUploading && setIsUploading(false);
				const { message: msg = '' } = a;
				if (a.statusCode === 200) {
					// successToast(msg);
					setImageKey(a?.data?.key);
				}
			})
			.error((e) => {
				const { message: msg = '' } = e;
				errorToast(msg);
				setLoading && setLoading(false);
			})
			.upload(() => {
				setLoading && setLoading(false);
			});
	};
export const largeFileUploadApi =
	(formData, setImageKey, setLoading, updateUploadProgress) => (dispatch) => {
		api()
			.root(LOGIN_API_ROOT)
			.put(FILE_UPLOAD_API)
			.data(formData)
			.onUploadProgress(updateUploadProgress) // Pass the updateUploadProgress callback here
			// .upload()
			.success((a) => {
				setLoading && setLoading(false);
				const { message: msg = '' } = a;
				if (a.statusCode === 200) {
					// successToast(msg);
					setImageKey(a?.data?.key);
				}
			})
			.error((e) => {
				const { message: msg = '' } = e;
				errorToast(msg);
				setLoading && setLoading(false);
			})
			.upload(() => {
				setLoading && setLoading(false);
			});
	};

export const fileDownloadApi = (key) => {
	return `${LOGIN_API_ROOT}${FILE_DOWNLOAD_API}${key}`;
};

export const salaqeenRegistrationApi =
	(formData, resetForm, navigate, setLoading) => (dispatch) => {
		api()
			.root(LOGIN_API_ROOT)
			.post(SALAQEEN_API)
			.data(formData)
			.success((a) => {
				const { message: msg = '' } = a;
				setLoading(false);
				if (a.statusCode === 201) {
					resetForm({ values: '' });
					Swal.fire('Thank you!', msg, 'success');
				} else {
					Swal.fire('Validation Missing!', msg, 'warning');
				}
			})
			.error((e) => {
				const { message: msg = '' } = e;
				if (msg == 'User already register') {
					Swal.fire('Already registered on this number!', '', 'error');
				} else {
					Swal.fire('Error!', msg, 'error');
				}
				setLoading(false);
			})
			.send(() => {
				dispatch(loginLoading(false));
				setLoading(false);
			});
	};

//start ---------event ------

export const getAllEventListsApi =
	(SIZE, setListsize, page, setLoading) => (dispatch) => {
		api()
			.root(LOGIN_API_ROOT)
			.get(`${EVENTS_API}?page=${page}&limit=${SIZE}`)
			.success((a) => {
				setLoading && setLoading(false);
				if (a.statusCode === 200) {
					setListsize(a?.data?.totalCounts);
					dispatch(getAllEventList(a?.data?.data));
				}
				if (
					a.statusCode === 400 ||
					a.statusCode === 401 ||
					a.statusCode === 403 ||
					a.statusCode === 404
				) {
					// errorToast(a.message);
				}
			})
			.error((e) => {
				const { message: msg = '' } = e;
				// errorToast(msg);
				dispatch(getAllEventList([]));
				setLoading && setLoading(false);
			})
			.send(() => {});
	};

export const getRecentEventListsApi = (setLoading) => (dispatch) => {
	api()
		.root(LOGIN_API_ROOT)
		.get(RECENT_EVENTS_API)
		.success((a) => {
			setLoading && setLoading(false);
			if (a.statusCode === 200) {
				dispatch(getRecentEventList(a?.data?.data));
			}
			if (
				a.statusCode === 400 ||
				a.statusCode === 401 ||
				a.statusCode === 403 ||
				a.statusCode === 404
			) {
				// errorToast(a.message);
			}
		})
		.error((e) => {
			const { message: msg = '' } = e;
			// errorToast(msg);
			dispatch(getRecentEventList([]));
			setLoading && setLoading(false);
		})
		.send(() => {});
};

export const getUpcomingApi = (setLoading) => (dispatch) => {
	api()
		.root(LOGIN_API_ROOT)
		.get(UPCOMING_EVENTS_API)
		.success((a) => {
			setLoading && setLoading(false);
			if (a.statusCode === 200) {
				dispatch(getUpComingEventList(a?.data?.data));
			}
			if (
				a.statusCode === 400 ||
				a.statusCode === 401 ||
				a.statusCode === 403 ||
				a.statusCode === 404
			) {
				// errorToast(a.message);
			}
		})
		.error((e) => {
			const { message: msg = '' } = e;
			// errorToast(msg);
			dispatch(getUpComingEventList([]));
			setLoading && setLoading(false);
		})
		.send(() => {});
};

// live broadcast api

export const getLiveBroadcastPlaylistApi =
	(playlist, setLoading, SIZE, setListsize, page) => (dispatch) => {
		const hasPageUrl =
			page && playlist != ''
				? `${LIVE_BROADCAST_API}${playlist}&page=${page}&limit=${SIZE}`
				: `${LIVE_BROADCAST_API}${playlist}`;
		api()
			.root(LOGIN_API_ROOT)
			.get(hasPageUrl)
			.success((a) => {
				setLoading && setLoading(false);
				if (a.statusCode === 200) {
					setListsize && setListsize(a?.data?.totalCounts);
					dispatch(getLiveBroadcastPlaylist(a?.data?.data));
				}
				if (
					a.statusCode === 400 ||
					a.statusCode === 401 ||
					a.statusCode === 403 ||
					a.statusCode === 404
				) {
					// errorToast(a.message);
				}
			})
			.error((e) => {
				const { message: msg = '' } = e;
				// errorToast(msg);
				dispatch(getLiveBroadcastPlaylist([]));
				setLoading && setLoading(false);
			})
			.send(() => {});
	};

export const getLiveBroadcastSubPlaylistApi =
	(playlist, setLoading, SIZE, setListsize, page) => (dispatch) => {
		const hasPageUrl =
			page && playlist != ''
				? `${LIVE_BROADCAST_API}${playlist}&page=${page}&limit=${SIZE}`
				: `${LIVE_BROADCAST_API}${playlist}`;
		api()
			.root(LOGIN_API_ROOT)
			.get(hasPageUrl)
			.success((a) => {
				setLoading && setLoading(false);
				if (a.statusCode === 200) {
					setListsize && setListsize(a?.data?.totalCounts);
					dispatch(getLiveBroadcastSubPlaylist(a?.data?.data));
				}
				if (
					a.statusCode === 400 ||
					a.statusCode === 401 ||
					a.statusCode === 403 ||
					a.statusCode === 404
				) {
					// errorToast(a.message);
					setLoading && setLoading(false);
				}
			})
			.error((e) => {
				const { message: msg = '' } = e;
				// errorToast(msg);
				dispatch(getLiveBroadcastSubPlaylist([]));
				setLoading && setLoading(false);
			})
			.send(() => {});
	};

//contact us----------

export const postContactApi =
	(formData, resetForm, setLoading) => (dispatch) => {
		api()
			.root(LOGIN_API_ROOT)
			.post(CONTACT_US_API)
			.data(formData)
			.success((a) => {
				const { message: msg = '' } = a;
				setLoading(false);
				if (a.statusCode === 201) {
					resetForm();
					Swal.fire('Thank you!', msg, 'success');
				} else {
					Swal.fire('Validation Missing!', msg, 'warning');
				}
			})
			.error((e) => {
				const { message: msg = '' } = e;
				setLoading(false);
			})
			.send(() => {});
	};

export const getListsOfBookApi =
	(SIZE, setListsize, page, setLoading) => (dispatch) => {
		api()
			.root(LOGIN_API_ROOT)
			.get(`${LIBRARY_API}?page=${page}&limit=${SIZE}`)
			.success((a) => {
				setLoading && setLoading(false);
				if (a.statusCode === 200) {
					setListsize(a?.data?.totalCounts);
					dispatch(getListOfBooks(a?.data?.data));
				}
				if (
					a.statusCode === 400 ||
					a.statusCode === 401 ||
					a.statusCode === 403 ||
					a.statusCode === 404
				) {
					// errorToast(a.message);
				}
			})
			.error((e) => {
				const { message: msg = '' } = e;
				// errorToast(msg);
				dispatch(getListOfBooks([]));
				setLoading && setLoading(false);
			})
			.send(() => {});
	};

//audio list

export const getAudioApi =
	(playlist, SIZE, setListsize, page, setLoading) => (dispatch) => {
		const audioUrl = playlist
			? `${LIVE_AUDIO_API}${playlist}&page=${page}&limit=${SIZE}`
			: `${LIVE_AUDIO_API}?page=${page}&limit=${SIZE}`;
		api()
			.root(LOGIN_API_ROOT)
			.get(audioUrl)
			.success((a) => {
				setLoading && setLoading(false);
				if (a.statusCode === 200) {
					setListsize(a?.data?.totalCounts);
					dispatch(getAudioLists(a?.data?.data));
				}
				if (
					a.statusCode === 400 ||
					a.statusCode === 401 ||
					a.statusCode === 403 ||
					a.statusCode === 404
				) {
					// errorToast(a.message);
				}
			})
			.error((e) => {
				const { message: msg = '' } = e;
				// errorToast(msg);
				dispatch(getAudioLists([]));
				setLoading && setLoading(false);
			})
			.send(() => {});
	};

export const getAudioSubPlaylistApi =
	(playlist, SIZE, setListsize, page, setLoading) => (dispatch) => {
		api()
			.root(LOGIN_API_ROOT)
			.get(`${LIVE_AUDIO_API}${playlist}&page=${page}&limit=${SIZE}`)
			.success((a) => {
				setLoading && setLoading(false);
				if (a.statusCode === 200) {
					dispatch(getSubPlaylistAudio(a?.data?.data));
				}
				if (
					a.statusCode === 400 ||
					a.statusCode === 401 ||
					a.statusCode === 403 ||
					a.statusCode === 404
				) {
					// errorToast(a.message);
					setLoading && setLoading(false);
				}
			})
			.error((e) => {
				const { message: msg = '' } = e;
				// errorToast(msg);
				dispatch(getSubPlaylistAudio([]));
				setLoading && setLoading(false);
			})
			.send(() => {});
	};

export const getAllAudioApi = (playlist, setLoading) => (dispatch) => {
	api()
		.root(LOGIN_API_ROOT)
		.get(`${LIVE_AUDIO_API}${playlist}`)
		.success((a) => {
			setLoading && setLoading(false);
			if (a.statusCode === 200) {
				dispatch(getAllAudioLists(a?.data?.data));
			}
			if (
				a.statusCode === 400 ||
				a.statusCode === 401 ||
				a.statusCode === 403 ||
				a.statusCode === 404
			) {
				// errorToast(a.message);
			}
		})
		.error((e) => {
			const { message: msg = '' } = e;
			// errorToast(msg);
			dispatch(getAllAudioLists([]));
			setLoading && setLoading(false);
		})
		.send(() => {});
};
export const getSingleAudioApi = (setLoading) => (dispatch) => {
	api()
		.root(LOGIN_API_ROOT)
		.get(`${LIVE_AUDIO_API}`)
		.success((a) => {
			setLoading && setLoading(false);
			if (a.statusCode === 200) {
				dispatch(getSingleAudio(a?.data?.data?.[0]));
			}
			if (
				a.statusCode === 400 ||
				a.statusCode === 401 ||
				a.statusCode === 403 ||
				a.statusCode === 404
			) {
				// errorToast(a.message);
			}
		})
		.error((e) => {
			const { message: msg = '' } = e;
			// errorToast(msg);
			dispatch(getSingleAudio([]));
			setLoading && setLoading(false);
		})
		.send(() => {});
};

export const postRegistrationApi =
	(formData, resetForm, setLoading) => (dispatch) => {
		api()
			.root(LOGIN_API_ROOT)
			.post(PROGRAM_REGISTRATION_FORM_API)
			.data(formData)
			.success((a) => {
				const { message: msg = '' } = a;
				setLoading(false);
				if (a.statusCode === 200) {
					resetForm();
					Swal.fire('Thank you!', msg, 'success');
					return;
				}
			})
			.error((e) => {
				const { message: msg = '' } = e;
				Swal.fire('Error!', msg, 'error');
				setLoading(false);
			})
			.send(() => {
				setLoading(false);
			});
	};

export const userItikaafConfirmationAccepted =
	(formData, navigate, setLoading, isAdmin, getFloorPlanList) => (dispatch) => {
		api()
			.root(LOGIN_API_ROOT)
			.put(`/athikaaf-confirmation-accepted/` + formData.id)
			.data(formData)
			.success((a) => {
				setLoading && setLoading(false);
				if (a.statusCode === 200) {
					if (!isAdmin) {
						Swal.fire('Thank you for letting know', '', 'success');
						navigate('/');
					} else {
						getFloorPlanList();
						Swal.fire('Thank you for letting know', '', 'success');
					}
				}
				if (
					a.statusCode === 400 ||
					a.statusCode === 401 ||
					a.statusCode === 403 ||
					a.statusCode === 404
				) {
					Swal.fire('Something wnt wrong, Contact with Admin', '', 'error');
				}
			})
			.error((e) => {
				const { message: msg = '' } = e;
				setLoading && setLoading(false);
				Swal.fire(
					msg || 'Something wnt wrong, Contact with Admin',
					'',
					'error'
				);
			})
			.send(() => {});
	};

export const userItikaafConfirmationRejected =
	(formData, navigate, setLoading, isAdmin, getFloorPlanList) => (dispatch) => {
		api()
			.root(LOGIN_API_ROOT)
			.put(`/athikaaf-confirmation-rejected/` + formData.id)
			.data(formData)
			.success((a) => {
				setLoading && setLoading(false);
				if (a.statusCode === 200) {
					if (!isAdmin) {
						Swal.fire('Thank you for letting know', '', 'success');
						navigate('/');
					} else {
						getFloorPlanList();
						Swal.fire('Thank you for letting know', '', 'success');
					}
				}
				if (
					a.statusCode === 400 ||
					a.statusCode === 401 ||
					a.statusCode === 403 ||
					a.statusCode === 404
				) {
					Swal.fire('Something wnt wrong, Contact with Admin', '', 'error');
				}
			})
			.error((e) => {
				const { message: msg = '' } = e;
				setLoading && setLoading(false);
				Swal.fire(
					msg || 'Something wnt wrong, Contact with Admin',
					'',
					'error'
				);
			})
			.send(() => {});
	};

export const getUserDetailsApi =
	(userId, setUser, setLoading) => (dispatch) => {
		setLoading(true);
		const url = `${ITIKAAF_REGISTRATION_API}user/${userId}`;

		api()
			.root(LOGIN_API_ROOT)
			.get(url)
			.success((a) => {
				if (a.statusCode === 200) {
					setUser(a?.data);
				}
				if (
					a.statusCode === 400 ||
					a.statusCode === 401 ||
					a.statusCode === 403 ||
					a.statusCode === 404
				) {
					setUser(null);
				}
			})
			.error((e) => {
				const { message: msg = '' } = e;
				Swal.fire(msg || 'something went wrong', '', 'error');
				setUser(null);
				setLoading(false);
			})
			.send(() => {
				setLoading(false);
			});
	};
