import React, { useEffect, useState } from 'react';
import './user-list.css';
import DashboardLayout from '../dashboardLayout';
import {
	acceptAndRegisterItakaafUserApi,
	bookSelectedSeat,
	getCityBasedList,
	getMasjidListApi,
	getUserListApi,
} from '../../../api/adminApi';
import { useDispatch, useSelector } from 'react-redux';
import { adminSelector } from '../../../redux/slicers/adminSlice';
import { PulseLoader } from 'react-spinners';
import Pagination from '../../../components/common/Pagination';
import { useNavigate } from 'react-router';
import SelectInput from '../../../components/common/SelectInput';

const ItikaafConfirmedList = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { userLists } = useSelector(adminSelector);
	const [editUser, setEditUser] = useState({});
	const [loadingBtn, setLoadingBtn] = useState(false);
	const [loading, setLoading] = useState(true);
	const [loadingApi, setLoadingApi] = useState(true);
	const [id, setId] = useState('');
	const [status, setStatus] = useState(true);
	const [listSize, setListsize] = useState(1);
	const [pageNumbers, setPageNumbers] = useState([]);
	const [limit, setLimit] = useState({ start: 1, end: 3 });
	const [page, setPage] = useState(1);
	const SIZE = 25;
	const { floorPlanLists = [] } = useSelector(adminSelector);
	const [masjidValue, setMasjidValue] = useState(null);
	const [masjidData, setMasjidData] = useState([]);
	const masjidId = masjidValue
		? masjidValue
		: masjidData?.length > 0
		? masjidData[0]._id
		: null;

	const handleChange = (event) => {
		setMasjidValue(event.target.value);
	};

	const getFloorPlanList = () => {
		dispatch(getMasjidListApi(setLoadingApi, setMasjidData));
	};

	useEffect(() => {
		getFloorPlanList();
	}, []);

	useEffect(() => {
		let value = parseInt(listSize / SIZE) + (listSize % SIZE > 0 ? 1 : 0);
		let pageNumbers = [];
		for (let i = 1; i <= value; i++) {
			pageNumbers.push(i);
		}
		setPageNumbers(pageNumbers);
	}, [listSize]);

	useEffect(() => {
		if (masjidId) getUserList();
	}, [page, masjidId]);

	const getUserList = () => {
		dispatch(getCityBasedList(masjidId, SIZE, setListsize, page, setLoading));
	};

	const editRow = (user) => {
		setEditUser(user);
	};
	const handleAcceptReject = (status) => {
		setLoadingBtn(true);
		dispatch(
			acceptAndRegisterItakaafUserApi(status, id, getUserList, setLoadingBtn)
		);
	};

	return (
		<>
			<DashboardLayout>
				{loadingApi ? (
					<div className='loading-table-wrapper'>
						<PulseLoader
							color='#0e5472'
							style={{ display: 'flex', justifyContent: 'center' }}
						/>
					</div>
				) : (
					<div className='container video-upload p-0 mt-md-3'>
						<div className='d-flex flex-column flex-md-row justify-content-between align-items-md-center mb-3 bg-white p-3 rounded-2'>
							<h1 className='responsive-heading'>
								I'tikaf Confirmed City List
							</h1>
							<div
								// className="bg-light rounded rounded-pill shadow-sm mb-4 list-search-bar"
								style={{ padding: '0 12px' }}
							>
								<div className='itakaaf-input'>
									{/* <label>Select Masjid:</label>
                <select value={masjidValue} onChange={handleChange}>
                  <option value="" disabled>
                    Select an option
                  </option>
                  {masjidData?.map((option, index) => (
                    <option key={index} value={option?._id}>
                      {option?.name}
                    </option>
                  ))}
                </select> */}
									<SelectInput
										label=''
										name='masjidValue'
										options={masjidData?.map((item) => {
											return { value: item?._id, label: item?.name };
										})}
										handleChange={(e) => {
											handleChange(e);
										}}
										value={masjidValue || ''}
										error={''}
										placeHolder='Select Masjid'
									/>
								</div>

								{/* <div class="input-group">
              <input
                type="search"
                placeholder="What're you searching.."
                aria-describedby="button-addon1"
                class="form-control border-0 bg-light"
                style={{ padding: "0 12px" }}
              />
              <div class="input-group-append">
                <button
                  id="button-addon1"
                  type="submit"
                  class="btn btn-link text-primary"
                  style={{ padding: "0 12px" }}
                >
                  <i class="fa fa-search"></i>
                </button>
              </div>
            </div>
          </div> */}
								{/* <div class="dropdown">
            <button
              class="btn dropdown-toggle"
              type="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
              style={{ backgroundColor: "#16aaff", color: "#fff" }}
            >
              Filter By
            </button>
            <ul class="dropdown-menu">
              <li>
                <a class="dropdown-item" href="#">
                  Action
                </a>
              </li>
              <li>
                <a class="dropdown-item" href="#">
                  Another action
                </a>
              </li>
              <li>
                <a class="dropdown-item" href="#">
                  Something else here
                </a>
              </li>
            </ul>*/}
							</div>
						</div>
						<div className='flex table-lists'>
							<div className='flex-large'>
								<table class='table' style={{ border: '1px solid #e9ecef' }}>
									<thead>
										<tr>
											<th style={{ width: '30%' }}>City</th>
											<th style={{ width: '15%' }}>Count</th>
											{/* <th style={{ width: "30%" }}>Email</th> */}
											{/* <th style={{ width: "25%" }}>Status</th> */}
										</tr>
									</thead>
									{loading ? (
										<tr className='loading-tr'>
											<div className='loading-table-wrapper'>
												<PulseLoader
													color='#0e5472'
													style={{ display: 'flex', justifyContent: 'center' }}
												/>
											</div>
										</tr>
									) : userLists?.length > 0 ? (
										userLists?.map((item) => (
											<tbody>
												<tr>
													<td>{item?.city}</td>
													<td>{item?.count}</td>
													{/* <td>{item?.email}</td> */}
													<td>
														{/* {item?.status} */}
														<div className={'button-div'}>
															<button
																className='btn edit-btn btn-primary'
																type='button'
																class='btn btn-primary'
																data-toggle='modal'
																data-target='#exampleModal2'
																onClick={() =>
																	navigate(
																		`/admin/city/${item?.city}/${item?.masjidId}`
																	)
																}
															>
																View
															</button>

															{/* <button
                            className="btn delete-btn btn-success"
                            data-toggle="modal"
                            data-target="#AcceptModel"
                            onClick={() => setId(item?._id)}
                          >
                            Accept
                          </button>
                          <button
                            className="btn delete-btn btn-danger"
                            data-toggle="modal"
                            data-target="#RejectModel"
                            onClick={() => setId(item?._id)}
                          >
                            Reject
                          </button> */}
														</div>
													</td>
												</tr>
											</tbody>
										))
									) : (
										<tr>
											<td colSpan={2}>
												<h1 style={{ textAlign: 'center' }}>No data found</h1>
											</td>
										</tr>
									)}
								</table>
							</div>

							{/* View pop started */}
							<div
								class='modal fade'
								id='exampleModal2'
								tabindex='-1'
								aria-labelledby='exampleModalLabel'
								aria-hidden='true'
							>
								<div class='modal-dialog shadow-none modal-dialog-centered'>
									<div class='modal-content'>
										<div class='modal-header'>
											<h5 class='modal-title' id='exampleModalLabel'>
												User details
											</h5>
											<button type='button' class='close' data-dismiss='modal'>
												&times;
											</button>
										</div>
										<div class='modal-body'>
											<table class='table'>
												<tbody>
													<tr>
														<td style={{ width: '50%' }}>
															Status<span style={{ float: 'right' }}>:</span>
														</td>
														<td>{editUser?.status}</td>
													</tr>
													<tr>
														<td style={{ width: '50%' }}>
															Name <span style={{ float: 'right' }}>:</span>
														</td>
														<td>John Doe{editUser?.name}</td>
													</tr>
													<tr>
														<td style={{ width: '50%' }}>
															Age <span style={{ float: 'right' }}>:</span>
														</td>
														<td>34{editUser?.age}</td>
													</tr>
													<tr>
														<td style={{ width: '50%' }}>
															City <span style={{ float: 'right' }}>:</span>
														</td>
														<td>Chennai{editUser?.city}</td>
													</tr>
													<tr>
														<td style={{ width: '50%' }}>
															State <span style={{ float: 'right' }}>:</span>
														</td>
														<td>Tamil Nadu{editUser?.state}</td>
													</tr>
													<tr>
														<td style={{ width: '50%' }}>
															Country <span style={{ float: 'right' }}>:</span>
														</td>
														<td>India{editUser?.country}</td>
													</tr>
												</tbody>
											</table>
										</div>
										<div class='modal-footer'>
											<button class='btn btn-primary' data-dismiss='modal'>
												Yes
											</button>

											<button
												class='btn btn-danger'
												type='button'
												data-dismiss='modal'
											>
												No
											</button>
										</div>
									</div>
								</div>
							</div>

							{/* Accept pop started */}
							<div
								class='modal fade'
								id='AcceptModel'
								tabindex='-1'
								aria-labelledby='exampleModalLabel'
								aria-hidden='true'
							>
								<div class='modal-dialog shadow-none modal-dialog-centered'>
									<div class='modal-content'>
										<div class='modal-header'>
											<h5 class='modal-title' id='exampleModalLabel'>
												Accept user
											</h5>
											<button type='button' class='close' data-dismiss='modal'>
												&times;
											</button>
										</div>
										<div class='modal-footer'>
											<button
												class='btn btn-primary'
												onClick={() => handleAcceptReject('accept')}
												data-dismiss='modal'
											>
												{loadingBtn ? (
													<PulseLoader
														color='#fff'
														style={{
															display: 'flex',
															justifyContent: 'center',
														}}
													/>
												) : (
													'Yes'
												)}
											</button>

											<button
												class='btn btn-danger'
												type='button'
												data-dismiss='modal'
											>
												No
											</button>
										</div>
									</div>
								</div>
							</div>
							{/* Accept pop wrapped */}

							{/* Reject pop started */}
							<div
								class='modal fade'
								id='RejectModel'
								tabindex='-1'
								aria-labelledby='exampleModalLabel'
								aria-hidden='true'
							>
								<div class='modal-dialog shadow-none modal-dialog-centered'>
									<div class='modal-content'>
										<div class='modal-header'>
											<h5 class='modal-title' id='exampleModalLabel'>
												Reject the user
											</h5>
											<button type='button' class='close' data-dismiss='modal'>
												&times;
											</button>
										</div>

										<div class='modal-footer'>
											<button
												class='btn btn-primary'
												onClick={() => handleAcceptReject('reject')}
												data-dismiss='modal'
											>
												{loadingBtn ? (
													<PulseLoader
														color='#fff'
														style={{
															display: 'flex',
															justifyContent: 'center',
														}}
													/>
												) : (
													'Yes'
												)}
											</button>

											<button
												class='btn btn-danger'
												type='button'
												data-dismiss='modal'
											>
												No
											</button>
										</div>
									</div>
								</div>
							</div>
							{/* Reject pop wrapped */}
						</div>
					</div>
				)}
			</DashboardLayout>
		</>
	);
};

export default ItikaafConfirmedList;
