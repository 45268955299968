import React, { useEffect, useState, useRef } from 'react';
import { State, City } from 'country-state-city';
import './user-list.css';
import DashboardLayout from '../dashboardLayout';
import {
	getMasjidListApi,
	getUserListApi,
	sendParticipationNotificationApi,
	bulkSendParticipationNotificationApi,
	exportUserListApi,
} from '../../../api/adminApi';
import { useDispatch, useSelector } from 'react-redux';
import { adminSelector } from '../../../redux/slicers/adminSlice';
import { PulseLoader } from 'react-spinners';
import Pagination from '../../../components/common/Pagination';
import { fileDownloadApi } from '../../../api/Sessions';
import { useNavigate } from 'react-router-dom';
import {
	DEBOUNCE_DELAY,
	USER_DATA,
} from '../../../config/constants/projectConstant';
import { useDebouncedEffect } from '../../../helpers/hooks';
import { getLocal } from '../../../helpers/projectHelper';

const ItikaafApprovedList = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { userLists } = useSelector(adminSelector);

	const [userRole, setUserRole] = useState('observer');
	const [editUser, setEditUser] = useState({});
	const [loading, setLoading] = useState(true);
	const [loadingApi, setLoadingApi] = useState(true);
	const [listSize, setListsize] = useState(1);
	const [pageNumbers, setPageNumbers] = useState([]);
	const [limit, setLimit] = useState({ start: 1, end: 3 });
	const [page, setPage] = useState(1);
	const SIZE = 25;

	const [view, setView] = useState('approved');

	const [masjidData, setMasjidData] = useState([]);
	const [selectedMasjidId, setSelectedMasjidId] = useState(null);

	const [searchTerm, setSearchTerm] = useState('');
	const [showFilter, setShowFilter] = useState(false);
	const [filterState, setFilterState] = useState('');
	const [filterCity, setFilterCity] = useState('');
	const [filterEtikafType, setFilterEtikafType] = useState('');
	const [filterKhidmat, setFilterKhidmat] = useState('');
	const [cityOptions, setCityOptions] = useState([]);
	const filterRef = useRef(null);

	const [selectedUsers, setSelectedUsers] = useState([]);
	const [loadingApproval, setLoadingApproval] = useState({});
	const [bulkLoading, setBulkLoading] = useState(false);

	const [userForApproval, setUserForApproval] = useState(null);

	const ItakaafType = [
		{ value: 'sunnat', label: 'Sunnah' },
		{ value: 'nafil', label: 'Nafil' },
	];

	const QuidmatType = [
		{ value: 'Khane Ki Khidmat', label: 'Khane Ki Khidmat' },
		{ value: 'Safai Ki Khidmat', label: 'Safai Ki Khidmat' },
		{ value: 'Nigrani Ki Khidmat', label: 'Nigrani Ki Khidmat' },
	];

	const indianStates =
		State.getStatesOfCountry('IN')?.map((item) => item.name) || [];

	useEffect(() => {
		if (filterState) {
			const stateObj = State.getStatesOfCountry('IN').find(
				(item) => item.name === filterState
			);
			if (stateObj) {
				const cities = City.getCitiesOfState('IN', stateObj.isoCode)?.map(
					(item) => item.name
				);
				setCityOptions(cities || []);
			}
		} else {
			setCityOptions([]);
		}
		setFilterCity('');
	}, [filterState]);

	useEffect(() => {
		const handleClickOutside = (event) => {
			if (filterRef.current && !filterRef.current.contains(event.target)) {
				setShowFilter(false);
			}
		};
		document.addEventListener('mousedown', handleClickOutside);
		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, []);

	const getMasjidList = () => {
		dispatch(getMasjidListApi(setLoadingApi, setMasjidData));
	};

	useEffect(() => {
		setLoadingApi(true);
		getMasjidList();
	}, []);

	useEffect(() => {
		const userData = getLocal(USER_DATA);
		setUserRole(userData?.role || 'observer');
	}, []);

	useEffect(() => {
		const totalPages = Math.ceil(listSize / SIZE);
		const pages = [];
		for (let i = 1; i <= totalPages; i++) {
			pages.push(i);
		}
		setPageNumbers(pages);
	}, [listSize, loadingApi]);

	const isInitialMount = useRef(true);

	useDebouncedEffect(
		() => {
			if (isInitialMount.current) {
				isInitialMount.current = false;
				return;
			}
			getUserList();
		},
		DEBOUNCE_DELAY,
		[searchTerm]
	);

	useEffect(() => {
		getUserList();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [page, masjidData, selectedMasjidId, view]);

	const getUserList = () => {
		if (!selectedMasjidId && !masjidData.length) return;
		dispatch(
			getUserListApi(
				'accepted',
				SIZE,
				setListsize,
				page,
				setLoading,
				selectedMasjidId ?? (masjidData.length ? masjidData[0]._id : null),
				{
					state: filterState,
					city: filterCity,
					etikafType: filterEtikafType,
					khidmat: filterKhidmat,
					search: searchTerm,
					isUserConfirmed: view === 'approved' ? 'yes' : 'no',
				}
			)
		);
		setSelectedUsers([]);
	};

	const applyFilters = () => {
		setPage(1);
		getUserList();
		setShowFilter(false);
	};

	const handleChangeMasjid = (event) => {
		setSelectedMasjidId(event.target.value);
	};

	const editRow = (user) => {
		setEditUser(user);
	};

	const handleSelectUser = (userId) => {
		if (selectedUsers.includes(userId)) {
			setSelectedUsers(selectedUsers.filter((id) => id !== userId));
		} else {
			setSelectedUsers([...selectedUsers, userId]);
		}
	};

	const isAllSelected = () => {
		return (
			userLists.length > 0 &&
			userLists.every((item) => selectedUsers.includes(item._id))
		);
	};

	const handleSelectAll = () => {
		if (isAllSelected()) {
			setSelectedUsers([]);
		} else {
			const allIds = userLists.map((item) => item._id);
			setSelectedUsers(allIds);
		}
	};

	const handleExport = () => {
		const filters = {
			state: filterState,
			city: filterCity,
			etikafType: filterEtikafType,
			khidmat: filterKhidmat,
			search: searchTerm,
			isUserConfirmed: view === 'approved' ? 'yes' : 'no', // Add export filter
		};
		const masjidValue =
			selectedMasjidId ?? (masjidData.length ? masjidData[0]._id : '');
		dispatch(exportUserListApi('accepted', masjidValue, filters));
	};

	const openSingleApprovalModal = (user) => {
		setUserForApproval(user);
	};

	const handleConfirmSingleApproval = () => {
		if (userForApproval) {
			const userId = userForApproval._id;
			setLoadingApproval((prev) => ({ ...prev, [userId]: true }));
			dispatch(
				sendParticipationNotificationApi(
					userId,
					() => {
						setUserForApproval(null);
					},
					() => {
						setLoadingApproval((prev) => ({ ...prev, [userId]: false }));
					}
				)
			);
		}
	};

	const handleCancelSingleApproval = () => {
		setUserForApproval(null);
	};

	const handleConfirmBulkApproval = () => {
		if (selectedUsers.length === 0) return;
		dispatch(
			bulkSendParticipationNotificationApi(
				selectedUsers,
				() => {
					setSelectedUsers([]);
				},
				setBulkLoading
			)
		);
	};

	return (
		<DashboardLayout>
			{loadingApi ? (
				<div className='loading-table-wrapper'>
					<PulseLoader
						color='#0e5472'
						style={{ display: 'flex', justifyContent: 'center' }}
					/>
				</div>
			) : (
				<div className='container video-upload p-0 mt-0'>
					<div className='d-flex flex-column flex-md-row justify-content-between align-items-md-center mb-3 bg-white p-3 rounded-2'>
						<h1 className='responsive-heading'>I'tikaf Admin Approved List</h1>
						<div className='d-flex justify-content-between align-items-center'>
							<div className='mr-2'>
								<input
									type='text'
									placeholder='Search...'
									className='form-control'
									value={searchTerm}
									onChange={(e) => setSearchTerm(e.target.value)}
								/>
							</div>
							<div className='dropdown'>
								<button
									className='btn btn-primary dropdown-toggle'
									type='button'
									onClick={() => setShowFilter(!showFilter)}
								>
									Filter By
								</button>
								<button
									className='btn btn-secondary ml-2'
									onClick={handleExport}
								>
									Export Excel
								</button>
								<button
									className='btn btn-info ml-2'
									onClick={() =>
										setView(view === 'approved' ? 'disapproved' : 'approved')
									}
								>
									{view === 'approved'
										? 'View Disapproved'
										: 'View Admin Approved'}
								</button>
								<div
									ref={filterRef}
									className={`dropdown-menu p-3 dropdown-menu-right ${
										showFilter ? 'show' : ''
									}`}
									style={{ minWidth: '300px' }}
								>
									<div className='form-group'>
										<label>Masjid</label>
										<select
											className='form-control'
											value={
												selectedMasjidId ??
												(masjidData.length ? masjidData[0]._id : '')
											}
											onChange={handleChangeMasjid}
										>
											{masjidData.map((msjd) => (
												<option key={msjd._id} value={msjd._id}>
													{msjd.name}
												</option>
											))}
										</select>
									</div>
									<div className='form-group'>
										<label>State</label>
										<select
											className='form-control'
											value={filterState}
											onChange={(e) => setFilterState(e.target.value)}
										>
											<option value=''>Select State</option>
											{indianStates.map((state) => (
												<option key={state} value={state}>
													{state}
												</option>
											))}
										</select>
									</div>
									<div className='form-group'>
										<label>City</label>
										<select
											className='form-control'
											value={filterCity}
											disabled={!filterState}
											onChange={(e) => setFilterCity(e.target.value)}
										>
											<option value=''>
												{!filterState ? 'Please Select State' : 'Select City'}
											</option>
											{cityOptions.map((city) => (
												<option key={city} value={city}>
													{city}
												</option>
											))}
										</select>
									</div>
									<div className='form-group'>
										<label>I'tikaf Type</label>
										<select
											className='form-control'
											value={filterEtikafType}
											onChange={(e) => setFilterEtikafType(e.target.value)}
										>
											<option value=''>Select I'tikaf Type</option>
											{ItakaafType.map((type) => (
												<option key={type.value} value={type.value}>
													{type.label}
												</option>
											))}
										</select>
									</div>
									<div className='form-group'>
										<label>Khidmat</label>
										<select
											className='form-control'
											value={filterKhidmat}
											onChange={(e) => setFilterKhidmat(e.target.value)}
										>
											<option value=''>Select Khidmat</option>
											{QuidmatType.map((qType) => (
												<option key={qType.value} value={qType.value}>
													{qType.label}
												</option>
											))}
										</select>
									</div>
									<button
										className='btn btn-success mt-2'
										onClick={applyFilters}
									>
										Apply
									</button>
								</div>
							</div>
						</div>
					</div>

					{userRole !== 'observer' && (
						<div className='bulk-actions mb-2'>
							<button
								className='btn btn-success'
								data-toggle='modal'
								data-target='#BulkApprovalConfirmModal'
								disabled
								// disabled={!selectedUsers.length || bulkLoading}
							>
								{bulkLoading ? (
									<PulseLoader color='#fff' size={8} />
								) : (
									<>
										<i className='fas fa-paper-plane'></i> Send Confirmation Msg
									</>
								)}
							</button>
						</div>
					)}

					<div className='flex table-lists'>
						<div className='flex-large'>
							<table className='table' style={{ border: '1px solid #e9ecef' }}>
								<thead>
									<tr>
										{userRole !== 'observer' && (
											<th style={{ width: '30px', textAlign: 'center' }}>
												<input
													type='checkbox'
													onChange={handleSelectAll}
													checked={isAllSelected()}
												/>
											</th>
										)}
										<th style={{ width: '10%' }}>User Id</th>
										<th style={{ width: '20%' }}>Name</th>
										<th style={{ width: '5%' }}>Age</th>
										<th style={{ width: '15%' }}>Email</th>
										<th style={{ width: '10%' }}>Status</th>
										<th style={{ width: '10%' }}>Notified</th>
										<th>Actions</th>
									</tr>
								</thead>
								{loading ? (
									<tr className='loading-tr'>
										<td colSpan='7'>
											<div className='loading-table-wrapper'>
												<PulseLoader
													color='#0e5472'
													style={{ display: 'flex', justifyContent: 'center' }}
												/>
											</div>
										</td>
									</tr>
								) : userLists?.length > 0 ? (
									userLists.map((item) => (
										<tbody key={item._id}>
											<tr>
												{userRole !== 'observer' && (
													<td style={{ textAlign: 'center' }}>
														<input
															type='checkbox'
															checked={selectedUsers.includes(item._id)}
															onChange={() => handleSelectUser(item._id)}
														/>
													</td>
												)}
												<td>{item?.id ?? 'NA'}</td>
												<td>{item?.name}</td>
												<td>{item?.age}</td>
												<td>{item?.email}</td>
												<td>{item?.status}</td>
												<td>{item?.notifiedCount || 0}</td>
												<td>
													<div className='button-div'>
														<button
															className='btn btn-primary'
															data-toggle='modal'
															data-target='#exampleModal2'
															onClick={() => editRow(item)}
															style={{
																minWidth: '30px',
																height: '35px',
															}}
														>
															<i className='fa-regular fa-eye'></i>
														</button>
														{userRole !== 'observer' && (
															<button
																className='btn btn-success ml-2'
																onClick={() => openSingleApprovalModal(item)}
																data-toggle='modal'
																data-target='#ApprovalConfirmModal'
																disabled={!!loadingApproval[item._id]}
															>
																{loadingApproval[item._id] ? (
																	<PulseLoader color='#fff' size={8} />
																) : (
																	<>
																		<i className='fas fa-paper-plane'></i> Send
																		Confirmation Msg
																	</>
																)}
															</button>
														)}
													</div>
												</td>
											</tr>
										</tbody>
									))
								) : (
									<tr>
										<td colSpan={7}>
											<h1 style={{ textAlign: 'center' }}>No data found</h1>
										</td>
									</tr>
								)}
							</table>
							<div className='customer_page'>
								<div className='pagination'>
									<Pagination
										setPage={setPage}
										limit={limit}
										pageNumbers={pageNumbers}
										setLimit={setLimit}
										page={page}
									/>
								</div>
							</div>
						</div>
					</div>

					<div
						className='modal fade'
						id='exampleModal2'
						tabIndex='-1'
						aria-labelledby='exampleModalLabel'
						aria-hidden='true'
					>
						<div className='modal-dialog shadow-none modal-dialog-centered'>
							<div className='modal-content'>
								<div className='modal-header'>
									<h5 className='modal-title' id='exampleModalLabel'>
										User details
									</h5>
									<button type='button' className='close' data-dismiss='modal'>
										&times;
									</button>
								</div>
								<div className='modal-body'>
									<div className='user-det'>
										<img src={fileDownloadApi(editUser?.photo)} alt='user' />
									</div>
									<table className='table'>
										<tbody>
											<tr>
												<td style={{ width: '50%' }}>
													Status <span style={{ float: 'right' }}>:</span>
												</td>
												<td>{editUser?.status}</td>
											</tr>
											<tr>
												<td style={{ width: '50%' }}>
													Name <span style={{ float: 'right' }}>:</span>
												</td>
												<td>{editUser?.name}</td>
											</tr>
											<tr>
												<td style={{ width: '50%' }}>
													Age <span style={{ float: 'right' }}>:</span>
												</td>
												<td>{editUser?.age}</td>
											</tr>
											<tr>
												<td style={{ width: '50%' }}>
													Contact Number{' '}
													<span style={{ float: 'right' }}>:</span>
												</td>
												<td>{editUser?.contactNumber}</td>
											</tr>
											<tr>
												<td style={{ width: '50%' }}>
													Whatsapp Number{' '}
													<span style={{ float: 'right' }}>:</span>
												</td>
												<td>{editUser?.whatsappNumber}</td>
											</tr>
											<tr>
												<td style={{ width: '50%' }}>
													City <span style={{ float: 'right' }}>:</span>
												</td>
												<td>{editUser?.city}</td>
											</tr>
											<tr>
												<td style={{ width: '50%' }}>
													State <span style={{ float: 'right' }}>:</span>
												</td>
												<td>{editUser?.state}</td>
											</tr>
											<tr>
												<td style={{ width: '50%' }}>
													Etekaaf Maqsad{' '}
													<span style={{ float: 'right' }}>:</span>
												</td>
												<td>{editUser?.etekaafMaqsad}</td>
											</tr>
											<tr>
												<td style={{ width: '50%' }}>
													Itikaf Type <span style={{ float: 'right' }}>:</span>
												</td>
												<td>{editUser?.typeOfIthikaaf}</td>
											</tr>
											<tr>
												<td style={{ width: '50%' }}>
													Nafil Days <span style={{ float: 'right' }}>:</span>
												</td>
												<td>{editUser?.nafilDays}</td>
											</tr>
											<tr>
												<td style={{ width: '50%' }}>
													Is Baiyath <span style={{ float: 'right' }}>:</span>
												</td>
												<td>
													{editUser?.baiyath?.isbaiyath || false ? 'Yes' : 'No'}
												</td>
											</tr>
											<tr>
												<td style={{ width: '50%' }}>
													Baiyath With <span style={{ float: 'right' }}>:</span>
												</td>
												<td>{editUser?.baiyath?.baiyathWith || 'N/A'}</td>
											</tr>
											<tr>
												<td style={{ width: '50%' }}>
													Baiyath Duration{' '}
													<span style={{ float: 'right' }}>:</span>
												</td>
												<td>{editUser?.baiyath?.howLongBaiyath || 'N/A'}</td>
											</tr>
											<tr>
												<td style={{ width: '50%' }}>
													Muraqaba <span style={{ float: 'right' }}>:</span>
												</td>
												<td>{editUser?.baiyath?.muqaraba || 'N/A'}</td>
											</tr>
											<tr>
												<td style={{ width: '50%' }}>
													Sabaq <span style={{ float: 'right' }}>:</span>
												</td>
												<td>{editUser?.baiyath?.sabaq || 'N/A'}</td>
											</tr>
											<tr>
												<td style={{ width: '50%' }}>
													Notes <span style={{ float: 'right' }}>:</span>
												</td>
												<td>{editUser?.notes}</td>
											</tr>
											{editUser?.officialIdProof && (
												<tr>
													<td style={{ width: '50%' }}>
														Id Proof <span style={{ float: 'right' }}>:</span>
													</td>
													<td>
														<div className='user-detail'>
															<img
																src={fileDownloadApi(editUser?.officialIdProof)}
																alt='id-proof'
															/>
														</div>
													</td>
												</tr>
											)}
										</tbody>
									</table>
								</div>
							</div>
						</div>
					</div>

					<div
						className='modal fade'
						id='ApprovalConfirmModal'
						tabIndex='-1'
						aria-labelledby='ApprovalConfirmModalLabel'
						aria-hidden='true'
					>
						<div className='modal-dialog shadow-none modal-dialog-centered'>
							<div className='modal-content'>
								<div className='modal-header'>
									<h5 className='modal-title' id='ApprovalConfirmModalLabel'>
										Confirm Approval Notification
									</h5>
									<button
										type='button'
										className='close'
										data-dismiss='modal'
										aria-label='Close'
									>
										<span aria-hidden='true'>&times;</span>
									</button>
								</div>
								<div className='modal-body'>
									{userForApproval && (
										<p>
											Are you sure you want to send an approval notification to{' '}
											<strong>{userForApproval.name}</strong>?
										</p>
									)}
								</div>
								<div className='modal-footer'>
									<button
										className='btn btn-primary'
										onClick={handleConfirmSingleApproval}
										data-dismiss='modal'
										disabled={
											userForApproval && !!loadingApproval[userForApproval._id]
										}
									>
										{userForApproval && loadingApproval[userForApproval._id] ? (
											<PulseLoader color='#fff' size={8} />
										) : (
											'Yes'
										)}
									</button>
									<button
										className='btn btn-danger'
										onClick={handleCancelSingleApproval}
										data-dismiss='modal'
									>
										No
									</button>
								</div>
							</div>
						</div>
					</div>

					<div
						className='modal fade'
						id='BulkApprovalConfirmModal'
						tabIndex='-1'
						aria-labelledby='BulkApprovalConfirmModalLabel'
						aria-hidden='true'
					>
						<div className='modal-dialog shadow-none modal-dialog-centered'>
							<div className='modal-content'>
								<div className='modal-header'>
									<h5
										className='modal-title'
										id='BulkApprovalConfirmModalLabel'
									>
										Bulk Approval Notification
									</h5>
									<button
										type='button'
										className='close'
										data-dismiss='modal'
										aria-label='Close'
									>
										<span aria-hidden='true'>&times;</span>
									</button>
								</div>
								<div className='modal-body'>
									<p>
										Are you sure you want to send approval notifications to{' '}
										<strong>{selectedUsers.length}</strong> users?
									</p>
								</div>
								<div className='modal-footer'>
									<button
										className='btn btn-success'
										onClick={handleConfirmBulkApproval}
										data-dismiss='modal'
										disabled={bulkLoading}
									>
										{bulkLoading ? (
											<PulseLoader color='#fff' size={8} />
										) : (
											'Yes'
										)}
									</button>
									<button className='btn btn-danger' data-dismiss='modal'>
										No
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			)}
		</DashboardLayout>
	);
};

export default ItikaafApprovedList;
