import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {
	Html5QrcodeScanner,
	Html5QrcodeScanType,
	Html5QrcodeSupportedFormats,
} from 'html5-qrcode';
import DashboardLayout from '../dashboardLayout';
import {
	checkInUserApi,
	inviteUserRegistrationApi,
	markMetWithSheikhApi,
} from '../../../api/adminApi';
import './checkInAndInvite.css';
import { PulseLoader } from 'react-spinners';
import { errorToast } from '../../../helpers/projectHelper';

const CheckInAndInvite = () => {
	const dispatch = useDispatch();
	const [showScanner, setShowScanner] = useState(false);
	const [showSheikhScanner, setShowSheikhScanner] = useState(false);
	const [loading, setLoading] = useState(false);
	const [sheikhLoading, setSheikhLoading] = useState(false);
	const [inviteLoading, setInviteLoading] = useState(false);
	const [showInviteForm, setShowInviteForm] = useState(false);

	const [email, setEmail] = useState('');
	const [contactNumber, setContactNumber] = useState('');
	const [whatsappNumber, setWhatsappNumber] = useState('');

	useEffect(() => {
		if (showScanner) {
			const scanner = new Html5QrcodeScanner(
				'reader',
				{
					fps: 10,
					qrbox: 250,
					videoConstraints: { facingMode: 'environment' },
					supportedScanTypes: [Html5QrcodeScanType.SCAN_TYPE_CAMERA],
					formatsToSupport: [Html5QrcodeSupportedFormats.QR_CODE],
				},
				false
			);
			scanner.render(onScanSuccess, onScanFailure);
			return () => {
				scanner
					.clear()
					.catch((err) => console.error('QR Scanner cleanup error:', err));
			};
		}
	}, [showScanner]);

	useEffect(() => {
		if (showSheikhScanner) {
			const sheikhScanner = new Html5QrcodeScanner(
				'sheikh-reader',
				{
					fps: 10,
					qrbox: 250,
					videoConstraints: { facingMode: 'environment' },
					supportedScanTypes: [Html5QrcodeScanType.SCAN_TYPE_CAMERA],
					formatsToSupport: [Html5QrcodeSupportedFormats.QR_CODE],
				},
				false
			);
			sheikhScanner.render(onSheikhScanSuccess, onSheikhScanFailure);
			return () => {
				sheikhScanner
					.clear()
					.catch((err) =>
						console.error('Sheikh QR Scanner cleanup error:', err)
					);
			};
		}
	}, [showSheikhScanner]);

	const onScanSuccess = (decodedText) => {
		setShowScanner(false);
		dispatch(checkInUserApi(decodedText, setLoading));
	};

	const onScanFailure = (error) => {
		console.warn('Scan Error:', error);
	};

	const onSheikhScanSuccess = (decodedText) => {
		setShowSheikhScanner(false);
		dispatch(markMetWithSheikhApi(decodedText, setSheikhLoading));
	};

	const onSheikhScanFailure = (error) => {
		console.warn('Sheikh Scan Error:', error);
	};

	const handleInviteUser = () => {
		if (!email && !contactNumber && !whatsappNumber) {
			errorToast('Please enter at least one contact detail.');
			return;
		}

		const userData = {
			email: email || null,
			contactNumber: contactNumber || null,
			whatsappNumber: whatsappNumber || null,
		};

		dispatch(inviteUserRegistrationApi(userData, setInviteLoading));

		setEmail('');
		setContactNumber('');
		setWhatsappNumber('');
		setShowInviteForm(false);
	};

	return (
		<DashboardLayout>
			{loading || sheikhLoading ? (
				<div className='loading-table-wrapper'>
					<PulseLoader
						color='#0e5472'
						style={{ display: 'flex', justifyContent: 'center' }}
					/>
				</div>
			) : (
				<div className='container mt-4'>
					<h3>Check-In, Mark Met with Sheikh & Invite Users</h3>
					<div className='d-flex gap-3'>
						<button
							className='btn btn-primary'
							onClick={() => setShowScanner(true)}
						>
							Check User In
						</button>
						<button
							className='btn btn-info'
							onClick={() => setShowSheikhScanner(true)}
						>
							Mark as Met with Sheikh
						</button>
						<button
							className='btn btn-secondary'
							onClick={() => setShowInviteForm(true)}
						>
							Invite User
						</button>
					</div>

					{/* Invite User Modal */}
					{showInviteForm && (
						<div className='modal show' style={{ display: 'block' }}>
							<div className='modal-dialog'>
								<div className='modal-content'>
									<div className='modal-header'>
										<h5 className='modal-title'>Invite User</h5>
										<button
											type='button'
											className='close'
											onClick={() => setShowInviteForm(false)}
										>
											<span>&times;</span>
										</button>
									</div>
									<div className='modal-body'>
										<label>Email:</label>
										<input
											type='email'
											className='form-control mb-2'
											value={email}
											onChange={(e) => setEmail(e.target.value)}
											placeholder='Enter email'
										/>

										<label>Contact Number:</label>
										<input
											type='text'
											className='form-control mb-2'
											value={contactNumber}
											onChange={(e) => setContactNumber(e.target.value)}
											placeholder='Enter contact number'
										/>

										<label>WhatsApp Number:</label>
										<input
											type='text'
											className='form-control mb-2'
											value={whatsappNumber}
											onChange={(e) => setWhatsappNumber(e.target.value)}
											placeholder='Enter WhatsApp number'
										/>
									</div>
									<div className='modal-footer'>
										<button
											className='btn btn-success'
											onClick={handleInviteUser}
											disabled={inviteLoading}
										>
											<span>
												Send Invite {inviteLoading && <PulseLoader size={6} />}
											</span>
										</button>
										<button
											className='btn btn-secondary'
											onClick={() => setShowInviteForm(false)}
										>
											Close
										</button>
									</div>
								</div>
							</div>
							<div
								className='modal-backdrop fade show'
								onClick={() => setShowInviteForm(false)}
							></div>
						</div>
					)}

					{showScanner && (
						<div className='modal show' style={{ display: 'block' }}>
							<div className='modal-dialog'>
								<div className='modal-content'>
									<div className='modal-header'>
										<h5 className='modal-title'>Scan QR Code</h5>
										<button
											type='button'
											className='close'
											onClick={() => setShowScanner(false)}
										>
											<span>&times;</span>
										</button>
									</div>
									<div className='modal-body'>
										<div id='reader'></div>
									</div>
								</div>
							</div>
							<div
								className='modal-backdrop fade show'
								onClick={() => setShowScanner(false)}
							></div>
						</div>
					)}

					{showSheikhScanner && (
						<div className='modal show' style={{ display: 'block' }}>
							<div className='modal-dialog'>
								<div className='modal-content'>
									<div className='modal-header'>
										<h5 className='modal-title'>
											Scan QR Code - Met with Sheikh
										</h5>
										<button
											type='button'
											className='close'
											onClick={() => setShowSheikhScanner(false)}
										>
											<span>&times;</span>
										</button>
									</div>
									<div className='modal-body'>
										<div id='sheikh-reader'></div>
									</div>
								</div>
							</div>
							<div
								className='modal-backdrop fade show'
								onClick={() => setShowSheikhScanner(false)}
							></div>
						</div>
					)}
				</div>
			)}
		</DashboardLayout>
	);
};

export default CheckInAndInvite;
