import React, { useEffect, useState, useRef } from 'react';
import { State, City } from 'country-state-city';
import './user-list.css';
import DashboardLayout from '../dashboardLayout';
import {
	getMasjidListApi,
	getUserListApi,
	exportUserListApi,
} from '../../../api/adminApi';
import { useDispatch, useSelector } from 'react-redux';
import { adminSelector } from '../../../redux/slicers/adminSlice';
import { PulseLoader } from 'react-spinners';
import Pagination from '../../../components/common/Pagination';
import {
	fileDownloadApi,
	userItikaafConfirmationAccepted,
	userItikaafConfirmationRejected,
} from '../../../api/Sessions';
import { useNavigate } from 'react-router-dom';
import { DEBOUNCE_DELAY } from '../../../config/constants/projectConstant';
import { useDebouncedEffect } from '../../../helpers/hooks';

const ItakaafRejectedList = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { userLists } = useSelector(adminSelector);

	const [editUser, setEditUser] = useState({});
	const [loading, setLoading] = useState(true);
	const [loadingBtn, setLoadingBtn] = useState(false);
	const [loadingApi, setLoadingApi] = useState(true);
	const [id, setId] = useState('');
	const [status] = useState('rejected');
	const [listSize, setListsize] = useState(1);
	const [pageNumbers, setPageNumbers] = useState([]);
	const [limit, setLimit] = useState({ start: 1, end: 3 });
	const [page, setPage] = useState(1);
	const SIZE = 25;

	const [masjidData, setMasjidData] = useState([]);
	const [selectedMasjidId, setSelectedMasjidId] = useState(null);

	const [searchTerm, setSearchTerm] = useState('');
	const [showFilter, setShowFilter] = useState(false);
	const [filterState, setFilterState] = useState('');
	const [filterCity, setFilterCity] = useState('');
	const [filterEtikafType, setFilterEtikafType] = useState('');
	const [filterKhidmat, setFilterKhidmat] = useState('');
	const [cityOptions, setCityOptions] = useState([]);
	const filterRef = useRef(null);

	const ItakaafType = [
		{ value: 'sunnat', label: 'Sunnah' },
		{ value: 'nafil', label: 'Nafil' },
	];

	const QuidmatType = [
		{ value: 'Khane Ki Khidmat', label: 'Khane Ki Khidmat' },
		{ value: 'Safai Ki Khidmat', label: 'Safai Ki Khidmat' },
		{ value: 'Nigrani Ki Khidmat', label: 'Nigrani Ki Khidmat' },
	];

	const indianStates =
		State.getStatesOfCountry('IN')?.map((item) => item.name) || [];

	useEffect(() => {
		if (filterState) {
			const stateObj = State.getStatesOfCountry('IN').find(
				(item) => item.name === filterState
			);
			if (stateObj) {
				const cities = City.getCitiesOfState('IN', stateObj.isoCode)?.map(
					(item) => item.name
				);
				setCityOptions(cities || []);
			}
		} else {
			setCityOptions([]);
		}
		setFilterCity('');
	}, [filterState]);

	useEffect(() => {
		const handleClickOutside = (event) => {
			if (filterRef.current && !filterRef.current.contains(event.target)) {
				setShowFilter(false);
			}
		};
		document.addEventListener('mousedown', handleClickOutside);
		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, []);

	const handleExport = () => {
		const filters = {
			state: filterState,
			city: filterCity,
			etikafType: filterEtikafType,
			khidmat: filterKhidmat,
			search: searchTerm,
		};
		const masjidValue =
			selectedMasjidId ?? (masjidData.length ? masjidData[0]._id : '');
		dispatch(exportUserListApi(status, masjidValue, filters));
	};

	const getMasjidList = () => {
		dispatch(getMasjidListApi(setLoadingApi, setMasjidData));
	};

	useEffect(() => {
		setLoadingApi(true);
		getMasjidList();
	}, []);

	useEffect(() => {
		const totalPages = Math.ceil(listSize / SIZE);
		const pages = [];
		for (let i = 1; i <= totalPages; i++) {
			pages.push(i);
		}
		setPageNumbers(pages);
	}, [listSize, loadingApi]);

	const isInitialMount = useRef(true);

	useDebouncedEffect(
		() => {
			if (isInitialMount.current) {
				isInitialMount.current = false;
				return;
			}
			getUserList();
		},
		DEBOUNCE_DELAY,
		[searchTerm]
	);

	useEffect(() => {
		getUserList();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [page, masjidData, selectedMasjidId]);

	const getUserList = () => {
		if (!selectedMasjidId && !masjidData.length) return;
		dispatch(
			getUserListApi(
				status,
				SIZE,
				setListsize,
				page,
				setLoading,
				selectedMasjidId ?? (masjidData.length ? masjidData[0]._id : null),
				{
					state: filterState,
					city: filterCity,
					etikafType: filterEtikafType,
					khidmat: filterKhidmat,
					search: searchTerm,
				}
			)
		);
	};

	const applyFilters = () => {
		setPage(1);
		getUserList();
		setShowFilter(false);
	};

	const handleChangeMasjid = (event) => {
		setSelectedMasjidId(event.target.value);
	};

	const editRow = (user) => {
		setEditUser(user);
	};

	const handleAcceptReject = (actionStatus) => {
		setLoadingBtn(true);
		const isAdmin = true;
		if (actionStatus === 'accept') {
			const formData = {
				id: id,
				isUserConfirmed: true,
			};
			dispatch(
				userItikaafConfirmationAccepted(
					formData,
					navigate,
					setLoadingBtn,
					isAdmin,
					getUserList
				)
			);
		} else {
			const formData = {
				id: id,
				isUserConfirmed: false,
			};
			dispatch(
				userItikaafConfirmationRejected(
					formData,
					navigate,
					setLoadingBtn,
					isAdmin,
					getUserList
				)
			);
		}
	};

	return (
		<DashboardLayout>
			{loadingApi ? (
				<div className='loading-table-wrapper'>
					<PulseLoader
						color='#0e5472'
						style={{ display: 'flex', justifyContent: 'center' }}
					/>
				</div>
			) : (
				<div className='container video-upload p-0 mt-0'>
					<div className='d-flex flex-column flex-md-row justify-content-between align-items-md-center mb-3 bg-white p-3 rounded-2'>
						<h1 className='responsive-heading'>I'tikaf Admin Complete Mu'takif List</h1>
						<div className='d-flex justify-content-between align-items-center'>
							<div className='mr-2'>
								<input
									type='text'
									placeholder='Search...'
									className='form-control'
									value={searchTerm}
									onChange={(e) => setSearchTerm(e.target.value)}
								/>
							</div>
							<div className='dropdown'>
								<button
									className='btn btn-primary dropdown-toggle'
									type='button'
									onClick={() => setShowFilter(!showFilter)}
								>
									Filter By
								</button>
								<button
									className='btn btn-secondary ml-2'
									onClick={handleExport}
								>
									Export Excel
								</button>
								<div
									ref={filterRef}
									className={`dropdown-menu p-3 dropdown-menu-right ${
										showFilter ? 'show' : ''
									}`}
									style={{ minWidth: '300px' }}
								>
									<div className='form-group'>
										<label>Masjid</label>
										<select
											className='form-control'
											value={
												selectedMasjidId ??
												(masjidData.length ? masjidData[0]._id : '')
											}
											onChange={handleChangeMasjid}
										>
											{masjidData.map((msjd) => (
												<option key={msjd._id} value={msjd._id}>
													{msjd.name}
												</option>
											))}
										</select>
									</div>
									<div className='form-group'>
										<label>State</label>
										<select
											className='form-control'
											value={filterState}
											onChange={(e) => setFilterState(e.target.value)}
										>
											<option value=''>Select State</option>
											{indianStates.map((state) => (
												<option key={state} value={state}>
													{state}
												</option>
											))}
										</select>
									</div>
									<div className='form-group'>
										<label>City</label>
										<select
											className='form-control'
											value={filterCity}
											disabled={!filterState}
											onChange={(e) => setFilterCity(e.target.value)}
										>
											<option value=''>
												{!filterState ? 'Please Select State' : 'Select City'}
											</option>
											{cityOptions.map((city) => (
												<option key={city} value={city}>
													{city}
												</option>
											))}
										</select>
									</div>
									<div className='form-group'>
										<label>I'tikaf Type</label>
										<select
											className='form-control'
											value={filterEtikafType}
											onChange={(e) => setFilterEtikafType(e.target.value)}
										>
											<option value=''>Select I'tikaf Type</option>
											{ItakaafType.map((type) => (
												<option key={type.value} value={type.value}>
													{type.label}
												</option>
											))}
										</select>
									</div>
									<div className='form-group'>
										<label>Khidmat</label>
										<select
											className='form-control'
											value={filterKhidmat}
											onChange={(e) => setFilterKhidmat(e.target.value)}
										>
											<option value=''>Select Khidmat</option>
											{QuidmatType.map((qType) => (
												<option key={qType.value} value={qType.value}>
													{qType.label}
												</option>
											))}
										</select>
									</div>
									<button
										className='btn btn-success mt-2'
										onClick={applyFilters}
									>
										Apply
									</button>
								</div>
							</div>
						</div>
					</div>

					<div className='flex table-lists'>
						<div className='flex-large'>
							<table className='table' style={{ border: '1px solid #e9ecef' }}>
								<thead>
									<tr>
										<th style={{ width: '20%' }}>User Id</th>
										<th style={{ width: '30%' }}>Name</th>
										<th style={{ width: '15%' }}>Age</th>
										<th style={{ width: '30%' }}>Email</th>
										<th style={{ width: '25%' }}>Status</th>
									</tr>
								</thead>
								{loading ? (
									<tr className='loading-tr'>
										<td colSpan='5'>
											<div className='loading-table-wrapper'>
												<PulseLoader
													color='#0e5472'
													style={{ display: 'flex', justifyContent: 'center' }}
												/>
											</div>
										</td>
									</tr>
								) : userLists?.length > 0 ? (
									userLists.map((item) => (
										<tbody key={item._id}>
											<tr>
												<td>{item?.id ?? 'NA'}</td>
												<td>{item?.name}</td>
												<td>{item?.age}</td>
												<td>{item?.email}</td>
												<td>
													<div className='button-div'>
														<button
															className='btn btn-primary'
															data-toggle='modal'
															data-target='#exampleModal2'
															onClick={() => editRow(item)}
														>
															View
														</button>
													</div>
												</td>
											</tr>
										</tbody>
									))
								) : (
									<tr>
										<td colSpan={5}>
											<h1 style={{ textAlign: 'center' }}>No data found</h1>
										</td>
									</tr>
								)}
							</table>
							<div className='customer_page'>
								<div className='pagination'>
									<Pagination
										setPage={setPage}
										limit={limit}
										pageNumbers={pageNumbers}
										setLimit={setLimit}
										page={page}
									/>
								</div>
							</div>
						</div>
					</div>

					<div
						className='modal fade'
						id='exampleModal2'
						tabIndex='-1'
						aria-labelledby='exampleModalLabel'
						aria-hidden='true'
					>
						<div className='modal-dialog shadow-none modal-dialog-centered'>
							<div className='modal-content'>
								<div className='modal-header'>
									<h5 className='modal-title' id='exampleModalLabel'>
										User details
									</h5>
									<button type='button' className='close' data-dismiss='modal'>
										&times;
									</button>
								</div>
								<div className='modal-body'>
									<div className='user-det'>
										<img src={fileDownloadApi(editUser?.photo)} alt='user' />
									</div>
									<table className='table'>
										<tbody>
											<tr>
												<td style={{ width: '50%' }}>
													Status <span style={{ float: 'right' }}>:</span>
												</td>
												<td>{editUser?.status}</td>
											</tr>
											<tr>
												<td style={{ width: '50%' }}>
													Name <span style={{ float: 'right' }}>:</span>
												</td>
												<td>{editUser?.name}</td>
											</tr>
											<tr>
												<td style={{ width: '50%' }}>
													Age <span style={{ float: 'right' }}>:</span>
												</td>
												<td>{editUser?.age}</td>
											</tr>
											<tr>
												<td style={{ width: '50%' }}>
													Contact Number{' '}
													<span style={{ float: 'right' }}>:</span>
												</td>
												<td>{editUser?.contactNumber}</td>
											</tr>
											<tr>
												<td style={{ width: '50%' }}>
													Whatsapp Number{' '}
													<span style={{ float: 'right' }}>:</span>
												</td>
												<td>{editUser?.whatsappNumber}</td>
											</tr>
											<tr>
												<td style={{ width: '50%' }}>
													City <span style={{ float: 'right' }}>:</span>
												</td>
												<td>{editUser?.city}</td>
											</tr>
											<tr>
												<td style={{ width: '50%' }}>
													State <span style={{ float: 'right' }}>:</span>
												</td>
												<td>{editUser?.state}</td>
											</tr>
											<tr>
												<td style={{ width: '50%' }}>
													Etekaaf Maqsad{' '}
													<span style={{ float: 'right' }}>:</span>
												</td>
												<td>{editUser?.etekaafMaqsad}</td>
											</tr>
											<tr>
												<td style={{ width: '50%' }}>
													Itikaf Type <span style={{ float: 'right' }}>:</span>
												</td>
												<td>{editUser?.typeOfIthikaaf}</td>
											</tr>
											<tr>
												<td style={{ width: '50%' }}>
													Nafil Days <span style={{ float: 'right' }}>:</span>
												</td>
												<td>{editUser?.nafilDays}</td>
											</tr>
											<tr>
												<td style={{ width: '50%' }}>
													Is Baiyath <span style={{ float: 'right' }}>:</span>
												</td>
												<td>
													{editUser?.baiyath?.isbaiyath || false ? 'Yes' : 'No'}
												</td>
											</tr>
											<tr>
												<td style={{ width: '50%' }}>
													Baiyath With <span style={{ float: 'right' }}>:</span>
												</td>
												<td>{editUser?.baiyath?.baiyathWith || 'N/A'}</td>
											</tr>
											<tr>
												<td style={{ width: '50%' }}>
													Baiyath Duration{' '}
													<span style={{ float: 'right' }}>:</span>
												</td>
												<td>{editUser?.baiyath?.howLongBaiyath || 'N/A'}</td>
											</tr>
											<tr>
												<td style={{ width: '50%' }}>
													Muraqaba <span style={{ float: 'right' }}>:</span>
												</td>
												<td>{editUser?.baiyath?.muqaraba || 'N/A'}</td>
											</tr>
											<tr>
												<td style={{ width: '50%' }}>
													Sabaq <span style={{ float: 'right' }}>:</span>
												</td>
												<td>{editUser?.baiyath?.sabaq || 'N/A'}</td>
											</tr>
											<tr>
												<td style={{ width: '50%' }}>
													Notes <span style={{ float: 'right' }}>:</span>
												</td>
												<td>{editUser?.notes}</td>
											</tr>
											{editUser?.officialIdProof && (
												<tr>
													<td style={{ width: '50%' }}>
														Id Proof <span style={{ float: 'right' }}>:</span>
													</td>
													<td>
														<div className='user-detail'>
															<img
																src={fileDownloadApi(editUser?.officialIdProof)}
																alt='mixlr'
															/>
														</div>
													</td>
												</tr>
											)}
										</tbody>
									</table>
								</div>
							</div>
						</div>
					</div>

					<div
						className='modal fade'
						id='AcceptModel'
						tabIndex='-1'
						aria-labelledby='exampleModalLabel'
						aria-hidden='true'
					>
						<div className='modal-dialog shadow-none modal-dialog-centered'>
							<div className='modal-content'>
								<div className='modal-header'>
									<h5 className='modal-title' id='exampleModalLabel'>
										Accept user
									</h5>
									<button type='button' className='close' data-dismiss='modal'>
										&times;
									</button>
								</div>
								<div className='modal-footer'>
									<button
										className='btn btn-primary'
										onClick={() => handleAcceptReject('accept')}
										data-dismiss='modal'
									>
										{loadingBtn ? (
											<PulseLoader
												color='#fff'
												style={{
													display: 'flex',
													justifyContent: 'center',
												}}
											/>
										) : (
											'Yes'
										)}
									</button>
									<button
										className='btn btn-danger'
										type='button'
										data-dismiss='modal'
									>
										No
									</button>
								</div>
							</div>
						</div>
					</div>

					{/* Reject Modal */}
					<div
						className='modal fade'
						id='RejectModel'
						tabIndex='-1'
						aria-labelledby='exampleModalLabel'
						aria-hidden='true'
					>
						<div className='modal-dialog shadow-none modal-dialog-centered'>
							<div className='modal-content'>
								<div className='modal-header'>
									<h5 className='modal-title' id='exampleModalLabel'>
										Reject user
									</h5>
									<button type='button' className='close' data-dismiss='modal'>
										&times;
									</button>
								</div>
								<div className='modal-footer'>
									<button
										className='btn btn-primary'
										onClick={() => handleAcceptReject('reject')}
										data-dismiss='modal'
									>
										{loadingBtn ? (
											<PulseLoader
												color='#fff'
												style={{
													display: 'flex',
													justifyContent: 'center',
												}}
											/>
										) : (
											'Yes'
										)}
									</button>
									<button
										className='btn btn-danger'
										type='button'
										data-dismiss='modal'
									>
										No
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			)}
		</DashboardLayout>
	);
};

export default ItakaafRejectedList;
