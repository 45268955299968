import React, { useEffect, useState } from 'react';
import {
	Box,
	Paper,
	Typography,
	IconButton,
	Button,
	CircularProgress,
	Chip,
	Tooltip,
	Menu,
	MenuItem,
} from '@mui/material';
import { ReactComponent as SeatIcon } from '../../../assets/images/floor/seat.svg';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
	getFloorPlanListByIdApi,
	bookSelectedSeat,
} from '../../../api/adminApi';
import { errorToast } from '../../../helpers/projectHelper';

const ViewFloorPlanUI = ({
	checkedItems,
	isPopup = false,
	handleClose,
	getFloorPlanList,
}) => {
	const { id } = useParams();
	const dispatch = useDispatch();
	const { floorPlanListsById } = useSelector((state) => state.admin);
	const [loading, setLoading] = useState(true);
	const [buttonLoading, setButtonLoading] = useState(false);
	const [selectedSeats, setSelectedSeats] = useState([]); // Array to store selected seats
	const [selectedFloorIndex, setSelectedFloorIndex] = useState(0);
	const [filter, setFilter] = useState('all');

	const [anchorEl, setAnchorEl] = useState(null);
	const handleOpenFilterMenu = (event) => setAnchorEl(event.currentTarget);
	const handleCloseFilterMenu = () => setAnchorEl(null);

	useEffect(() => {
		dispatch(getFloorPlanListByIdApi(id, setLoading));
	}, [id, dispatch]);

	const handleSeatClick = (seat) => {
		if (seat.user) return;
		const isAlreadySelected = selectedSeats.some((s) => s._id === seat._id);
		if (isAlreadySelected) {
			setSelectedSeats(selectedSeats.filter((s) => s._id !== seat._id));
		} else {
			if (selectedSeats.length < checkedItems.length) {
				setSelectedSeats([...selectedSeats, { ...seat }]);
			} else {
				errorToast(`You can only select up to ${checkedItems.length} seats`);
			}
		}
	};

	const handleBooking = () => {
		if (selectedSeats.length === 0) {
			errorToast('Please select at least one seat');
			return;
		}
		const seatBookings = selectedSeats.map((seat, index) => ({
			...seat,
			userId: checkedItems[index],
		}));
		dispatch(
			bookSelectedSeat(
				seatBookings,
				setButtonLoading,
				() => dispatch(getFloorPlanListByIdApi(id, setLoading)),
				() => setSelectedSeats([]),
				handleClose,
				getFloorPlanList
			)
		);
	};

	if (loading) {
		return (
			<Box display='flex' justifyContent='center' py={4}>
				<CircularProgress />
			</Box>
		);
	}

	const floors = floorPlanListsById?.floors;
	if (!floors || floors.length === 0) {
		return <Typography>No Floor Data Found</Typography>;
	}
	const selectedFloor = floors[selectedFloorIndex];

	return (
		<Box p={{ xs: 2, md: 4 }}>
			{/* Header Section */}
			<Paper
				sx={{
					p: 2,
					mb: 3,
					borderRadius: 2,
					boxShadow: 1,
				}}
			>
				<Box
					display='flex'
					flexDirection={{ xs: 'column', md: 'row' }}
					alignItems='center'
					justifyContent='space-between'
					gap={2}
				>
					<Box textAlign={{ xs: 'center', md: 'left' }}>
						<Typography variant='h5' fontWeight='bold'>
							{floorPlanListsById?.name}
						</Typography>
						{floorPlanListsById?.city && (
							<Typography variant='subtitle1' color='text.secondary'>
								({floorPlanListsById.city})
							</Typography>
						)}
					</Box>
					<Button
						variant='contained'
						color='primary'
						onClick={handleBooking}
						sx={{
							fontSize: { xs: '0.75rem', md: 'inherit' },
							whiteSpace: 'nowrap',
						}}
						disabled={buttonLoading}
					>
						{buttonLoading && <CircularProgress size={20} mr={3} />}
						Book Selected Bed ({selectedSeats.length})
					</Button>
				</Box>

				<Box
					mt={2}
					display='flex'
					justifyContent='center'
					alignItems='center'
					gap={2}
				>
					<Box display='flex' alignItems='center' gap={0.5}>
						<Box sx={{ width: 20, height: 4, backgroundColor: '#ff9800' }} />
						<Typography variant='caption'>Selected</Typography>
					</Box>
					<Box display='flex' alignItems='center' gap={0.5}>
						<Box sx={{ width: 20, height: 4, backgroundColor: '#1976d2' }} />
						<Typography variant='caption'>Checked In</Typography>
					</Box>
					<Box display='flex' alignItems='center' gap={0.5}>
						<Box sx={{ width: 20, height: 4, backgroundColor: '#d32f2f' }} />
						<Typography variant='caption'>Booked</Typography>
					</Box>
					<Box display='flex' alignItems='center' gap={0.5}>
						<Box
							sx={{
								width: 20,
								height: 4,
								backgroundColor: '#fff',
								border: '1px solid #ccc',
							}}
						/>
						<Typography variant='caption'>Free</Typography>
					</Box>
				</Box>
			</Paper>

			<Box
				display='flex'
				alignItems='center'
				justifyContent='center'
				gap={1}
				mb={3}
				flexWrap='wrap'
			>
				<Box
					sx={{
						overflowX: 'auto',
						whiteSpace: 'nowrap',
						display: 'flex',
						gap: 1,
					}}
				>
					{floors.map((floor, index) => (
						<Chip
							key={floor._id}
							label={floor.floorName}
							clickable
							onClick={() => setSelectedFloorIndex(index)}
							color={selectedFloorIndex === index ? 'primary' : 'default'}
							sx={{ flexShrink: 0 }}
						/>
					))}
				</Box>
				<Box
					sx={{
						width: '1px',
						height: '24px',
						backgroundColor: '#ccc',
						mx: 1,
					}}
				/>
				<Box
					onClick={handleOpenFilterMenu}
					sx={{
						cursor: 'pointer',
						display: 'flex',
						alignItems: 'center',
						gap: 0.5,
					}}
				>
					<Typography variant='body2'>
						{filter.charAt(0).toUpperCase() + filter.slice(1)}
					</Typography>
					<i className='fa-solid fa-chevron-down'></i>
				</Box>
				<Menu
					anchorEl={anchorEl}
					open={Boolean(anchorEl)}
					onClose={handleCloseFilterMenu}
				>
					<MenuItem
						onClick={() => {
							setFilter('all');
							handleCloseFilterMenu();
						}}
					>
						All
					</MenuItem>
					<MenuItem
						onClick={() => {
							setFilter('booked');
							handleCloseFilterMenu();
						}}
					>
						Booked
					</MenuItem>
					<MenuItem
						onClick={() => {
							setFilter('free');
							handleCloseFilterMenu();
						}}
					>
						Free
					</MenuItem>
					<MenuItem
						onClick={() => {
							setFilter('checkedin');
							handleCloseFilterMenu();
						}}
					>
						Checked In
					</MenuItem>
				</Menu>
			</Box>

			<Box sx={{ overflowX: 'auto' }}>
				{selectedFloor.rows.map((row) => {
					const filteredSeats = row.seats.filter((seat) => {
						if (filter === 'all') return true;
						if (filter === 'booked')
							return !!seat.user && !seat.user?.isCheckedIn;
						if (filter === 'free') return !seat.user;
						if (filter === 'checkedin') return seat.user?.isCheckedIn;
						return true;
					});
					if (filteredSeats.length === 0) return null;
					return (
						<Box key={row._id} mb={3}>
							<Box sx={{ mb: 2 }}>
								<Box
									sx={{
										display: 'flex',
										justifyContent: 'center',
										gap: 1,
										minWidth: 'max-content',
										margin: '0 auto',
									}}
								>
									{filteredSeats.map((seat) => {
										const isBooked = !!seat.user;
										const isSelected = selectedSeats.some(
											(selected) => selected._id === seat._id
										);
										const isCheckedIn = seat.user?.isCheckedIn;

										let borderStyle, backgroundColor, iconFill, tooltipTitle;

										if (isBooked) {
											if (isCheckedIn) {
												borderStyle = '2px solid #1976d2';
												backgroundColor = '#e3f2fd';
												iconFill = '#1976d2';
												tooltipTitle = (
													<p
														style={{
															fontSize: '12px',
															textAlign: 'center',
															marginBottom: 0,
														}}
													>
														Checked in <br /> {seat.user?.name ?? ''} <br />
														{seat.user?.contactNumber ?? ''}
													</p>
												);
											} else {
												borderStyle = '2px solid #d32f2f';
												backgroundColor = '#f5f5f5';
												iconFill = '#d32f2f';
												tooltipTitle = (
													<p
														style={{
															fontSize: '12px',
															textAlign: 'center',
															marginBottom: 0,
														}}
													>
														Assigned to <br /> {seat.user?.name ?? ''} <br />
														{seat.user?.contactNumber ?? ''}
													</p>
												);
											}
										} else {
											if (isSelected) {
												borderStyle = '2px solid #ff9800';
												backgroundColor = '#fff3e0';
												iconFill = '#ff9800';
												tooltipTitle = 'Selected';
											} else {
												borderStyle = '1px solid #ccc';
												backgroundColor = '#fff';
												iconFill = '#4caf50';
												tooltipTitle = 'Free';
											}
										}

										return (
											<Tooltip key={seat._id} title={tooltipTitle} arrow>
												<Box flex='0 0 auto'>
													<Paper
														elevation={isSelected ? 6 : 2}
														sx={{
															p: 1,
															textAlign: 'center',
															border: borderStyle,
															cursor: isBooked ? 'not-allowed' : 'pointer',
															backgroundColor: backgroundColor,
															width: 70,
															height: 90,
															display: 'flex',
															flexDirection: 'column',
															alignItems: 'center',
															justifyContent: 'center',
														}}
														onClick={() =>
															!isBooked &&
															handleSeatClick({
																...seat,
																seatId: seat._id,
																masjidId: floorPlanListsById._id,
																floorId: selectedFloor._id,
																rowId: row._id,
															})
														}
													>
														<IconButton disabled={isBooked}>
															<SeatIcon
																fill={iconFill}
																width={40}
																height={40}
															/>
														</IconButton>
														<Typography variant='caption'>
															{selectedFloor.floorName?.charAt(0).toUpperCase()}
															{seat?.number.toString().padStart(3, '0')}
														</Typography>
													</Paper>
												</Box>
											</Tooltip>
										);
									})}
								</Box>
							</Box>
						</Box>
					);
				})}
			</Box>
		</Box>
	);
};

export default ViewFloorPlanUI;
